import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Spin,
  Button,
  Col,
  Row,
  Table,
  Breadcrumb,
  message,
  Select,
  Popconfirm,
} from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
  UndoOutlined,
} from "@ant-design/icons";

import Search from "antd/es/input/Search";
import MasterService from "../../../Services/MasterService";
import AddEditStream from "./AddEditStream";
import dayjs from "dayjs";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { StreamMaster } from "../../../ApiUrls/URLS";
import {
  ActiveInactiveOptions,
  MasterTypes,
  RecordStatusType,
} from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import { useNavigate } from "react-router";
import HTTPSCalls from "../../../Services/HTTPCalls";

interface IStream {
  key: string;
  sNo: number;
  id: string;
  name: string;
}
const api = new ApiCalls(StreamMaster.endPoints, StreamMaster.prefix);

const Stream = () => {
  const navigate = useNavigate();
  const columns: ColumnsType<IStream> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "5%",
      render: (index: any) => <p>{index}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      showSorterTooltip: false,
      sorter: true,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setStreamId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),
    },

    {
      title: " ",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          <FormOutlined
            className="editIconColour me-2 cursor"
            onClick={() => {
              setStreamId(record?.id);
              setOpenDrawer(true);
            }}
          />
        </Row>
      ),
    },
    {
      title: "",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          {record.recordStatus === RecordStatusType.Deleted ? (
            <UndoOutlined
              className="ca-edit-btn me-2 cursor"
              onClick={() => UpdateRecordStatus(record.id, "restore")}
            />
          ) : (
            <Popconfirm title={"Are you sure to delte this task?"} onConfirm={()=>UpdateRecordStatus(record.id, "delete")}>
              <DeleteOutlined
                className="ca-edit-btn me-2 cursor"
              />
            </Popconfirm>
          )}
        </Row>
      ),
    },
  ];
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [streamId, setStreamId] = useState<string | undefined>();
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [allStream, setAllStream] = useState<IStream[]>();
  const [status, setStatus] = useState<string>("active");

  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "Name",
    sortDir: "",
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    setListParams((p) => {
      p.sortCol = sorter?.columnKey;
      p.sortDir = sorter?.order;
      return p;
    });
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
    }
  };
  useEffect(() => {
    setPageLoader(true);
    get_stream(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems]);

  const get_stream = async (start: any, length: any) => {
    await api
      .GET(StreamMaster.endPoints.getList + "?", {
        masterType: MasterTypes.Stream,
        start: start,
        length: length,
        search: listParams.search,
        sortCol: listParams.sortCol,
        sortDir: listParams.sortDir,
        status: status,
      })
      .then((res: IApiResponse) => {
        if (/*res?.errors === null &&*/ res?.result) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          setAllStream(
            res?.result?.items.map((r: any, i: any) => ({
              key: i,
              sno: r?.sno,
              id: r?.id,
              name: r?.name,
              recordStatus: r?.recordStatus,
            }))
          );
          setPageLoader(false);
        } else {
          message.error(res?.message);
          setPageLoader(false);
        }
      });
  };

  const setListParamsAndRefresh = (value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams({ ...listParams, search: value });
  };

  const UpdateRecordStatus = async (
    sourceId?: string,
    action: string = "delete"
  ) => {
    if (!sourceId) return message.error("Source ID is required.");
    try {
      setPageLoader(true);
      await HTTPSCalls.POST(
        `${HTTPSCalls.ENDPOINTS.UPDATE_RECORD_STATUS}?objId=${sourceId}&restore=${action}`,
        {},
        {}
      );
      setTrigger(!trigger); // Refresh data
    } catch (error) {
      message.error("Error occurred while updating record status.");
    } finally {
      setPageLoader(false);
    }
  };

  return (
    <>
      <AddEditStream
        setTrigger={setTrigger}
        openDrawer={openDrawer}
        streamId={streamId}
        SetDrawerClose={setOpenDrawer}
      />
      <Row
        justify={"space-between"}
        align="middle"
        style={{ marginBottom: "6px" }}
      >
        <ArrowLeftOutlined
          style={{ color: "#1677ff" }}
          onClick={() => {
            navigate("/settings");
          }}
        />
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Streams",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row gutter={10}>
            <Col lg={18}>
              <Button
                type="primary"
                className="cit-add-btn mb-1"
                onClick={() => {
                  setStreamId(undefined);
                  setOpenDrawer(true);
                }}
              >
                <PlusOutlined />
                Stream
              </Button>
            </Col>
            <Col lg={3}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Search status"
                optionFilterProp="label"
                onChange={(val) => {
                  setStatus(val);
                  setPagingItems((prev) => ({ ...prev, currentPage: 1 }));
                  setTrigger((prev) => !prev);
                }}
                defaultValue={"active"}
                options={ActiveInactiveOptions}
              />
            </Col>
            <Col lg={3}>
              <Search
                size="middle"
                placeholder="Search..."
                allowClear
                className="att-search-input mb-1"
                onSearch={(val: string) => setListParamsAndRefresh(val)}
                onChange={(e: any) =>
                  e.target.value === "" ? setListParamsAndRefresh("") : null
                }
                style={{ width: 170 }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "12px" }}>
          <Table
            className="cit-table"
            loading={{
              spinning: pageLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            size="small"
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={allStream}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: ["15", "25", "50", "100"],
            }}
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                setListParams({
                  ...listParams,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey,
                });
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Stream;
