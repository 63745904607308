import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Spin, Button, Col, Row, Table, Breadcrumb, Tag, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import Search from "antd/es/input/Search";
import MasterService from "../../../Services/MasterService";
import AddEditStatus from "./AddEditStatus";
import dayjs from "dayjs";
import "../.../../../../Custom.css";
import { HttpMethods, IApiResponse } from "../../../Utilities/ApiUtility.axios";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { StatusMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { useNavigate } from "react-router";

interface IStatus {
  key: string;
  sNo: number;
  id: string;
  name: string;
}
const api = new ApiCalls(StatusMaster.endPoints, StatusMaster.prefix);

const Status = () => {
  const navigate = useNavigate();
  const columns: ColumnsType<IStatus> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "5%",
      render: (index: any) => <p>{index}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      showSorterTooltip: false,
      sorter: true,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setStatusId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),

      // render:
    },
    {
      title: " ",
      width: "5%",
      dataIndex: "isDefaultFilter",
      key: "IsDefaultFilter",
      render: (isDefaultFilter: any) => (
        <>
          {isDefaultFilter ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Allowed
            </Tag>
          ) : (
            ""
          )}
        </>
      ),
      // render:
    },

    {
      title: " ",
      width: "8%",
      render: (_: any, record: any) => (
        <Row justify="end">
          <FormOutlined
            className="ca-edit-btn me-2 editIconColour"
            onClick={() => {
              setStatusId(record?.id);
              setOpenDrawer(true);
            }}
          />
        </Row>
      ),
    },
  ];
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [statusId, setStatusId] = useState<string | undefined>();
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [allStatus, setAllStatus] = useState<IStatus[]>();
  // const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [showIsSaveAll, setShowIsSaveAll] = useState(false);
  const [saveRequestList, setSaveRequestList]: any = useState([]);
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "Name",
    sortDir: "",
  });
  const rowSelection = {
    selectedRowKeys: checkedKeys,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setCheckedKeys(selectedRowKeys);
      let arr: any = [];
      // eslint-disable-next-line array-callback-return
      selectedRows.map((item: any) => {
        arr.push(item?.id);
      });
      setSaveRequestList([...arr]);
      selectedRows.length > 0
        ? setShowIsSaveAll(true)
        : setShowIsSaveAll(false);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.status === 1 || record.status === 3, // Column configuration not to be checked
      status: record.status,
    }),
  };
  // const onSelectChange = (newSelectedRowKeys: React.Key[], record: any) => {
  //   console.log("d: ", record);
  //   console.log("selectedRowKeys changed: ", newSelectedRowKeys);
  //   setSelectedRowKeys([...selectedRowKeys,record]);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };
  // const hasSelected = selectedRowKeys.length > 0;
  const saveStatus = () => {
    setLoading(true);
    HTTPSCalls.POST_FROM(
      HTTPSCalls.ENDPOINTS.SAVE_STATUS_ACTION + "?",
      { masterType: MasterTypes.Status },
      { saveRequestList }
    ).then((res: any) => {
      if (res?.result?.length >= 0 && res?.errors === null) {
        setLoading(false);
        setTrigger(!trigger);
        setCheckedKeys([]);
        setSaveRequestList([]);
        setShowIsSaveAll(false);
      } else {
        message.error(res?.errors);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    setPageLoader(true);
    get_status(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems]);

  const get_status = async (start: any, length: any) => {
    setPageLoader(true);

    await api
      .GET(StatusMaster.endPoints.getList + "?", {
        masterType: MasterTypes.Status,
        start: start,
        length: length,
        search: listParams.search,
        sortCol: listParams.sortCol,
        sortDir: listParams.sortDir,
      })
      .then((res: IApiResponse) => {
        if (/*res?.errors === null &&*/ res?.result?.items?.length >= 0) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          setAllStatus(
            res?.result?.items.map((r: any, i: any) => ({
              key: i,
              sno: r?.sno,
              id: r?.id,
              name: r?.name,
              isDefaultFilter: r?.isDefaultFilter,
            }))
          );
          setPageLoader(false);
        } else {
          message.error(res?.message);
          setPageLoader(false);
        }
      });
  };

  const setListParamsAndRefresh = (value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams({ ...listParams, search: value });
  };

  return (
    <>
      <AddEditStatus
        setTrigger={setTrigger}
        openDrawer={openDrawer}
        statusId={statusId}
        SetDrawerClose={setOpenDrawer}
      />
      <Row
        justify={"space-between"}
        align="middle"
        style={{ marginBottom: "6px" }}
      >
        <ArrowLeftOutlined
          style={{ color: "#1677ff" }}
          onClick={() => {
            navigate("/settings");
          }}
        />
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Status",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row justify="space-between">
            <Button
              type="primary"
              className="cit-add-btn mb-1"
              onClick={() => {
                setStatusId(undefined);
                setOpenDrawer(true);
              }}
            >
              <PlusOutlined />
              Status
            </Button>

            <Search
              size="middle"
              placeholder="Search..."
              allowClear
              className="att-search-input mb-1"
              onSearch={(val: string) => setListParamsAndRefresh(val)}
              onChange={(e: any) =>
                e.target.value === "" ? setListParamsAndRefresh("") : null
              }
              style={{ width: 170 }}
            />
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "12px" }}>
          <Table
            className="cit-table"
            loading={{
              spinning: pageLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            size="small"
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={allStatus}
            rowSelection={rowSelection}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: ["15", "25", "50", "100"],
            }}
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                setListParams({
                  ...listParams,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey,
                });
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
            footer={() =>
              showIsSaveAll ? (
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    saveStatus();
                  }}
                >
                  Save
                </Button>
              ) : (
                <></>
              )
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default Status;
