import {
  Badge,
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { useState, useEffect } from "react";
import { useBatchAttendance } from "../../Utilities/tanStackQueries";
import { AttandanceStatusType } from "../../Constant/Enums";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AddStudentAtt } from "../../Store/Slice/studentAttSlice";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { selectBatchState } from "../../Store/Slice/batchSlice";
import UnassignedEnquiryList from "./UnassignedEnquiryList";
import EnquiryGroupByCounselors from "./EnquiryGroupByCounselors";
import EnquiryCallList from "./EnquiryCallList";

const EnquiryDashboard = () => {
  const [selectedStatus, setSelectedStatus] = useState<AttandanceStatusType>(
    AttandanceStatusType.All
  );
  const [search, setSearch] = useState<any>("");
  const [selectedMonth, setSelectedMonth] = useState(dayjs());

  const [attendanceCounts, setAttendanceCounts] = useState({
    presentCount: 0,
    absentCount: 0,
    leaveCount: 0,
  });
  const [filteredBatchStudent, setFilteredBatchStudent] = useState<any[]>([]);
  const navigation = useNavigate();
  const batchState = useSelector(selectBatchState);
  const { data: batchStudent, isLoading: batchStudentLoading } =
    useBatchAttendance({
      batchId: batchState,
      date: selectedMonth,
      name: search,
    });
  useEffect(() => {
    setAttendanceCounts({
      presentCount: 0,
      absentCount: 0,
      leaveCount: 0,
    });

    const filteredStudents = batchStudent?.filter((student: any) => {
      if (selectedStatus === AttandanceStatusType.All) {
        if (student.status === AttandanceStatusType.Present) {
          setAttendanceCounts((prev) => ({
            ...prev,
            presentCount: prev.presentCount + 1,
          }));
        } else if (student?.isLeave) {
          setAttendanceCounts((prev) => ({
            ...prev,
            leaveCount: prev.leaveCount + 1,
          }));
        } else if ((student?.dayTrans?.length || 0) === 0) {
          setAttendanceCounts((prev) => ({
            ...prev,
            absentCount: prev.absentCount + 1,
          }));
        }

        return true;
      } else if (selectedStatus === AttandanceStatusType.Present) {
        const isPresent = student.status === AttandanceStatusType.Present;
        if (isPresent) {
          setAttendanceCounts((prev) => ({
            ...prev,
            presentCount: prev.presentCount + 1,
          }));
        }
        return isPresent;
      } else if (selectedStatus === AttandanceStatusType.Absent) {
        const isAbsent = (student?.dayTrans?.length || 0) === 0;
        if (isAbsent) {
          setAttendanceCounts((prev) => ({
            ...prev,
            absentCount: prev.absentCount + 1,
          }));
        }
        return isAbsent;
      } else if (selectedStatus === AttandanceStatusType.Leave) {
        const isLeave = student?.isLeave ? true : false;
        if (isLeave) {
          setAttendanceCounts((prev) => ({
            ...prev,
            leaveCount: prev.leaveCount + 1,
          }));
        }
        return isLeave;
      }
      return false;
    });

    setFilteredBatchStudent(filteredStudents);
  }, [batchStudent, selectedStatus]);

  const dispatch = useDispatch();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "48%",
      ellipsis: true,
      showSorterTooltip: true,

      render: (text: any, record: any) => (
        <div style={{ display: "flex" }}>
          <span
            style={{
              display: "inline-block",
              height: "8px",
              backgroundColor:
                record?.status ||
                record?.status === 1 ||
                record?.dayTrans?.length > 0
                  ? "rgb(97, 200, 85)"
                  : record.isHoliday
                  ? "gray"
                  : record.isLeave
                  ? "#b029bc"
                  : "rgb(255, 115, 115)",
              borderRadius: "50%",
              flex: "0 0 8px",
              lineHeight: 1.2,
              alignSelf: "center",
              marginRight: "8px",
            }}
          ></span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                AddStudentAtt({
                  batch: {
                    id: record?.Batch?.id,
                    name: record?.Batch?.name,
                  },
                  student: {
                    id: record?.id,
                    name: record?.name,
                  },
                  date: dayjs.utc(selectedMonth),
                })
              );
              navigation("/attendance");
            }}
          >
            <span style={{ fontSize: "13px" }}>{text}</span>
          </span>
        </div>
      ),
    },
    {
      title: "In Time",
      dataIndex: "in",
      key: "in",
      render: (text: any, record: any) => {
        return (
          <Tooltip
            destroyTooltipOnHide
            placement="top"
            overlayStyle={{ minWidth: 160 }}
            title={record?.dayTrans?.map((item: any, index: number) => (
              <Row key={index} justify="space-between">
                <Col className="fs12" span={12}>
                  {dayjs.utc(item?.time).format("hh:mm:ss A")}
                </Col>
                <Col className="fs12" span={11}>
                  {item?.isManual && "Manual"}
                  {/* {console.log("record", record)} */}
                </Col>
              </Row>
            ))}
          >
            <span
              style={{
                textAlign: "center",
                fontSize: "13px",
                color: text
                  ? record?.dayTrans?.length > 0 &&
                    record?.batchDayIn < record?.dayTrans?.[0].time
                    ? "rgb(255, 115, 115)"
                    : "rgb(97, 200, 85)"
                  : "black",
                fontWeight: 500,
              }}
            >
              <>
                {/* {record)} */}
                {record?.dayTrans?.length > 0
                  ? dayjs.utc(record?.dayTrans?.[0].time).format("hh:mm A")
                  : "-"}
              </>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Out Time",
      dataIndex: "out",
      key: "out",
      render: (text: any, record: any) => {
        const dayTransLength = record?.dayTrans?.length || 0;
        return (
          <>
            <Tooltip
              destroyTooltipOnHide
              placement="top"
              overlayStyle={{ minWidth: 160 }}
              title={record?.dayTrans?.map((item: any, index: number) => (
                <Row key={index} justify="space-between">
                  <Col className="fs12" span={12}>
                    {dayjs.utc(item?.time).format("hh:mm:ss A")}
                  </Col>
                  <Col className="fs12" span={11}>
                    {item?.isManual && "Manual"}
                  </Col>
                </Row>
              ))}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  color:
                    dayTransLength % 2 === 0 && dayTransLength !== 0
                      ? record?.batchDayOut >
                        record?.dayTrans?.[dayTransLength - 1]?.time
                        ? "rgb(255, 115, 115)"
                        : "rgb(97, 200, 85)"
                      : "black",
                  fontWeight: 500,
                }}
              >
                {dayTransLength % 2 === 0 && dayTransLength !== 0
                  ? dayjs
                      .utc(record?.dayTrans[dayTransLength - 1]?.time)
                      .format("hh:mm A")
                  : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* <Spin
          spinning={load}
          indicator={<LoadingOutlined />}
          fullscreen
        /> */}
      <Row gutter={18}>
        <Col lg={16} md={24} span={24}>
        <Row gutter={[0, 16]}> 
            <Col span={24}>
              <UnassignedEnquiryList />
            </Col>
            <Col span={24}>
            <EnquiryCallList/>
              {/* <Row gutter={18} className="mt-3">
                <Col md={13}>
                  <Card style={{ height: "100%" }} />
                </Col>
                <Col md={11}>
                <EnquiryGroupByCounselors/>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Col>
        <Col lg={8}>
          <div className="py-2 roundedCornerMedium bg-white">
            <Row gutter={18} style={{ padding: 12 }}>
              <Col lg={8} xxl={8} xl={8} md={8}>
                <Select
                  // loading={isMasterLoading}
                  popupClassName="cit-select-box"
                  placeholder={"Select Status"}
                  // allowClear
                  // onClear={() => {
                  //   setSelectedStatus("");
                  // }}
                  options={[
                    { label: "All", value: AttandanceStatusType.All },
                    { label: "Present", value: AttandanceStatusType.Present },
                    { label: "Absent", value: AttandanceStatusType.Absent },
                    { label: "Leave", value: AttandanceStatusType.Leave },
                  ]}
                  showSearch
                  value={selectedStatus}
                  // options={getBatchOptions()}
                  style={{ width: "100%" }}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  onSelect={(val: any) => {
                    setSelectedStatus(val);
                  }}
                />
              </Col>
              <Col lg={8} xxl={8} xl={8} md={8}>
                <DatePicker
                  allowClear={false}
                  value={selectedMonth}
                  style={{ width: "100%" }}
                  // picker="month"
                  format="DD/MM/YYYY"
                  disabledDate={(current: any) => {
                    return current && current > dayjs().endOf("month");
                  }}
                  onChange={(val: any) => {
                    setSelectedMonth(val);
                  }}
                />
              </Col>

              <Col lg={8} xxl={8} xl={8} md={8}>
                <Search
                  size="middle"
                  placeholder="Search..."
                  allowClear
                  className="att-search-input"
                  onSearch={(val: string) => {
                    setSearch(val);
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <Table
              className="studentList"
              loading={{
                spinning: batchStudentLoading,
                indicator: <Spin indicator={<LoadingOutlined />} />,
              }}
              style={{
                width: "100vw",
                borderBottom: "none",
                overflow: "auto",
                minHeight: 650,
              }}
              size="small"
              pagination={false}
              scroll={{ y: "630px" }}
              dataSource={filteredBatchStudent}
              columns={columns}
              rowKey={(record) => record?.id}
            />
            <Row style={{ textAlign: "center" }}>
              <Col xxl={6} xl={6} lg={6}>
                <Badge
                  status="processing"
                  color="green"
                  text={
                    <span
                      style={{
                        cursor: "pointer",
                        // marginRight: 40,
                        color: "rgb(54, 70, 99)",
                        fontWeight: 600,
                      }}
                    >
                      Present : {attendanceCounts?.presentCount}
                    </span>
                  }
                />
              </Col>
              <Col xxl={6} xl={6} lg={6}>
                <Badge
                  status="processing"
                  color="red"
                  text={
                    <span
                      style={{
                        cursor: "pointer",
                        // marginRight: 40,
                        color: "rgb(54, 70, 99)",
                        fontWeight: 600,
                      }}
                    >
                      Absent : {attendanceCounts?.absentCount}
                    </span>
                  }
                />
              </Col>
              <Col xxl={6} xl={6} lg={6}>
                <Badge
                  status="processing"
                  color="purple"
                  text={
                    <span
                      style={{
                        cursor: "pointer",
                        // marginRight: 40,
                        color: "rgb(54, 70, 99)",
                        fontWeight: 600,
                      }}
                    >
                      Leave : {attendanceCounts?.leaveCount}
                    </span>
                  }
                />
              </Col>
              <Col xxl={6} xl={6} lg={6}>
                <Badge
                  color="gray"
                  text={
                    <span
                      style={{
                        cursor: "pointer",
                        color: "rgb(54, 70, 99)",
                        fontWeight: 600,
                      }}
                    >
                      Holiday
                    </span>
                  }
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default EnquiryDashboard;
