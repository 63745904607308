import dayjs from "dayjs";
import { AttachmentsCategory, LevelObj, StudyModeObj } from "../Constant/Enums";

export const convertToAntForm = (apiData: any) => {
  // console.log("apiData = ", apiData);
  const formVal = {
    ...apiData,
    gender: {
      label: apiData?.gender?.name,
      value: apiData?.gender?.id,
    },
    medium: {
      label: apiData?.medium?.name,
      value: apiData?.medium?.id,
    },
    currentClass: {
      label: apiData?.currentClass?.name,
      value: apiData?.currentClass?.id,
    },
    category: {
      label: apiData?.category?.name,
      value: apiData?.category?.id,
    },
    curriculum: {
      label: apiData?.curriculum?.name,
      value: apiData?.curriculum?.id,
    },
    batch: {
      label: apiData?.batch?.name,
      value: apiData?.batch?.id,
    },
    stream: {
      label: apiData?.stream?.name,
      value: apiData?.stream?.id,
    },
    sources: {
      label: apiData?.sources?.name,
      value: apiData?.sources?.id,
    },
    counselors: apiData?.counselors?.map((itm: any) => {
      return {
        label: itm?.name,
        value: itm?.id,
      };
    }),
    counselor: {
      label: apiData?.counselor?.name,
      value: apiData?.counselor?.id,
    },
    englishProficiency: {
      label: LevelObj[apiData?.englishProficiency],
      value: apiData?.englishProficiency,
    },

    dateOfBirth: dayjs(apiData?.dateOfBirth),
    academicRecord: apiData?.academicRecord?.map(
      (arrayItem: any, index: any) => ({
        key: index,
        ...arrayItem,
        class: {
          label: arrayItem?.class?.name,
          value: arrayItem?.class?.id,
        },
        curriculum: {
          label: arrayItem?.curriculum?.name,
          value: arrayItem?.curriculum?.id,
        },
        stream: {
          label: arrayItem?.stream?.name,
          value: arrayItem?.stream?.id,
        },
        studyMode: {
          label: StudyModeObj[arrayItem?.studyMode],
          value: arrayItem?.studyMode,
        },
      })
    ),
    attachments: apiData?.attachments
      ?.filter(
        (itm: any) => itm?.category === AttachmentsCategory.StudentDocuments
      )
      ?.map((itm: any, index: any) => {
        return {
          key: index,
          ...itm,
          fileType: {
            label: itm?.fileType?.name,
            value: itm?.fileType?.id,
          },
        };
      }),
    approvalStatus: {
      label: apiData?.approvalStatus?.name,
      value: apiData?.approvalStatus?.id,
    },
    amount: apiData?.amount,
    cardNo: apiData?.cardNo,
    studentId: apiData?.studentId,
    enrolledDate: dayjs(apiData?.enrolledDate),
    enquiryDate: dayjs(apiData?.enquiryDate),
    credentials: apiData?.credentials?.map((arrayItem: any, index: any) => ({
      ...arrayItem,
      key: index,
      credentialName: {
        value: arrayItem?.credentialName?.id,
        label: arrayItem?.credentialName?.name,
      },
    })),
  };

  if (formVal?.dateOfBirth?.format("DD MMM YYYY") === "01 Jan 0001")
    delete formVal?.dateOfBirth;

  return formVal;
};

export const convertBasicDetailsToJson = (
  val: any,
  recordId: string,
  attachmentList: any[],
  allAttachment: any[],
  studentSignature?: any,
  counselorSignature?: any,
  studentImage?: any
) => {
  let studentAttachment = val?.attachments?.filter(
    (x: any) => x?.category === AttachmentsCategory.StudentDocuments
  );
  // console.log("valvalvalvalval = ", val);
  // console.log("frm = ", studentSignature, counselorSignature, studentImage);
  let formValue = {
    id: recordId,
    ...val,
    counselorName: {
      id: val?.counselorName?.value,
      name: val?.counselorName?.label,
    },
    credentials: val?.credentials?.map((arrayItem: any, index: any) => ({
      ...arrayItem,
      credentialName: {
        id: arrayItem?.credentialName?.id,
        name: arrayItem?.credentialName?.name,
      },
    })),
    category: { id: val?.category?.value, name: val?.category?.label },
    curriculum: {
      id: val?.curriculum?.value,
      name: val?.curriculum?.label,
    },
    nationality: val?.nationality,
    medium: { id: val?.medium?.value, name: val?.medium?.label },
    currentClass: { id: val?.currentClass?.value, name: val?.currentClass?.label },
    counselor: { id: val?.counselor?.value, name: val?.counselor?.label },
    gender: { id: val?.gender?.value, name: val?.gender?.label },
    batch: { id: val?.batch?.value, name: val?.batch?.label },
    englishProficiency: val?.englishProficiency?.value,
    stream: { id: val?.stream?.value, name: val?.stream?.label },
    sources: { id: val?.sources?.value, name: val?.sources?.label },
    counselors: val?.counselors?.map((itm: any) => ({
      id: itm?.value,
      name: itm?.label,
    })),
    // amount: 260000,
    academicRecord: val?.academicRecord?.map((arrayItem: any) => {
      return {
        class: {
          id: arrayItem?.class?.value,
          name: arrayItem?.class?.label,
        },
        curriculum: {
          id: arrayItem?.curriculum?.value,
          name: arrayItem?.curriculum?.label,
        },
        stream: {
          id: arrayItem?.stream?.value,
          name: arrayItem?.stream?.label,
        },
        schoolName: arrayItem?.schoolName,
        year: arrayItem?.year,
        marks: arrayItem?.marks,
        percentage: arrayItem?.percentage,
        studyMode: arrayItem?.studyMode?.value,
      };
    }),
    signature: {
      candidate:
        studentSignature?.length > 0
          ? studentSignature?.map((arrayItem: any) => {
              return {
                name: arrayItem?.name,
                id: "",
                path: arrayItem?.thumbUrl,
                contentType: arrayItem?.type,
              };
            })[0]
          : {
              name: "",
              id: "",
              path: "",
              contentType: "",
            },
      counselor:
        counselorSignature?.length > 0
          ? counselorSignature?.map((arrayItem: any) => {
              return {
                name: arrayItem?.name,
                id: "",
                path: arrayItem?.thumbUrl,
                contentType: arrayItem?.type,
              };
            })[0]
          : {
              name: "",
              id: "",
              path: "",
              contentType: "",
            },
    },
    candidateImage:
      studentImage?.length > 0
        ? studentImage?.map((arrayItem: any) => {
            return {
              name: arrayItem?.name,
              id: "",
              path: arrayItem?.thumbUrl,
              contentType: arrayItem?.type,
            };
          })[0]
        : {
            name: "",
            id: "",
            path: "",
            contentType: "",
          },
    attachments:studentAttachment||0
    // studentAttachment.length > 0
    //     ? studentAttachment?.map((item: any, index: number) => {
    //         return {
    //           fileType: {
    //             id: item?.fileType?.value,
    //             name: item?.fileType?.label,
    //           },
    //           fileName: {
    //             id: item?.fileName?.id ? item?.fileName?.id : "",
    //             name: item?.fileName?.file?.name
    //               ? item?.fileName?.file?.name
    //               : item?.fileName?.name
    //               ? item?.fileName?.name
    //               : "",
    //             contentType: item?.fileName?.file?.type
    //               ? item?.fileName?.file?.type
    //               : item?.fileName?.contentType
    //               ? item?.fileName?.contentType
    //               : "",
    //             length: item?.fileName?.file?.size
    //               ? item?.fileName?.file?.size
    //               : item?.fileName?.length,
    //             path: attachmentList[index]?.fileName?.path
    //               ? attachmentList[index]?.fileName?.path
    //               : item?.fileName?.path,
    //           },
    //           description: item?.description,
    //           category: AttachmentsCategory.StudentDocuments,
    //         };
    //       })
    //     : [],
  };
  // let studentDocsAtt: any = null;
  // let otherDocAtt: any = allAttachment?.filter(
  //   (x: any) => x?.category !== AttachmentsCategory.StudentDocuments
  // );
  // let totalDocAtt: any = null;
  // if (formValue?.attachments) {
  //   studentDocsAtt = [...formValue?.attachments];
  //   totalDocAtt = [...formValue?.attachments];
  // }
  // if (otherDocAtt) {
  //   totalDocAtt = [...studentDocsAtt, ...otherDocAtt];
  // }
  // let finalVal = {
  //   ...formValue,
  //   attachments: totalDocAtt,
  // };

  // return finalVal;
  return formValue
};
