import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Pagination, Row, Table } from "antd";
import HTTPSCalls from "../../Services/HTTPCalls";
import { ColumnsType } from "antd/es/table/interface";
import { pageSizeOption } from "../LMS/SubjectAlllocation/SubjectAllocation";
// import { FeesType } from "../../Constant/Enums";
import { useSelector } from "react-redux";
import { selectBatchState } from "../../Store/Slice/batchSlice";
import { FileTextOutlined } from "@ant-design/icons";
import FeesSummary from "../Fees/FeesSummary";
import { Utils } from "../../Utilities/Utils";

const FeesViewModal = (props: any) => {
  const { isModalOpen, handleCancel, sendFeesType } = props;
  const [viewFeesData, setViewFeesData] = useState<any>([]);
  const [totalFees, setTotalFees] = useState<any>({});
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 10 });
  const [modelLoading, setModelLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>({
    student: {},
    // data: [],
  });
  const [open, setOpen] = useState("");

  // const [listParams, setListParams] = useState({
  //   streamSearch: "",
  //   batchSearch: "",
  //   start: 0,
  //   length: 15,
  //   search: "",
  //   sortCol: "Student.Name",
  //   sortDir: "ascend",
  //   feesType: "",
  // });
  const batchState = useSelector(selectBatchState);

  const getFeesData = async () => {
    try {
      setModelLoading(true);
      let res = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.PAYMENT_GET_LIST + "?",
        {
          // streamSearch: listParams?.streamSearch,
          // batchSearch: listParams?.batchSearch,
          batchSearch: batchState,
          start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
          length: pagingItems?.pageSize,
          // sortCol: listParams.sortCol,
          // sortDir: listParams?.sortDir,
          // search: listParams?.search,
          feesType: sendFeesType,
        }
      );
      if (res?.result?.data?.items.length >= 0) {
        const result = res?.result?.data?.items;
        setViewFeesData(() =>
          result?.map((itm: any, index: number) => {
            return {
              sno: itm?.sno,
              key: index,
              student: itm?.student,
              id: itm?.id,
              paymentDetail: itm?.paymentDetail?.map(
                (item: any, innerIndex: number) => ({ ...item, key: innerIndex })
              ),
              totalPaidAmount: itm?.totalPaidAmount,
              remainingAmount: itm?.remainingAmount,
              feesAmount: itm?.feesAmount,
              stream: itm?.stream,
              batch: itm?.batch,
            };
          })
        );
        setTotalFees(res?.result?.data?.total)
        setPagingItems((p) => {
          p.totalRecords = res?.result?.data?.totalRecords;
          return p;
        });
      }
    } catch (error) {
      console.error("error",error);
    } finally {
      setModelLoading(false);
    }
  };

  useEffect(() => {
    isModalOpen && getFeesData();
  }, [pagingItems, isModalOpen]);

  const columns: ColumnsType<any> = [
    {
      title: "S No",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "student",
      key: "name",
      width: "20%",
      ellipsis:true,
      showSorterTooltip:true,
      render: (student: any) => <span>{student?.name}</span>,
    },
    {
      title: "Fees Amount",
      dataIndex: "feesAmount",
      key: "amount",
      width: "20%",
      align:"right",
      render: (name: any) => <span>₹{Utils.getFormattedNumber(name)}</span>,
    },
    {
      title: "Paid Amount",
      dataIndex: "totalPaidAmount",
      key: "totalPaidAmount",
      align:"right",
      render: (name: any) => <span>₹{Utils.getFormattedNumber(name)}</span>,
      width: "20%",
    },
    {
      title: "Due Amount",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
      align:"right",
      render: (name: any) => <span>₹{Utils.getFormattedNumber(name)}</span>,
      width: "20%",
    },
    {
      title: " ",
      align: "right",
      dataIndex: "paymentDetail",
      responsive: ["xs", "sm", "md", "lg"],
      render: (data: any, record: any) => (
        <>
          {
            <Button
              type="text"
              icon={
                <FileTextOutlined
                  style={{
                    color: "#377dff",
                  }}
                />
              }
              className="cursorPointer"
              style={{ color: "#377dff" }}
              onClick={() => {
                setTableData({ student: record?.student, id: record?.id });
                setOpen("showModal");
              }}
              size="small"
            >
              Summary
            </Button>
          }
        </>
      ),
    },
  ];
  // const calculateTotals = () => {
  //   const totals = viewFeesData.reduce(
  //     (acc: any, item: any) => {
  //       acc.totalFeesAmount += item.feesAmount || 0;
  //       acc.totalPaidAmount += item.totalPaidAmount || 0;
  //       acc.totalRemainingAmount += item.remainingAmount || 0;
  //       return acc;
  //     },
  //     { totalFeesAmount: 0, totalPaidAmount: 0, totalRemainingAmount: 0 }
  //   );
  //   return totals;
  // };
  // const { totalFeesAmount, totalPaidAmount, totalRemainingAmount } =
  //   calculateTotals();

  return (
    <>
      <Modal
        //style={{ height: "2000px" }}
        width={1100}
        footer={false}
        title="Fees"
        open={isModalOpen}
        onCancel={handleCancel}
        // bodyStyle={{
        //   maxHeight: "80%",
        //   overflow: "auto",
        // }}
      >
        <Row>
          <Col span={24} style={{ paddingTop: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 330px)",
                overflow: "hidden",
              }}
            >
              <div style={{ overflowY: "auto", flex: "1" }}>
                <Table
                  size="small"
                  className="Tabel-style"
                  dataSource={viewFeesData}
                  loading={modelLoading}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: "calc(100vh - 450px)", x: 600 }}
                  summary={() => (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} align="end"><span style={{fontWeight:600}}>Total</span></Table.Summary.Cell>
                        <Table.Summary.Cell index={1} align="end"></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} align="end">
                          ₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(totalFees?.totalFeesAmount)} </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} align="end">
                          ₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(totalFees?.totalPaidAmount)}</span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4} align="end">
                          ₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(totalFees?.totalRemainingAmount)}</span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                />
              </div>
              <Pagination
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                current={pagingItems.currentPage}
                pageSize={pagingItems.pageSize}
                showSizeChanger
                total={pagingItems.totalRecords}
                pageSizeOptions={pageSizeOption}
                onChange={(page, pageSize) => {
                  setPagingItems({
                    ...pagingItems,
                    currentPage: page,
                    pageSize,
                  });
                }}
                style={{ marginTop: "16px", textAlign: "right" }}
              />
            </div>
          </Col>
        </Row>
        {open === "showModal" && (
          <FeesSummary
            tableData={tableData}
            onDismiss={() => {
              setOpen("");
              setTableData([]);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default FeesViewModal;
