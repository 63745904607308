import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Spin, Button, Col, Row, Table, Breadcrumb, message, Select, ConfigProvider, Popconfirm, Space } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import { ActiveInactiveOptions, MasterTypes, RecordStatusType } from "../../../Constant/Enums";
import { BatchMaster } from "../../../ApiUrls/URLS";
import { useQuery } from "@tanstack/react-query";
import HTTPSCalls from "../../../Services/HTTPCalls";
import AddEditBatch from "./AddEditShift";
import AddEditShift from "./AddEditShift";
import dayjs from "dayjs";
const api = new ApiCalls(BatchMaster.endPoints, BatchMaster.prefix);
interface IBatch {
  key: string;
  sNo: number;
  id: string;
  name: string;
  inTime: any;
  outTime: any;
}


//#region  Custom TableHeader
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
  option,
  defaultValue,
  setDepartmentSearchValue
}: any) => {
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorTextPlaceholder: "#1B1A1A",
              colorFillAlter: "transparent",
            },
            Select: {
              colorTextPlaceholder: "#1B1A1A",
            },
          },
        }}
      >
        <div
          className="searchDivStyle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {ifSelect ? (
            <Select
              allowClear
              style={{ width: "100%" }}
              onSelect={(value) => {
                setListParams({...listParams, start: 0 }); // Move this to the top
                if (changeListParams) {
                  changeListParams(inputName, value);
                }
                if (inputName === "departmentSelection") {
                  setListParams({...listParams, departmentId: value });
                  setDepartmentSearchValue(value);
                }
                if (inputName === "course") {
                  setListParams({...listParams, courseId: value });
                }
                setShouldRefresh((x: boolean) =>!x);
              }}
              onClear={() => {
                // if (inputName === "counselors")
                //   changeListParams(inputName, "");
                // else
                //   changeListParams(inputName)
                
                setListParams({ ...listParams, start: 0,departmentId:"",courseId:"" });
                setShouldRefresh((x: boolean) => !x);
              }}
              popupMatchSelectWidth={false}
              variant="borderless"
              size="small"
              className="invSelectVendor"
              placeholder={title}
              options={option}
            // defaultValue={defaultValue}
            />
          ) : (
            <Search
              placeholder={title}
              className="CitSearchInput"
              allowClear
              onChange={(e:any) => {
                if (changeListParams) {
                  changeListParams(inputName, e.target?.value);
                };
                // props?.changeListParams(inputName, e.target?.value)
              }}
              onSearch={(val) => {

                setListParams({ ...listParams, start: 0,search:val });
                setShouldRefresh((x: boolean) => !x);
              }}
              size="small"
              variant="borderless"
            />
          )}
        </div>
      </ConfigProvider>
    </>
  );
};
//#endregion


const ShiftList: React.FC = () => {
  const [listParams, setListParams] = useState({
    search: "",
    departmentId:"",
    courseId:"",
    sortCol: "Name",
    sortDir: "ascend",
  });
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [batchId, setBatchId] = useState<string | undefined>();
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [recordData, setRecordData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any>([]);
    const [changedStatus, setChangedStatus] = useState<any>("active");
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  


  useEffect(() => {
    setPageLoader(true);
    getShitList(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems,refresh,changedStatus]);


  
  const getShitList = async (start: number, length: number) => {
    try {
      setLoading(true);
      let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.POST_MASTER + "?", {
        masterType: MasterTypes.Shift,
        start: start,
        length: length,
        search: listParams?.search,
        status: changedStatus,
        sortCol: "createdBy.Date",
      });
      if (res?.status) {
        setDataSource(res?.result?.items);
        setPagingItems((p) => {
          p.totalRecords = res?.result?.totalRecords;
          return p;
        });
      } else {
        message.warning(res?.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const UpdateRecordStatus = async (
    noticeId?: string,
    action: string = "delete"
  ) => {
    try {
      if (!noticeId) {
        message.error("Faculty type ID is missing. Unable to update record status.");
        return;
      }
      setLoading(true);
      await HTTPSCalls.POST(
        `${HTTPSCalls.ENDPOINTS.UPDATE_RECORD_STATUS}` + "?",
        {
          objId: noticeId,
          restore: action,
        },
        {}
      );
      setRefresh((prev) => !prev);
    } catch (error) {
      message.error("An unexpected error occured");
      console.log("Error during API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "6%",
      render: (_, record, index) => <p>{_}</p>,
    },
    {
      title: "Shift Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => text,
    },
    {
      title: "In Time",
      dataIndex: "inTime",
      key: "inTime",
      render: (text: any) => (
        dayjs(text, "HH:mm").format("hh:mm A")
      ),
      },
    {
      title: "Out Time",
      dataIndex: "outTime",
      key: "name",
      render: (text: any) => (
        dayjs(text, "HH:mm").format("hh:mm A")
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (text, record) => (
        <Space size={30}>
          <FormOutlined
            // style={{ color: "#377dff" }}
            className="cursor editIconColour"
            onClick={() => {
              setOpenDrawer((prev: boolean) => !prev);
              setRecordData(record);
            }}
          />
          {record?.recordStatus === RecordStatusType?.Deleted ? (
            <Popconfirm
              //  placement="topLeft"
              title="Are you sure you want to restore this record?"
              onConfirm={() => {
                UpdateRecordStatus(record?.id, "restore");
              }}
              okText="Yes"
              cancelText="No"
            >
              <UndoOutlined className="cursor" />
            </Popconfirm>
          ) : (
            <Popconfirm
              //  placement="topRight"
              title="Are you sure you want to delete this record?"
              onConfirm={() => {
                UpdateRecordStatus(record?.id, "delete");
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className="cursor deleteIconColour" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];



  const setListParamsAndRefresh = (value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams({ ...listParams, search: value });
  };

  return (
    <>
      <AddEditShift 
        setTrigger={setTrigger}
        openDrawer={openDrawer}
        SetDrawerClose={setOpenDrawer}
        recordData={recordData}
        setRecordData={setRecordData}
      />
      <Row
        justify={"space-between"}
        align="middle"
        style={{ marginBottom: "6px" }}
      >
        <ArrowLeftOutlined
          style={{ color: "#1677ff" }}
          onClick={() => {
            navigate("/settings");
          }}
        />
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Batches",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row gutter={12}>
            <Col lg={18}>
            <Button
              type="primary"
              className="cit-add-btn mb-1"
              onClick={() => {
                setBatchId(undefined);
                setOpenDrawer(true);
              }}
            >
              <PlusOutlined />
              Shift
            </Button>
            </Col>
              <Col lg={3}>
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Search status"
                              optionFilterProp="label"
                              onChange={(val) => {
                                setChangedStatus(val);
                              }}
                              defaultValue={"active"}
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare((optionB?.label ?? "").toLowerCase())
                              }
                              options={ActiveInactiveOptions}
                            />
                          </Col>
                          <Col lg={3}>
            <Search
              size="middle"
              placeholder="Search Name Here..."
              className="att-search-input mb-1"
              allowClear
              onSearch={(val: string) => setListParamsAndRefresh(val)}
              onChange={(e: any) =>
                e.target.value === "" ? setListParamsAndRefresh("") : null
              }
              // style={{ width: 200 }}
            />
                          </Col>
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "12px" }}>
          <Table
            className="cit-table"
            loading={{
              spinning: loading,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            size="small"
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: ["15", "25", "50", "100"],
            }}
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                setListParams({
                  ...listParams,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey,
                });
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ShiftList;
