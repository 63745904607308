import * as React from "react";
import { useState, useEffect } from "react";
import {
  DeleteOutlined,
  EyeFilled,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import Upload, { RcFile } from "antd/es/upload";
import { ApiCalls } from "../ApisCaller/ApiCall";
import { EnquiryEndPoint } from "../ApiUrls/URLS";
import {
  AttachmentsCategory,
  CandidateRequestType,
  IndianState,
  MasterTypes,
  nationalityOption,
} from "../Constant/Enums";
import HTTPSCalls from "../Services/HTTPCalls";
import "./../component/EnquiryLeadModel/EnquiryLead.css";
import ImageUploader from "../UI/ImageUploader/ImageUploader";
import { convertToAntForm } from "./EnquiryFormUtil";
interface ISignature {
  name: string;
  id: string;
  path: string;
  contentType: string;
}
const api = new ApiCalls(EnquiryEndPoint, "Enquiry/");

interface EnquiryProps {
  setShouldRefresh?:any
  shouldRefresh?:any;
  userType?: string;
  isOpen: boolean;
  type: any;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  onDismiss: (rec: boolean) => void;
  credentialDetail?: any;
  basicDetail?: any;
  apiData: any;
}

var guardianAddress: any;
var candidateAddress: any;

const EnquiryFormStudents = (props: EnquiryProps) => {

  
  const [form] = Form.useForm();
  // const [address, setAddress] = React.useState<boolean>(false);
  const [clearImage, setClearImage] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentprevAddress, setCurrentprevAddress] = useState("");
  const [studentSignature, setStudentSignature] = React.useState<ISignature[]>(
    []
  );

  // const setFormData = async (apiData: any) => {
  //   const formVal = {
  //     ...apiData,
  //     gender: {
  //       label: apiData?.gender?.name,
  //       value: apiData?.gender?.id,
  //     },
  //     medium: {
  //       label: apiData?.medium?.name,
  //       value: apiData?.medium?.id,
  //     },
  //     category: {
  //       label: apiData?.category?.name,
  //       value: apiData?.category?.id,
  //     },
  //     curriculum: {
  //       label: apiData?.curriculum?.name,
  //       value: apiData?.curriculum?.id,
  //     },
  //     batch: {
  //       label: apiData?.batch?.name,
  //       value: apiData?.batch?.id,
  //     },
  //     counselorName: {
  //       label: apiData?.counselorName?.name,
  //       value: apiData?.counselorName?.id,
  //     },
  //     // state: IndianStateOptions.filter(
  //     //   (itm) => itm.value === apiData?.state
  //     // ),
  //     // guardianState: IndianStateOptions.filter(
  //     //   (itm) => itm.value === apiData?.guardianState
  //     // ),
  //     // nationality:nationalityOption[apiData?.nationality],
  //     stream: {
  //       label: apiData?.stream?.name,
  //       value: apiData?.stream?.id,
  //     },
  //     sources: {
  //       label: apiData?.sources?.name,
  //       value: apiData?.sources?.id,
  //     },
  //     assignTo: apiData?.assignTo?.map((itm: any) => {
  //       return {
  //         label: itm?.name,
  //         value: itm?.id,
  //       };
  //     }),
  //     englishProficiency: {
  //       label: LevelObj[apiData?.englishProficiency],
  //       value: apiData?.englishProficiency,
  //     },

  //     dateOfBirth:
  //       // apiData?.dateOfBirth === ""
  //       //   ? undefined
  //       //   :
  //       dayjs(apiData?.dateOfBirth),
  //     academicRecord: apiData?.academicRecord?.map(
  //       (arrayItem: any, index: any) => ({
  //         key: index,
  //         ...arrayItem,
  //         class: {
  //           label: arrayItem?.class?.name,
  //           value: arrayItem?.class?.id,
  //         },
  //         curriculum: {
  //           label: arrayItem?.curriculum?.name,
  //           value: arrayItem?.curriculum?.id
  //         },
  //         stream: {
  //           label: arrayItem?.stream?.name,
  //           value: arrayItem?.stream?.id
  //         },
  //         studyMode: {
  //           label: StudyModeObj[arrayItem?.studyMode],
  //           value: arrayItem?.studyMode,
  //         },

  //       })
  //     ),
  //     attachments: apiData?.attachments
  //       ?.filter(
  //         (itm: any) => itm?.category === AttachmentsCategory.StudentDocuments
  //       )
  //       ?.map((itm: any, index: any) => {
  //         return {
  //           key: index,
  //           ...itm,
  //           fileType: {
  //             label: itm?.fileType?.name,
  //             value: itm?.fileType?.id,
  //           },
  //         };
  //       }),
  //     approvalStatus: {
  //       label: apiData?.approvalStatus?.name,
  //       value: apiData?.approvalStatus?.id,
  //     },
  //     amount: apiData?.amount,
  //     cardNo: apiData?.cardNo,
  //     studentId: apiData?.studentId,
  //     enrolledDate: dayjs(apiData?.enrolledDate),
  //     enquiryDate: dayjs(apiData?.enquiryDate),
  //     credentials: apiData?.credentials?.map((arrayItem: any, index: any) => ({
  //       ...arrayItem,
  //       key: index,
  //       credentialName: {
  //         value: arrayItem?.credentialName?.id,
  //         label: arrayItem?.credentialName?.name,
  //       },
  //     })),
  //   };

  // };

  useEffect(() => {
    if (props?.apiData) form.setFieldsValue(convertToAntForm(props?.apiData));
  }, [props?.apiData]);
  // console.log("apiData = ", props?.apiData);
  // const navigate = useNavigate();
  const [studentImage, setStudentImage] = React.useState<ISignature[]>([]);
  const [counselorSignature, setCounselorSignature] = useState<ISignature[]>(
    []
  );
  const [counselors, setCounselors] = useState([{}]);
  useEffect(() => {
    getList()
  }, [])
  const getList = async () => {
    let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_COUNSELORS_LIST + "?", {  })
    if (res?.result?.length > 0) {
      setCounselors(res?.result?.map((item: any) => ({
          value: item?.id,
          label: item?.name,
          // role: item?.role,
      })))
    }
  }
  const [masterData, setMasterData] = React.useState<{
    gender: any;
    curriculum: any;
    classes: any;
    streams: any;
    category: any;
    credentials: any;
    document: any;
    status: any;
    batch: any;
    medium: any;
    source: any;
    assignTo: any;
  }>({
    gender: [],
    curriculum: [],
    classes: [],
    streams: [],
    category: [],
    credentials: [],
    document: [],
    status: [],
    batch: [],
    medium: [],
    source: [],
    assignTo: [],
  });
  const [attachmentList, setAttachmentList] = React.useState<any[]>([
    {
      fileType: "",
      fileName: {
        id: "",
        name: "",
        contentType: "",
        length: 0,
        path: "",
      },
      description: "",
    },
  ]);
  // const IndianStateOptions = Object.entries(IndianState).map(
  //   ([label, value]) => ({
  //     label,
  //     value,
  //   })
  // );

  const resetForm = () => {
    setClearImage(!clearImage);
    setCounselorSignature([]);
    setStudentImage([]);
    setStudentSignature([]);
    form.resetFields();
    props.onDismiss(false);
  };

  const handleClick = () => {
    form.validateFields().then(async (val: any) => {
      
      let formValue = {
        id: props?.apiData?.id,
        ...val,
        counsellingSession: props?.apiData?.counsellingSession,
        ...(props?.apiData?.qualification && { qualification: props?.apiData?.qualification }),
        credentials: props?.apiData?.credentials,
        category: { id: val?.category?.value, name: val?.category?.label },
        curriculum: {
          id: val?.curriculum?.value,
          name: val?.curriculum?.label,
        }, 
        bloodGroup:val?.bloodGroup,
        nationality: val?.nationality,
        currentClass:{ id: val?.currentClass?.value, name: val?.currentClass?.label },
        medium: { id: val?.medium?.value, name: val?.medium?.label },
        gender: { id: val?.gender?.value, name: val?.gender?.label },
        batch: { id: val?.batch?.value, name: val?.batch?.label },
        englishProficiency: val?.englishProficiency?.value,
        stream: { id: val?.stream?.value, name: val?.stream?.label },
        sources: { id: val?.sources?.value, name: val?.sources?.label },
        assignTo: val?.assignTo?.map((itm: any) => ({
          id: itm?.value,
          name: itm?.label,
        })),
        contactDetail:{
          currentAddress:val?.contactDetail?.currentAddress,
          permanentAddress:val?.contactDetail?.permanentAddress,
          email:val?.contactDetail?.email,
          mobileNumber:val?.contactDetail?.mobileNumber,
          emergencyNumber:val?.contactDetail?.emergencyNumber
        },
        counselors: val?.counselors?.map((itm: any) => ({
          id: itm?.value,
          name: itm?.label,
        })),
        counselor: { id: val?.counselor?.value, name: val?.counselor?.label },

        amount: props?.apiData?.amount || 260000,
        //   ? val?.state.length > 0
        //     ? val?.state[0]?.value
        //     : undefined
        //   : val?.state,
        //   ? val?.guardianState.length > 0
        //     ? val?.guardianState[0]?.value
        //     : undefined
        //   : val?.guardianState,
        academicRecord: val?.academicRecord?.map((arrayItem: any) => {
          return {
            class: {
              id: arrayItem?.class?.value,
              name: arrayItem?.class?.label,
            },
            curriculum: {
              id: arrayItem?.curriculum?.value,
              name: arrayItem?.curriculum?.label,
            },
            stream: {
              id: arrayItem?.stream?.value,
              name: arrayItem?.stream?.label,
            },
            schoolName: arrayItem?.schoolName,
            year: arrayItem?.year,
            marks: arrayItem?.marks,
            percentage: arrayItem?.percentage,
            studyMode: arrayItem?.studyMode?.value,
          };
        }),
        signature: {
          candidate:
            studentSignature?.length > 0
              ? studentSignature?.map((arrayItem: any) => {
                  return {
                    name: arrayItem?.name,
                    id: "",
                    path: arrayItem?.thumbUrl,
                    contentType: arrayItem?.type,
                  };
                })[0]
              : {
                  name: "",
                  id: "",
                  path: "",
                  contentType: "",
                },
          counselor:
            counselorSignature?.length > 0
              ? counselorSignature?.map((arrayItem: any) => {
                  return {
                    name: arrayItem?.name,
                    id: "",
                    path: arrayItem?.thumbUrl,
                    contentType: arrayItem?.type,
                  };
                })[0]
              : {
                  name: "",
                  id: "",
                  path: "",
                  contentType: "",
                },
        },
        candidateImage:
          studentImage?.length > 0
            ? studentImage?.map((arrayItem: any) => {
                return {
                  name: arrayItem?.name,
                  id: "",
                  path: arrayItem?.thumbUrl,
                  contentType: arrayItem?.type,
                };
              })[0]
            : {
                name: "",
                id: "",
                path: "",
                contentType: "",
              },
        attachments:
          val?.attachments?.length > 0
            ? val?.attachments?.map((item: any, index: number) => {
                return {
                  fileType: {
                    id: item?.fileType?.value,
                    name: item?.fileType?.label,
                  },
                  fileName: {
                    id: item?.fileName?.id ? item?.fileName?.id : "",
                    name: item?.fileName?.file?.name
                      ? item?.fileName?.file?.name
                      : item?.fileName?.name
                      ? item?.fileName?.name
                      : "",
                    contentType: item?.fileName?.file?.type
                      ? item?.fileName?.file?.type
                      : item?.fileName?.contentType
                      ? item?.fileName?.contentType
                      : "",
                    length: item?.fileName?.file?.size
                      ? item?.fileName?.file?.size
                      : item?.fileName?.length,
                    path: attachmentList[index]?.fileName?.path
                      ? attachmentList[index]?.fileName?.path
                      : item?.fileName?.path,
                  },
                  description: item?.description,
                  category: AttachmentsCategory.StudentDocuments,
                  ...(item?.fileName?.id && {updateInfo:item.updateInfo})
                };
              })
            : [],
      };
      // let studentDocsAtt: any = null;
      // let otherDocAtt: any = props?.apiData?.attachments?.filter(
      //   (x: any) => x?.category !== AttachmentsCategory.StudentDocuments
      // );
      // let totalDocAtt: any = null;
      // if (formValue?.attachments) {
      //   studentDocsAtt = [...formValue?.attachments];
      //   totalDocAtt = [...formValue?.attachments];
      // }
      // if (otherDocAtt) {
      //   totalDocAtt = [...studentDocsAtt, ...otherDocAtt];
      // }
      // let finalVal = {
      //   ...formValue,
      //   attachments: totalDocAtt,
      // };
      // console.log(val,"finalVal",formValue)
      setButtonLoading(true);
      let response = await api.POST(
        EnquiryEndPoint.save + "?",
        { type: CandidateRequestType.Enquire },
        formValue
      );
      if (response.status) {
        // props.setShouldRefresh((pre:any)=>!pre)
        resetForm();
        setButtonLoading(false);
        // resetForm();
        props.onDismiss(true);
        message.success("Enquiry Submitted");
      } else {
        setButtonLoading(false);
        message.error(response.message);
      }
    });
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handelFile = async (file: any, index: number) => {
    let prevAtt = [...attachmentList];

    let currentFileBase64 = await getBase64(file as RcFile);

    let fileInfo = {
      id: "",
      name: file?.name,
      contentType: file?.type,
      length: file?.size,
      path: currentFileBase64,
    };

    prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

    setAttachmentList(prevAtt);
  };

  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      let obj: {
        gender: any;
        curriculum: any;
        classes: any;
        streams: any;
        category: any;
        credentials: any;
        document: any;
        status: any;
        batch: any;
        medium: any;
        source: any;
        assignTo: any;
      } = {
        gender: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Gender
        )?.[0]?.items,
        curriculum: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Curriculum
        )?.[0]?.items,
        classes: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Classes
        )?.[0]?.items,
        streams: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Stream
        )?.[0]?.items,
        category: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Category
        )?.[0]?.items,
        credentials: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Credential
        )?.[0]?.items,
        document: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Documents
        )?.[0]?.items,
        status: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Status
        )?.[0]?.items,
        batch: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Batch
        )?.[0]?.items,
        medium: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Medium
        )?.[0]?.items,
        source: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Source
        )?.[0]?.items,
        assignTo: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.AssignTo
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
    } else {
      message.error(res?.message);
    }
  };

  useEffect(() => {
    getMaster();
  }, []);

  const validateCurrentPassOutYear = async (rule: any, val: any) => 
    {
    if (val < 2000) throw new Error("Current PassOut Year >= 2000");
    if (val > new Date().getFullYear()) {
      throw new Error(`Current PassOut <=${new Date().getFullYear()}`);
    }
  };
  return (
    // <div className="roundedCornerSmall bg-white" style={{ padding: "20px" }}>
    <Card
      title={<>{!props?.apiData?.id ? "Add" : "Edit"} Student Details</>}
      extra={
        <>
          <Row justify={"end"} gutter={8}>
            <Col>
              <Button
                type="default"
                onClick={() => {
                  // navigate("/enquiry")
                  props?.onDismiss(false);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => handleClick()}
                loading={buttonLoading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </>
      }
    >
      {/* <Spin spinning={loading}> */}
      {/* <Affix offsetTop={120}>
        <Row justify={"end"} gutter={8}>
          <Col>
            <Button
              type="default"
              onClick={() => {
                // navigate("/enquiry")
                props?.onDismiss(false);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => handleClick()}
              loading={buttonLoading}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Affix> */}
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          enquiryDate: dayjs(),
          nationality: "Indian",
        }}
        // onFinish={handleClick}
        // requiredMark={false}
      >
        {/* <span>
          <ArrowLeftOutlined
            onClick={() => {
              // navigate("/enquiry")
              props?.onDismiss(false);
            }}
          />
        </span> */}
        {/* <Button
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              props?.onDismiss(false);
            }}
          /> */}
        <div style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}>
          <div
            style={{
              color: "#4c4c4c",
              marginBottom: "10px",
              fontSize: 15,
              fontWeight: 600,
            }}
          >
            General Information<br></br>
            <span style={{ color: "gray", fontSize: "12px" }}>
              All the information should be similar to your Documents
            </span>
          </div>
          <Row gutter={16}>
            {/* <Col sm={12} lg={4} xs={24}>
            <Form.Item
              name="incrementalSeries"
              label="Enquiry Id"
            // tooltip="This is a required field"
            >
              <Input
                style={{ width: "100%" }}
                disabled
                onInput={(e: any) => {
                  e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase();
                }}
              />
            </Form.Item>
          </Col> */}
            {/* <Col sm={12} lg={6} xs={24}>
            <Form.Item label="Course" name="course">
              <Select
                showSearch
                disabled
                style={{ width: "100%" }}
                optionFilterProp="children"
                labelInValue
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "",
                    label: "",
                  },
                ]}
              />
            </Form.Item>
          </Col> */}

            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Enquiry date cant'be empty" },
                ]}
                label="Enquiry Date"
                name="enquiryDate"
              >
                <DatePicker
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  // disabledDate={(current: any) => {
                  //   return current && current > dayjs().endOf("day");
                  // }}
                />
              </Form.Item>
            </Col>
            {props?.apiData?.id && (
              <Col sm={12} lg={4} xs={24}>
                <p> Enquiry Status</p>
                <p
                  style={{
                    marginTop: "revert",
                    fontWeight: "bolder",
                    color: props?.apiData?.reject ? "red" : "black",
                  }}
                >
                  {props?.apiData?.reject
                    ? "Rejected"
                    : props?.apiData?.isEnrolled
                    ? "Enrolled"
                    : "Enquiry"}
                </p>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                name="name"
                label="First Name"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input First Name!",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z_ /,-]+$/i),
                    message: "Please input alphabets only!",
                  },
                  {
                    whitespace: true,
                    message: "Invalid name",
                  },
                ]}
                // tooltip="This is a required field"
              >
                <Input
                  style={{ width: "100%" }}
                  onInput={(e: any) => {
                    e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase();
                  }}
                  onKeyPress={(e) =>
                    !/[a-z ]/.test(e.key) && e.preventDefault()
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Middle Name" name="middleName">
                <Input
                  style={{ width: "100%" }}
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                  onKeyPress={(e) =>
                    !/[a-z ]/.test(e.key) && e.preventDefault()
                  }
                />
              </Form.Item>
            </Col>

            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input Last Name!",
                  // },
                  {
                    pattern: new RegExp(/^[a-zA-Z_ /,-]+$/i),
                    message: "Please input alphabets only!",
                  },
                  {
                    whitespace: true,
                    message: "Invalid name",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                  onKeyPress={(e) =>
                    !/[a-z ]/.test(e.key) && e.preventDefault()
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Date of Birth"
                name="dateOfBirth"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input Date of Birth!",
                //   },
                // ]}
              >
                <DatePicker
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  disabledDate={(current: any) => {
                    return current && current > dayjs().endOf("day");
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Blood Group" name="bloodGroup">
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item
                label="Other Languages"
                name="otherLanguages"
                rules={[
                  {
                    required: true,
                    message: "Please input your Other Languages!",
                  },
                ]}
              >
                <Input name="Other Languages" placeholder="Other Languages" />
              </Form.Item>
            </Col> */}
            {/* <Col sm={12} lg={4} xs={24}>
            <Form.Item
              label="Date of Birth"
              name="dateOfBirth"
              rules={[
                {
                  required: true,
                  message: "Please input Date of Birth!",
                },
              ]}
            >
              <DatePicker
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
                disabledDate={(current: any) => {
                  return current && current > dayjs().endOf("day");
                }}
              />
            </Form.Item>
          </Col> */}
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Gender"
                name="gender"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select Gender!",
                //   },
                // ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Gender"
                  optionFilterProp="children"
                  labelInValue
                  allowClear
                  popupMatchSelectWidth={true}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={masterData?.gender}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Nationality" name="nationality">
                <Select
                  // labelInValue
                  placeholder="Nationality"
                  options={nationalityOption}
                />
                {/* <Input
                  style={{ width: "100%" }}
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                /> */}
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="English Proficiency"
                name="englishProficiency"
                rules={[
                  {
                    required: true,
                    message: "Please select Languages!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Languages: English Proficiency"
                  optionFilterProp="children"
                  labelInValue
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: 0,
                      label: "Beginner",
                    },
                    {
                      value: 1,
                      label: "Intermediate",
                    },
                    {
                      value: 2,
                      label: "Advanced",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Status"
                name="response"
                rules={[
                  {
                    required: true,
                    message: "Please select Status!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  popupMatchSelectWidth={true}
                  placeholder="Status"
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{ width: "100%" }}
                  options={[
                    // {
                    //   value: 0,
                    //   label: "None"
                    // },
                    {
                      value: 1,
                      label: "Interested",
                    },
                    {
                      value: 2,
                      label: "Not Interested",
                    },
                    {
                      value: 3,
                      label: "DNP",
                    },
                    {
                      value: 4,
                      label: "Follow Up",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {/* <Col sm={12} lg={6} xs={24}>
            <Form.Item label="Reference" name="reference">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Where you got the reference"
                optionFilterProp="children"
                labelInValue
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "",
                    label: "",
                  },
                ]}
              />
            </Form.Item>
          </Col> */}
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Sources" name="sources">
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Where you got the sources"
                  optionFilterProp="children"
                  labelInValue
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={masterData.source}
                />
              </Form.Item>
            </Col>
            {/* <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Assign To" name="assignTo">
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Choose employee"
                  optionFilterProp="children"
                  labelInValue
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={masterData.assignTo}
                />
              </Form.Item>
            </Col> */}

            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Center Visited" name="centerVisited">
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Center Visited"
                  optionFilterProp="children"
                  popupMatchSelectWidth={true}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 0,
                      label: "None",
                    },
                    {
                      value: 1,
                      label: "Yes",
                    },
                    {
                      value: 2,
                      label: "No",
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col sm={18} lg={4} xs={24}>
              <Form.Item
                label="Counselling"
                name="counselors"
                // rules={[
                //   {
                //     required: true,
                //     message: "This field is required",
                //   },
                // ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="Counselors"
                  optionFilterProp="children"
                  labelInValue
                  allowClear
                  popupMatchSelectWidth={true}
                  filterOption={(input, option: any) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  //    <>
                  //      {menu}
                  //      <AddInOption
                  //        funcType={"class"}
                  //        setRefresh={setRefresh}
                  //      />
                  //    </>
                  //  )}
                  options={counselors}
                />
                {/* <Input
                    placeholder="Counselor Name"
                    style={{ width: "100%" }}
                  /> */}
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Counselor" name="counselor">
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Counselor"
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  labelInValue
                  allowClear
                  popupMatchSelectWidth={true}
                  options={counselors}
                  filterOption={(input, option: any) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            {/* <Col sm={12} lg={6} xs={24}>
            <Form.Item label="Counselor Name" name="counselor">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Choose employee"
                optionFilterProp="children"
                labelInValue
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: 0,
                    label: "Beginner",
                  },
                  {
                    value: 1,
                    label: "Intermediate",
                  },
                  {
                    value: 2,
                    label: "Advanced",
                  },
                ]}
              />
            </Form.Item>
          </Col> */}
          </Row>

          <Row gutter={[24, 10]}>
            <Col span={8}>
              <Form.Item
                name="otherInformation"
                label="Other Information"
                // tooltip="This is a required field"
              >
                <Input.TextArea
                  placeholder="Other"
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="referralSource"
                label="Referral Source / Where did you hear about us?"
                // tooltip="This is a required field"
              >
                <Input.TextArea
                  placeholder="Referral Source / Where did you hear about us?"
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              color: "#4c4c4c",
              marginBottom: "10px",
              fontSize: 15,
              fontWeight: 600,
            }}
          >
            Address and Contact
          </div>

          <Row gutter={10}>
            <Col lg={4} md={8} sm={8} xs={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input Mobile No.!",
                  },
                  {
                    pattern: new RegExp(/^\d{10}$/),
                    message: "Invalid number",
                  },
                ]}
                name={["contactDetail", "mobileNumber"]}
                label="Contact Number"

                // tooltip="This is a required field"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  maxLength={10}
                  minLength={10}
                  controls={false}
                  name="mobileNumber"
                  placeholder="+91 0000 0000"
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={8} sm={8} xs={24}>
              <Form.Item
                name={["contactDetail", "emergencyNumber"]}
                label="Emergency Number"

                // tooltip="This is a required field"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  maxLength={10}
                  minLength={10}
                  controls={false}
                  placeholder="+91 0000 0000"
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                />
              </Form.Item>
            </Col>
            <Col lg={4} md={8} sm={8} xs={24}>
              <Form.Item
                // rules={[
                //   {
                //     type: "email",
                //     message: "Please input valid email!",
                //     required: true,
                //   },
                // ]}
                name={["contactDetail", "email"]}
                label="Email"
              >
                <Input placeholder="Email Address" />
              </Form.Item>
            </Col>

            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Permanent Address"
                name={["contactDetail", "permanentAddress"]}
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input Address!",
                  // },
                  {
                    whitespace: true,
                    message: "Invalid name",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="House no, street name, landmark"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                  onChange={(e: any) => {
                    candidateAddress = form.getFieldValue([
                      "contactDetail",
                      "currentAddress",
                    ]);
                    if (candidateAddress === guardianAddress) {
                      form.setFieldValue("sameAsCandidateAddress", true);
                    } else {
                      form.setFieldValue("sameAsCandidateAddress", false);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginBottom: "20px" }}>
              <Checkbox
                onChange={(e) => {
                  const isChecked = e?.target?.checked;
                  const permanentAddress = form.getFieldValue([
                    "contactDetail",
                    "permanentAddress",
                  ]);
                  const currentAddress = form.getFieldValue([
                    "contactDetail",
                    "currentAddress",
                  ]);
                  setCurrentprevAddress(currentAddress);
                  if (isChecked) {
                    form.setFieldValue(
                      ["contactDetail", "currentAddress"],
                      permanentAddress
                    );
                  } else {
                    form.setFieldValue(
                      ["contactDetail", "currentAddress"],
                      currentprevAddress
                    );
                  }
                }}
              >
                <span className="themeColor fw-500">
                  Same as Permanent Address
                </span>
              </Checkbox>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Current Address"
                name={["contactDetail", "currentAddress"]}
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input Address!",
                  // },
                  {
                    whitespace: true,
                    message: "Invalid name",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="House no, street name, landmark"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Zip Code"
                name="zipcode"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input PinCode!",
                  // },
                  {
                    pattern: /^\d{4,6}\b/g,
                    message: "Please input a valid PinCode",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  maxLength={6}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input City Name!",
                  // },
                  {
                    whitespace: true,
                    message: "Invalid name",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="State" name="state">
                <Select
                  showSearch
                  // labelInValue
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={IndianState}
                />

                {/* <Select>
                  {Object.keys(IndianState)?.map((state, index) => (
                    <Option key={index} value={IndianState[state]}>
                      {state}
                    </Option>
                  ))}
                </Select> */}
                {/* <Input
                style={{ width: "100%" }}
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              /> */}
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              color: "#4c4c4c",
              marginBottom: "10px",
              marginTop: "12px",
              fontSize: 15,
              fontWeight: 600,
            }}
          >
            Parents and Guardian
          </div>
          <Row gutter={16}>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Father Name"
                name="fatherName"
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: "Please input your father name!",
                    // },
                  ]
                }
              >
                <Input
                  placeholder="Father Name"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Father Occupation" name="fatherOccupation">
                <Input
                  placeholder="Father Occupation"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Mother Name"
                name="motherName"
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: "Please input your mother name!",
                    // },
                  ]
                }
              >
                <Input
                  placeholder="Mother Name"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Mother Occupation" name="motherOccupation">
                <Input
                  placeholder="Mother Occupation"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Guardian Name"
                name="guardianName"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input Guardian Name!",
                  // },
                  {
                    pattern: new RegExp(/^[a-zA-Z_ /,-]+$/i),
                    message: "Please input alphabets only!",
                  },
                  {
                    whitespace: true,
                    message: "Invalid name",
                  },
                ]}
              >
                <Input
                  placeholder="Guardian Name"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Guardian Relation" name="guardianRelation">
                <Input
                  placeholder="Guardian Relation"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input Mobile No.!",
                  // },
                  {
                    pattern: new RegExp(/^\d{10}$/),
                    message: "Invalid number",
                  },
                ]}
                name="guardianPhone"
                label="Guardian Phone No."

                // tooltip="This is a required field"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  maxLength={10}
                  minLength={10}
                  controls={false}
                  name="mobileNumber"
                  placeholder="+91 0000 0000"
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Guardian Occupation" name="guardianOccupation">
                <Input
                  placeholder="Guardian Occupation"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="sameAsCandidateAddress" valuePropName="checked">
            <Checkbox
              onChange={(e) => {
                if (e?.target?.checked) {
                  form.setFieldValue(
                    "guardianAddress",
                    form.getFieldValue(["contactDetail", "currentAddress"])
                  );
                  form.setFieldValue(
                    "guardianZipCode",
                    form.getFieldValue("zipcode")
                  );
                  form.setFieldValue(
                    "guardianCity",
                    form.getFieldValue("city")
                  );
                  form.setFieldValue(
                    "guardianState",
                    form.getFieldValue("state")
                  );
                  form.validateFields([
                    "guardianAddress",
                    "guardianZipCode",
                    "guardianCity",
                  ]);
                } else {
                  form.setFieldValue("guardianAddress", form.getFieldValue(""));
                  form.setFieldValue("guardianZipCode", form.getFieldValue(""));
                  form.setFieldValue("guardianCity", form.getFieldValue(""));
                  form.setFieldValue("guardianState", form.getFieldValue(""));
                }
              }}
            >
              <span className="themeColor fw-500">
                Same as Candidate Address
              </span>
            </Checkbox>
          </Form.Item>
          <Row gutter={16}>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Guardian Address"
                name="guardianAddress"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input Address!",
                  // },
                  {
                    whitespace: true,
                    message: "Invalid name",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="House no, street name, landmark"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                  onChange={(e: any) => {
                    guardianAddress = form.getFieldValue("guardianAddress");
                    if (candidateAddress === guardianAddress) {
                      form.setFieldValue("sameAsCandidateAddress", true);
                    } else {
                      form.setFieldValue("sameAsCandidateAddress", false);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Zip Code"
                name="guardianZipCode"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input PinCode!",
                  // },
                  {
                    pattern: /^\d{4,6}\b/g,
                    message: "Please input a valid PinCode",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  maxLength={6}
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="City"
                name="guardianCity"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input City Name!",
                  // },
                  {
                    whitespace: true,
                    message: "Invalid name",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Guardian State" name="guardianState">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={IndianState}
                />
              </Form.Item>
            </Col>
          </Row>

          <div
            style={{
              color: "#4c4c4c",
              marginBottom: "10px",
              fontSize: 15,
              fontWeight: 600,
            }}
          >
            Education<br></br>
            <span style={{ color: "gray", fontSize: "12px" }}>
              To enroll in B.Sc. Required 12th grade clear
            </span>
          </div>

          <Row gutter={16}>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Current / Previous Grades"
                name="currentClass"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input Grade!",
                  // },
                  // {
                  //   whitespace: true,
                  //   message: "Invalid name",
                  // },
                ]}
              >
                <>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="current Class"
                    optionFilterProp="children"
                    labelInValue
                    allowClear
                    popupMatchSelectWidth={true}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={masterData?.classes}
                  />
                  {/* <Input
                    style={{ width: "100%" }}
                    placeholder="12th"
                    onInput={(e: any) =>
                      (e.target.value =
                        e.target.value.length > 1
                          ? e.target.value
                          : e.target.value.toUpperCase())
                    }
                  /> */}
                </>
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Current / Previous School Name"
                name="currentSchoolName"
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: "Please select School Name!",
                    // },
                  ]
                }
              >
                <Input />
                {/* <Select
                style={{ width: "100%" }}
                placeholder="Select School Name"
                optionFilterProp="children"
                labelInValue
                allowClear
                popupMatchSelectWidth={true}
                showSearch
                // filterOption={(input, option) =>
                //   (option?.label?.toString() ?? "")
                //     .toLowerCase()
                //     .includes(input.toLowerCase())
                // }
                options={[]}
              /> */}
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Medium"
                name="medium"
                rules={[
                  {
                    required: true,
                    message: "Please select medium!",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Hindi/English"
                  optionFilterProp="children"
                  labelInValue
                  allowClear
                  popupMatchSelectWidth={true}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={masterData?.medium}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                name="curriculum"
                rules={[
                  {
                    required: true,
                    message: "Please select Curriculum!",
                  },
                ]}
                label="Curriculum"
                required
                // tooltip="This is a required field"
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Curriculum"
                  optionFilterProp="children"
                  labelInValue
                  allowClear
                  popupMatchSelectWidth={true}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={masterData?.curriculum}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                name="currentPassOutYear"
                rules={[
                  {
                    required: true,
                    message: "Please input Current Passout Year!",
                  },
                  {
                    validator: validateCurrentPassOutYear,
                    // message: "Invalid Year",
                  },
                ]}
                label="Current Passout Year"
              >
                <InputNumber
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  minLength={4}
                  maxLength={4}
                  style={{ width: "100%" }}
                  controls={false}
                  name="current Passout Year"
                  placeholder="Current  Passout Year"
                  formatter={(value: any) =>
                    value ? `${parseInt(value, 10)}` : ""
                  } // Format the value to remove decimal parts
                  parser={(value) => (value ? `${parseInt(value, 10)}` : "")} // Parse the value to remove decimal parts
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                name="stream"
                rules={[
                  {
                    required: true,
                    message: "Please select Stream!",
                  },
                ]}
                label="Stream"
                required
                // tooltip="This is a required field"
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Stream:"
                  optionFilterProp="children"
                  labelInValue
                  allowClear
                  popupMatchSelectWidth={true}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={masterData?.streams}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Percentage"
                name="percentage"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input percentage",
                  // },
                  {
                    validator(rule: any, val: any, callback) {
                      if (val > 100) {
                        callback("Please enter correct percentage");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  // maxLength={3}
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="academicRecord">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={index}>
                    <Row gutter={10} style={{ marginTop: "20px" }}>
                      <Col sm={6} lg={2}>
                        <Form.Item
                          name={[name, "class"]}
                          {...restField}
                          rules={[
                            {
                              required: true,
                              message: "Please input grade/graduate",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Grade/Graduate"
                            optionFilterProp="children"
                            labelInValue
                            allowClear
                            popupMatchSelectWidth={true}
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={masterData?.classes}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={4} lg={5}>
                        {" "}
                        <Form.Item
                          name={[name, "schoolName"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing class",
                            },
                          ]}
                          {...restField}
                        >
                          <Input placeholder="School/Collage Name" />
                          {/* <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="School/Collage Name"
                          optionFilterProp="children"
                          labelInValue
                          allowClear
                          popupMatchSelectWidth={true}
                          filterOption={(input, option) =>
                            (option?.label?.toString() ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={masterData?.classes}
                        /> */}
                        </Form.Item>
                      </Col>
                      <Col sm={3} lg={2}>
                        {" "}
                        <Form.Item
                          name={[name, "year"]}
                          {...restField}
                          rules={[
                            {
                              required: true,
                              message: "Please input year!",
                            },
                            {
                              validator: validateCurrentPassOutYear,
                              // message: "Invalid Year",
                            },
                          ]}
                        >
                          <InputNumber
                            minLength={4}
                            maxLength={4}
                            style={{ width: "100%" }}
                            controls={false}
                            placeholder="Year"
                            formatter={(value: any) =>
                              value ? `${parseInt(value, 10)}` : ""
                            } // Format the value to remove decimal parts
                            parser={(value) =>
                              value ? `${parseInt(value, 10)}` : ""
                            } // Parse the value to remove decimal parts
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={3} lg={2}>
                        {" "}
                        <Form.Item
                          name={[name, "percentage"]}
                          {...restField}
                          rules={[
                            {
                              pattern: new RegExp(/^[0-9]/i),
                              message: "Invalid percent!",
                            },
                            {
                              required: true,
                              message: "Percent is Required",
                            },
                            {
                              validator(rule: any, val: any, callback) {
                                if (val > 100) {
                                  callback("Please enter correct percentage");
                                } else {
                                  callback();
                                }
                              },
                            },
                          ]}
                        >
                          <Input
                            suffix={"%"}
                            placeholder="Percent"
                            // minLength={2}
                            // maxLength={3}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={4} lg={2}>
                        {" "}
                        <Form.Item
                          name={[name, "studyMode"]}
                          {...restField}
                          rules={[
                            {
                              required: true,
                              message: "Missing study mode",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Study Mode"
                            labelInValue
                            allowClear
                            style={{ width: "100%" }}
                            options={[
                              {
                                value: 0,
                                label: "Regular",
                              },
                              {
                                value: 1,
                                label: "Private",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={3}>
                        <Form.Item
                          name={[name, "curriculum"]}
                          {...restField}
                          rules={[
                            {
                              required: true,
                              message: "Please select Curriculum!",
                            },
                          ]}
                          required
                          // tooltip="This is a required field"
                        >
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select Curriculum"
                            optionFilterProp="children"
                            labelInValue
                            allowClear
                            popupMatchSelectWidth={true}
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={masterData?.curriculum}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={4}>
                        <Form.Item
                          name={[name, "stream"]}
                          {...restField}
                          rules={[
                            {
                              required: true,
                              message: "Please select Stream!",
                            },
                          ]}
                          required
                          // tooltip="This is a required field"
                        >
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Stream:"
                            optionFilterProp="children"
                            labelInValue
                            allowClear
                            popupMatchSelectWidth={true}
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={masterData?.streams}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={1}>
                        {/* {index > 0 ? ( */}
                        <Button
                          onClick={() => {
                            // if (index !== 0)
                            remove(index);
                          }}
                          icon={<DeleteOutlined className="cursor deleteIconColour"/>}
                        />
                        {/* ) : null} */}
                      </Col>
                    </Row>
                  </div>
                ))}

                <Form.Item className="inputBoxMb">
                  <Row key={"add contact"}>
                    <Col>
                      <Button
                        type="primary"
                        ghost
                        size="middle"
                        onClick={() => add()}
                        // block
                        icon={<PlusOutlined />}
                        disabled={fields?.length < 5 ? false : true}
                      >
                        Add Education
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}
          </Form.List>
          <div
            style={{
              color: "#4c4c4c",
              marginBottom: "10px",
              fontSize: 15,
              fontWeight: 600,
            }}
          >
            Upload Documents<br></br>
            <span style={{ color: "gray", fontSize: "12px" }}>
              Please upload all the necessary document to enroll
            </span>
          </div>
          <Row gutter={[24, 16]}>
            <Col sm={5}>
              <Form.Item label="Photo">
                <ImageUploader
                  defaultImages={[]}
                  setFileUrls={setStudentImage}
                  limit={1}
                  shouldPreview={true}
                  children={undefined}
                  shouldCrop={false}
                  clearImage={clearImage}
                  source={form.getFieldValue("candidateImage")?.path}
                />
              </Form.Item>
            </Col>
            <Col sm={5}>
              {/* <Form.Item label="Signature">
                <ImageUploader
                  defaultImages={[]}
                  setFileUrls={setStudentSignature}
                  limit={1}
                  shouldPreview={true}
                  children={undefined}
                  shouldCrop={false}
                  clearImage={clearImage}
                  source={form.getFieldValue("signature")?.candidate?.path}
                />
              </Form.Item> */}
            </Col>
            <Col>
              {/* <Form.Item label="Counselor Signature">
                <ImageUploader
                  defaultImages={[]}
                  setFileUrls={setCounselorSignature}
                  limit={1}
                  shouldPreview={true}
                  children={undefined}
                  shouldCrop={false}
                  clearImage={clearImage}
                  source={form.getFieldValue("signature")?.counselor?.path}
                />
              </Form.Item> */}
            </Col>
          </Row>
          {/* {studentIsEdited ? ( */}
          <Form.Item>
            <Form.List
              name={"attachments"}
              //   initialValue={[{}]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(
                    ({ key, name, ...restField }, index, attachments) => {
                      return (
                        <div key={index}>
                          <Row gutter={[20, 20]} key={index}>
                            <Col xl={6} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "description"]}
                                label={index === 0 ? "" : ""}
                              >
                                <Input.TextArea
                                  placeholder="Description"
                                  autoSize={{
                                    minRows: 1,
                                    maxRows: 6,
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={5} xl={5} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "fileType"]}
                                label={index === 0 ? "" : ""}
                                rules={[
                                  {
                                    required: true,
                                    message: "Select Doc Type",
                                  },
                                ]}
                              >
                                <Select
                                  // options={document}
                                  placeholder="Select Doc Type"
                                  options={masterData?.document}
                                  labelInValue
                                  allowClear
                                  popupMatchSelectWidth={true}
                                  filterOption={(input, option) =>
                                    (option?.label?.toString() ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  // dropdownRender={(menu) => (
                                  //   <>
                                  //     {menu}
                                  //     <AddInOption
                                  //       funcType={"document"}
                                  //       setRefresh={setRefresh}
                                  //     />
                                  //   </>
                                  // )}
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={4} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "fileName"]}
                                label={index === 0 ? "" : ""}
                                rules={[
                                  {
                                    required: true,
                                    message: "Choose Doc",
                                  },
                                ]}
                              >
                                <Upload
                                  defaultFileList={[]}
                                  beforeUpload={(file: any) => {
                                    handelFile(file, index);
                                    return false;
                                  }}
                                  maxCount={1}

                                  //   beforeUpload={(
                                  //     file: any
                                  //   ) => {
                                  //     return true;
                                  //   }}
                                >
                                  <Tooltip
                                    title={
                                      form.getFieldsValue(["attachments"])
                                        ?.attachments[name]?.fileName?.name
                                    }
                                  >
                                    <Button icon={<UploadOutlined />}>
                                      {form.getFieldsValue(["attachments"])
                                        ?.attachments[name]?.fileName?.id
                                        ? form.getFieldsValue(["attachments"])
                                            ?.attachments[name]?.fileName?.name
                                            ?.length > 10
                                          ? form
                                              .getFieldsValue(["attachments"])
                                              ?.attachments[
                                                name
                                              ]?.fileName?.name?.substr(0, 5) +
                                            "..."
                                          : form.getFieldsValue(["attachments"])
                                              ?.attachments[name]?.fileName
                                              ?.name
                                        : "Upload"}
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </Col>
                            {/* {index !== 0 ? ( */}
                            <Col xl={2} sm={12}>
                              <Button
                                style={
                                  index === 0
                                    ? {
                                        borderRadius: "5px",
                                        // marginTop: "25px",
                                      }
                                    : {
                                        borderRadius: "5px",
                                        // marginTop: "2px",
                                      }
                                }
                                onClick={() => {
                                  remove(name);
                                }}
                                icon={<DeleteOutlined className="deleteIconColour" />}
                              />
                            </Col>
                            {form.getFieldsValue(["attachments"])?.attachments[
                              name
                            ]?.fileName?.id ? (
                              <Col xl={1}>
                                <Form.Item label="">
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      window.open(
                                        `${
                                          form.getFieldsValue(["attachments"])
                                            ?.attachments[name]?.fileName?.path
                                        }`,
                                        "_blank"
                                      )
                                    }
                                    icon={<EyeFilled />}
                                  >
                                    Preview
                                  </Button>
                                </Form.Item>
                              </Col>
                            ) : null}

                            {/* ): null} */}
                          </Row>
                        </div>
                      );
                    }
                  )}
                  <Form.Item className="m-0 pb-4">
                    <Button
                      type="primary"
                      ghost
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                    >
                      Attachment
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
};

export default EnquiryFormStudents;
