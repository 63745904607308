import React, { useEffect, useState } from "react";
import { Col, Modal, Pagination, Row, Table } from "antd";
import HTTPSCalls from "../../Services/HTTPCalls";
import { ColumnsType } from "antd/es/table/interface";
import { pageSizeOption } from "../LMS/SubjectAlllocation/SubjectAllocation";
import { useSelector } from "react-redux";
import { selectBatchState } from "../../Store/Slice/batchSlice";

const StreamViewModal = (props: any) => {
  const [viewStreamData, setViewStreamData] = useState<any>([]);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 10 });
  const [modelLoading, setModelLoading] = useState<boolean>(false);

  //   const [listParams, setListParams] = useState({
  //     streamSearch: "",
  //     batchSearch: "",
  //     start: 0,
  //     length: 15,
  //     search: "",
  //     sortCol: "Student.Name",
  //     sortDir: "ascend",
  //     feesType: "",
  //   });
  const batchState = useSelector(selectBatchState);

  const getStreamData = async () => {
    try {
      setModelLoading(true);
      let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_STREAM + "?", {
        name: (props?.stream).toString(),
        start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
        length: pagingItems?.pageSize,
        batch: batchState,
      });
      if (res?.status) {
        const result = res?.result?.items;
        setViewStreamData(
          () =>
            result?.map((itm: any, index: number) => {
              return {
                sno:
                  (pagingItems.currentPage - 1) * pagingItems.pageSize +
                  index +
                  1,
                key: index,
                id: itm?.id,
                fullName: itm?.fullName || itm?.name,
                stream: itm?.stream?.name,
                batch: itm?.batch?.name,
                contactDetail: itm?.contactDetail?.mobileNumber,
              };
            }) || []
        );
        setPagingItems((p) => {
          p.totalRecords = res?.result?.totalRecords;
          return p;
        });
      }
    } catch (error) {
    } finally {
      setModelLoading(false);
    }
  };
  useEffect(() => {
    props?.isModalOpen && getStreamData();
  }, [pagingItems, props?.isModalOpen]);

  const columns: ColumnsType<any> = [
    {
      title: "S No",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "fullName",
      ellipsis: true,
      key: "name",
      width: "30%",
      render: (fullName: any) => (
        <span style={{ cursor: "pointer" }}>{fullName}</span>
      ),
    },
    {
      title: "Stream",
      dataIndex: "stream",
      key: "stream",
      width: "30%",
      render: (currentClass: any) => <span>{currentClass}</span>,
    },
    {
      title: "Contact No.",
      dataIndex: "contactDetail",
      render: (contactDetail: any) => <span>{contactDetail}</span>,
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      render: (batch: any) => <span>{batch}</span>,
    },
  ];
  
  return (
    <>
      <Modal
        width={1100}
        footer={false}
        title="Stream"
        open={props?.isModalOpen}
        onCancel={() => props?.setOpenStreamModal((p: any) => !p)}
      >
        <Row>
          <Col span={24} style={{ paddingTop: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 330px)",
                overflow: "hidden",
              }}
            >
              <div style={{ overflowY: "auto", flex: "1" }}>
                <Table
                  size="small"
                  className="Tabel-style"
                  dataSource={viewStreamData}
                  loading={modelLoading}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: "calc(100vh - 400px)", x: 600 }}
                />
              </div>
              <Pagination
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                current={pagingItems.currentPage}
                pageSize={pagingItems.pageSize}
                showSizeChanger
                total={pagingItems.totalRecords}
                pageSizeOptions={pageSizeOption}
                onChange={(page, pageSize) => {
                  setPagingItems({
                    ...pagingItems,
                    currentPage: page,
                    pageSize,
                  });
                }}
                style={{ marginTop: "16px", textAlign: "right" }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default StreamViewModal;
