import React from "react";
import { Card, Col, Flex, Row, Tabs, Typography, type TabsProps } from "antd";
import AddAllSubjectMarks from "./MarksEntry/AddAllSubjectMarks";
import AddSingleSubjectMarks from "./MarksEntry/AddSingleSubjectMarks";
import { useNavigate } from "react-router-dom";
const GradeBook = () => {
  const navigate = useNavigate();
  const gap = 54;

  return (
    <>
      <Card className="roundedCornerSmall">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Typography.Title level={5} style={{ color: "#7b8ca8" }}>
              Marks Entry
            </Typography.Title>
            <Typography.Paragraph
              className="cursor"
              style={{ color: "#0d75ff" }}
              onClick={() => navigate("/marksentryallsubjects")}
            >
              Marks Entry All Subject
            </Typography.Paragraph>
            <Typography.Paragraph
              className="cursor"
              style={{ color: "#0d75ff" }}
              onClick={() => navigate("/marksentrysinglesubjects")}
            >
              Marks Entry Single Subject
            </Typography.Paragraph>
          </Col>

          {/* Result Section */}
          <Col span={8}>
            <Typography.Title level={5} style={{ color: "#7b8ca8" }}>
              Result
            </Typography.Title>
            <Typography.Paragraph
              className="cursor"
              style={{ color: "#0d75ff" }}
              onClick={() => navigate("/singlestudentresult")}
            >
              Single Student Result
            </Typography.Paragraph>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default GradeBook;
