import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Select,
  Table,
  Tag,
  Pagination,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import HTTPSCalls from "../../Services/HTTPCalls";
import { IApiResponse } from "../../Utilities/ApiUtility.axios";
import dayjs from "dayjs";
import { Tooltip } from "antd";
import { leaveStatusEnum } from "../../Constant/Enums";
import LeaveReqModal from "./LeaveReqModal";
import { getLeaveStatus } from "../../Utilities/Utils";

const LeaveRequestList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [leave, setLeave] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [tableRecord, setTableRecord] = useState<any>([]);
  // const [page, setPage] = React.useState(1);
  const [selectedStatus, setSelectedStatus] = useState<any | undefined>(
    undefined
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedLeaveType, setSelectedLeaveType] = useState<any | undefined>(
    undefined
  );
  const [leaveTypes, setLeaveTypes] = useState<any[]>([]);

  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const THeader = ({
    title,
    setShouldRefresh,
    changeListParams,
    selectedValue,
    setSelectedValue,
    option,
  }: any) => {
    return (
      <>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                colorTextPlaceholder: "#1B1A1A",
              },
            },
          }}
        >
          <div className="searchDivStyle" onClick={(e) => e.stopPropagation()}>
            <Select
              allowClear
              style={{ width: "100%" }}
              value={selectedValue}
              onSelect={(value) => {
                changeListParams(value);
                setSelectedValue(value);
                setPagingItems((prev) => ({ ...prev, currentPage: 1 }));
                setShouldRefresh((x: boolean) => !x);
              }}
              onClear={() => {
                changeListParams(undefined);
                setSelectedValue(undefined);
                setPagingItems((prev) => ({ ...prev, currentPage: 1 }));
                setShouldRefresh((x: boolean) => !x);
              }}
              popupMatchSelectWidth={false}
              variant="borderless"
              size="small"
              className="invSelectVendor"
              placeholder={title}
              options={option}
            />
          </div>
        </ConfigProvider>
      </>
    );
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      dataIndex: "sno",
      width: "5%",
      key: "sno",
      render: (text: any, record: any, index: any) => (
        <>
          <p>
            {(pagingItems.currentPage - 1) * pagingItems.pageSize + index + 1}
          </p>
        </>
      ),
    },
    // {
    //   title: "Student Id",
    //   dataIndex: "id",
    //   render: (text, record) => <span style={{ cursor: "pointer" }}>{""}</span>,
    //   width: "2%",
    //   ellipsis: true,
    // },
    {
      title: "Student",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text, record) => <span>{record?.student?.name}</span>,
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      render: (text, record) => <span>{record?.batch?.name}</span>,
      width: "7%",
    },
    {
      title: "Applied Date",
      dataIndex: "date",
      key: "date",
      render: (date, record) => (
        <span>{dayjs(record?.createdBy?.date).format("DD/MM/YYYY")}</span>
      ),
      width: "10%",
    },
    {
      title: (
        <THeader
          title="Leave Type"
          setShouldRefresh={setRefresh}
          selectedValue={selectedLeaveType}
          setSelectedValue={setSelectedLeaveType}
          option={leaveTypes}
          changeListParams={(value: any) => setSelectedLeaveType(value)}
        />
      ),
      dataIndex: "leaveType",
      key: "leaveType",
      render: (text: any) => <span style={{paddingLeft:"8px"}}>{text?.name}</span>,
      width: "10%",
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
      render: (from: any, record: any) => {
        const fromDate = dayjs.utc(record?.date?.from).startOf("day");
        const toDate = dayjs.utc(record?.date?.to).startOf("day");
        let daysDifference = toDate.diff(fromDate, "day");
        return <span>{daysDifference + 1}</span>;
      },
      width: "5%",
    },

    {
      title: "Date",
      dataIndex: "from",

      render: (from: any, record: any) => {
        const fromDate = dayjs.utc(record.date.from).format("DD/MM/YYYY");
        const toDate = dayjs.utc(record.date.to).format("DD/MM/YYYY");
        return fromDate !== toDate ? (
          <span>{`${fromDate} - ${toDate}`}</span>
        ) : (
          <span>{fromDate}</span>
        );
      },
      width: "15%",
    },

    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text: string, record: any) => {
        const wordLimit = 15;
        const reasontext = record?.reason;
        const isExceedingLimit = reasontext?.length > wordLimit;
        return isExceedingLimit ? (
          <Tooltip title={reasontext}>
            <span>{reasontext?.slice(0, wordLimit) + "..."}</span>
          </Tooltip>
        ) : (
          <span>{reasontext}</span>
        );
      },
      width: "10%",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      ellipsis: true,
      render: (text, record) => <span>{text || "-"}</span>,
      width: "10%",
    },
    {
      title: (
        <>
          <THeader
            title={"Status"}
            setShouldRefresh={setRefresh}
            selectedValue={selectedStatus}
            setSelectedValue={setSelectedStatus}
            option={[
              { label: getLeaveStatus(leaveStatusEnum.Approve), value: leaveStatusEnum.Approve },
              { label: getLeaveStatus(leaveStatusEnum.Pending), value: leaveStatusEnum.Pending },
              { label: getLeaveStatus(leaveStatusEnum.Cancel), value: leaveStatusEnum.Cancel },
              { label: getLeaveStatus(leaveStatusEnum.Reject), value: leaveStatusEnum.Reject },
            ]}
            changeListParams={(value: any) => setSelectedStatus(value)} // Update selectedStatus
          />
        </>
      ),
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const status: leaveStatusEnum = text;
        let color = "";
        switch (status) {
          case leaveStatusEnum.Approve:
            color = "#87d068";
            break;
          case leaveStatusEnum.Pending:
            color = "#4F93A9";
            break;
          case leaveStatusEnum.Cancel:
            color = "#FFA500";
            break;
          case leaveStatusEnum.Reject:
            color = "#f50";
            break;
          default:
            color = "gray";
        }

        return (
          <Tag style={{ width: "70%", textAlign: "center" }} color={color}>
            {getLeaveStatus(status)}
          </Tag>
        );
      },
      width: "10%",
    },
    {
      title: "Action",
      key: "action",
      width: "5%",
      render: (text, record) => (
        <>
          <Button
            size="small"
            onClick={() => {
              showModal();
              setTableRecord(record);
            }}
          >
            Action
          </Button>
        </>
      ),
    },
  ];

  const getLeaveTypes = async () => {
    try {
      const response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.GET_LEAVE_TYPES + "?",
        {}
      );
      if (response?.result) {
        setLeaveTypes(
          response.result.map((item: any) => ({
            label: item.leaveType,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to fetch leave types:", error);
    }
  };
  useEffect(() => {
    getLeaveTypes();
  }, []);

  const getLeaveRequest = async () => {
    setLoading(true);
    try {
      const params: any = {
        page: pagingItems.currentPage,
        pageSize: pagingItems.pageSize,
        searchTerm,
      };
      if (selectedStatus !== undefined) {
        params.status = selectedStatus;
      }
      if (selectedLeaveType !== undefined) {
        params.leaveType = selectedLeaveType;
      }
      await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.LEAVE_APPLICATION_ADMIN + "?",
        params
      ).then((res: IApiResponse) => {
        if (res?.result) {
          setLeave(res?.result?.items);
          setPagingItems((prev) => ({
            ...prev,
            totalRecords: res.result.totalRecords,
          }));
        }
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLeaveRequest();
  }, [refresh, pagingItems.currentPage, pagingItems.pageSize, searchTerm]);

  return (
    <>
      <Row justify={"end"}>
        <Col style={{ marginTop: "-55px", marginBottom: "30px" }}>
          <Input.Search
            placeholder="Search..."
            allowClear
            onSearch={(value) => {
              setSearchTerm(value);
              setPagingItems({ ...pagingItems, currentPage: 1 });
              setRefresh((x) => !x);
            }}
            style={{ width: "220px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
          className="masterList"
          rowKey={(record) => record.id}
            //   className="cit-table"
            size="small"
            columns={columns}
            dataSource={leave}
            loading={loading}
            pagination={false}
            scroll={{ y: 600 }}
          />
          <Pagination
            style={{ float: "right", marginTop: "10px" }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            current={pagingItems.currentPage}
            pageSize={pagingItems.pageSize}
            showSizeChanger
            total={pagingItems.totalRecords}
            pageSizeOptions={["15", "25", "50", "100", "200"]}
            onChange={(current, pageSize) => {
              setPagingItems({
                ...pagingItems,
                currentPage: current,
                pageSize: pageSize,
              });
            }}
          />
        </Col>
      </Row>
      {open && (
        <LeaveReqModal
          open={open}
          handleCancel={handleCancel}
          tableRecord={tableRecord}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default LeaveRequestList;
