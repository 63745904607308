import {
    BellOutlined,
    CloseOutlined,
    PaperClipOutlined,
    UploadOutlined,
  } from "@ant-design/icons";
  import {
    Button,
    Col,
    Drawer,
    Form,
    Image,
    Input,
    message,
    Modal,
    Row,
    Space,
    Upload,
  } from "antd";
  import { useForm } from "antd/es/form/Form";
  import TextArea from "antd/es/input/TextArea";
  import React, { useEffect, useState } from "react";
  import { MasterTypes } from "../../../Constant/Enums";
  import { ApiCalls } from "../../../ApisCaller/ApiCall";
  import { NoticeMaster } from "../../../ApiUrls/URLS";
  import HTTPSCalls from "../../../Services/HTTPCalls";
  import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
  import { FilesViewer } from "../../../Common/FileViewer";
  interface IDrawerPorps {
    open: boolean;
    setOpen: any;
    setTrigger: any;
    recordData: any;
    trigger: any;
  }
  const AddEditFacultyType = ({
    open,
    setOpen,
    setTrigger,
    trigger,
    recordData,
  }: IDrawerPorps) => {
    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const onsubmit = async () => {
      form.validateFields().then(async (values: any) => {
        setLoading(true);
        let noticeDetial = {
          masterType: MasterTypes.FacultyType,
          id: recordData?.id,
          name: values?.name,
        };
        HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.POST_MASTER,
          {},
          { ...noticeDetial }
        ).then((res: IApiResponse) => {
          if (res?.status) {
            setOpen(false);
            form.resetFields();
            setOpen(false);
            message.success(
              recordData?.id
                ? "Faculty Type updated successfully"
                : "Faculty Type added successfully"
            );
            setTrigger((prev: boolean) => !prev);
          } else {
            message.warning(res?.message);
          }
          setLoading(false);
        });
      });
    };
    useEffect(() => {
      if (open) {
        console.log(recordData?.id,"recorddataid");
        
        if (recordData?.id) {
          form.setFieldsValue({
            name: recordData?.name,
          });
        } else if (open) {
          form.resetFields();
        }
      }
    }, [open, recordData, form]);

    return (
      <>
        <Drawer
          open={open}
          onClose={() => {
            setOpen((prev: boolean) => !prev);
            form.resetFields();
          }}
          title={
            <>
              {recordData?.id ? "Edit Faculty Type" : "Add Faculty Type"} 
            </>
          }
          footer={
            <Row gutter={12} justify={"end"}>
              <Col>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => onsubmit()}
                  loading={loading}
                >
                  {recordData?.id ? "Update" : "Save"} 
                </Button>
              </Col>
            </Row>
          }
        >
          <Form form={form} >
            <Row className="p-4">
              <Col span={24}>
                <Form.Item
                  name={"name"}
                  label="Name"
                  rules={[{ required: true, message: "Please input name" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
  
      </>
    );
  };
  
  export default AddEditFacultyType;
  