import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { leaveStatusEnum, leaveStatusObject } from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";

const LeaveReqModal = (props: any) => {
  const { open, handleCancel, tableRecord, setRefresh } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);

  useEffect(() => {
    if (tableRecord) {
      form.setFieldsValue({
        status: tableRecord?.status,
        remark: tableRecord?.remark || "",
      });
      setFileList(tableRecord?.attachments || []);
    }
  }, [tableRecord, form]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formValues = form.getFieldsValue();
      if (formValues.status !== leaveStatusEnum.Pending) {
        const payload = {
          id: tableRecord?.id,
          status: formValues.status,
          remark: formValues.remark,
        };

        let res = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.LEAVE_ACTION,
          {},
          payload
        );
        if (res?.result) {
          setRefresh((prev: boolean) => !prev);
          message.success("Leave request submitted successfully.");
        }
        form.resetFields();
        handleCancel();
      } else {
        message.warning("Please select a valid status.");
      }
    } catch (error: any) {
      message.error(
        error.message || "An error occurred while submitting the leave request."
      );
    } finally {
      setLoading(false);
    }
  };

  const getAttachments = (file: any) => {
    const previewUrl = file.path;
    const win = window.open(previewUrl, "_blank");
    if (win) {
      win.focus();
    }
  };
  const leaveStatusArray = Object.keys(leaveStatusObject).map((key) => ({
    value: leaveStatusObject[key as keyof typeof leaveStatusObject],
    label: key,
  }));

  return (
    <>
      <Modal
        okText={"Save"}
        title="Leave Request"
        width={800}
        open={open}
        onCancel={handleCancel}
        onOk={() =>
          form
            .validateFields()
            .then(handleSubmit)
            .catch((err) => {
              console.log(err);
            })
        }
        okButtonProps={{
          disabled: props?.tableRecord?.status !== leaveStatusEnum.Pending,
        }}
        confirmLoading={loading}
        // onCancel={handleCancel}
      >
        <div
          style={{
            height: "100%",
            border: "1.5px solid lightgray",
            borderRadius: "5px",
            padding: "10px",
            color: "black",
          }}
        >
          <Form requiredMark={false} form={form} style={{ lineHeight: 2.5 }}>
            <Row>
              <Col span={6}>
                <p style={{ color: "black", fontWeight: "600" }}>Name</p>
              </Col>
              <Col span={6}>
                <p>{tableRecord?.createdBy?.userName}</p>
              </Col>
              <Col span={6} style={{ color: "black", fontWeight: "600" }}>
                Type
              </Col>
              <Col span={6}>
                <p>{tableRecord?.leaveType?.name}</p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <p style={{ color: "black", fontWeight: "600" }}>
                  Applied date
                </p>
              </Col>
              <Col span={6}>
                <p>
                  {dayjs.utc(tableRecord?.createdBy?.date).format("DD/MM/YYYY")}
                </p>
              </Col>
              <Col span={6} style={{ color: "black", fontWeight: "600" }}>
                Days
              </Col>
              <Col span={6}>
                <p>
                  {(() => {
                    const fromDate = dayjs
                      .utc(tableRecord?.date?.from)
                      .startOf("day");
                    const toDate = dayjs
                      .utc(tableRecord?.date?.to)
                      .startOf("day");
                    let daysDifference = toDate.diff(fromDate, "day");
                    // if (daysDifference === 1) {
                    //   daysDifference = 1;
                    // }
                    return `${daysDifference + 1} days`;
                  })()}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ color: "black", fontWeight: "600" }}>
                <p>From</p>
              </Col>
              <Col span={6}>
                <p>{dayjs.utc(tableRecord?.date?.from).format("DD/MM/YYYY")}</p>
              </Col>
              <Col span={6} style={{ color: "black", fontWeight: "600" }}>
                To
              </Col>
              <Col span={6}>
                <p>{dayjs.utc(tableRecord?.date?.to).format("DD/MM/YYYY")}</p>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ color: "black", fontWeight: "600" }}>
                <p>Reason</p>
              </Col>
              <Col span={18}>
                <p>{tableRecord?.reason}</p>
              </Col>
            </Row>
            <Divider
              orientation="left"
              orientationMargin="0"
              style={{ margin: "2px", fontSize: "14px" }}
            >
              <span className="fw600"> Attachments</span>
            </Divider>
            <Row>
              <Col span={24} md={24}>
                {fileList.length > 0 ? (
                  fileList.map((file: any) => (
                    <Row gutter={[16, 16]} align="middle" key={file.id}>
                      <Col span={12}>
                        <Tooltip title={file.name}>
                          <span>
                            {file.name.length > 50
                              ? file.name.slice(0, 50) + "..."
                              : file.name}
                          </span>
                        </Tooltip>
                      </Col>
                      <Col span={12}>
                        <Button
                          type="link"
                          onClick={() => getAttachments(file)}
                        >
                          Preview
                        </Button>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <p>No attachments available for preview.</p>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col span={19}></Col>
              <Col span={5}></Col>
              <Divider
                orientationMargin="0"
                orientation="left"
                style={{ marginTop: "2px", fontSize: "14px" }}
              >
                <span className="fw600">Final Approvals</span>
              </Divider>
            </Row>

            <Row>
              <Col span={24} style={{ color: "black", fontWeight: "600" }}>
                <Form.Item
                  label="Status"
                  rules={[
                    { required: true, message: "Please select a status!" },
                  ]}
                  name={"status"}
                >
                  <Select
                    placeholder="Select Leave Status"
                    style={{ width: 200 }}
                    options={leaveStatusArray}
                    disabled={
                      props?.tableRecord?.status !== leaveStatusEnum.Pending
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={24} style={{ color: "black", fontWeight: "600" }}>
                <Form.Item
                  label="Remark"
                  rules={[
                    { required: true, message: "Please add remark!" },
                    {
                      pattern: /^(?!\s*$).+/,
                      message: "Remark cannot be just empty spaces!",
                    },
                  ]}
                  name={"remark"}
                >
                  <Input
                    placeholder="Add Remarks"
                    disabled={
                      props?.tableRecord?.status !== leaveStatusEnum.Pending
                    }
                  />
                </Form.Item>
              </Col>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Note: This remark will be visible to requesting employee and the
                management
              </p>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default LeaveReqModal;
