import * as React from "react";
import { useState } from "react";
import {
  Row,
  Typography,
  Col,
  Button,
  Table,
  message,
  DatePicker,
  Select,
} from "antd";
import {
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import type { TableProps } from "antd";
import AddAssessments from "./AddAssessments";
import HTTPSCalls from "../../../../Services/HTTPCalls";
// import { adminContext } from "../../../../Common/PageRoute";
import { useQuery } from "@tanstack/react-query";
import { pageSizeOption } from "../../../LMS/SubjectAlllocation/SubjectAllocation";
import { queryClient } from "../../../../App";
import ConfirmationModel from "../../../../Common/ConfirmationModel";
import {
  ActiveInactiveOptions,
  RecordStatusType,
} from "../../../../Constant/Enums";

//#region And table
interface DataType {
  _id: string;
  // id:string;
  key: string;
  name: string;
}

// const items: MenuProps["items"] = [
//   {
//     key: "1",
//     label: <Typography.Link>Edit</Typography.Link>,
//   },
//   {parentLoading}parentLoading}
//     key: "2",
//     label: <Typography.Link>Delete</Typography.Link>,
//   },
// ];

//#endregion

const DisplayAssements: React.FC = () => {
  // const { openNotification } = React.useContext(adminContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectAction, setSelectAction] = useState<String>("all");
  const [assessmentsId, setAssessmentsId] = useState<any>("");
  // const [currectPath, setCurrectPath] = useState<any>();
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [inactiveConfirmation, setInactiveConfirmation] =
    useState<boolean>(false);
  // const [previewOpen, setPreviewOpen] = React.useState(false);
  // const [previewImage, setPreviewImage] = React.useState("");
  const [start, setStart] = useState<any>(0);
  const [length, setLength] = useState<any>(15);
  const [recordData, setRecordData] = useState<any>({});
  // const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    // form.resetFields();
    setAssessmentsId(null);
    setRecordData(null);
  };

  React.useEffect(() => {
    setStart((pagingItems?.currentPage - 1) * pagingItems?.pageSize);
    setLength(pagingItems?.pageSize);
  }, [pagingItems]);

  // const inviditualAssessment = (item: any) => {
  //   // handlePreview(item.attachments.fileName)
  //   showDrawer();
  //   setAssessmentsId(item.id);
  //   form.setFieldsValue({
  //     assessmentName: item.assessmentName,
  //     category: {
  //       label: item.category.name,
  //       value: item.category.id,
  //     },
  //     marksIn: item.marksIn,
  //     totalMarks: item.totalMarks,
  //     passingMarks: item.passingMarks,
  //     countScore: item.countScore,
  //     gradingScale: {
  //       label: item.gradingScale,
  //       value: item.gradingScale,
  //     },
  //     appliedTo: item.appliedTo,
  //     onlineSubmission: item.onlineSubmission,
  //     description: item.description,
  //     attachments: item?.attachments
  //   });
  //   // item?.attachments?.length >0 &&
  //   // setPreviewImage(item?.attachments[0]?.fileName?.path)
  //   console.log('item',item?.attachments[0].fileName)
  // };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Assessment Name",
      dataIndex: "assessmentName",
      key: "assessmentName",
      width: "14%",
    },
    {
      title: "Category Name",
      dataIndex: "category",
      key: "category",
      render: (text, record: any) => record.Category?.Name || "N/A",
    },

    {
      title: "Count in Overall",
      dataIndex: "isCountOverallScore",
      key: "isCountOverallScore",
      render: (text, record: any) =>
        record?.IsCountOverallScore ? "Yes" : "No",
    },

    {
      title: "Course",
      dataIndex: "course",
      key: "course",
      render: (text, record: any) => record.Course?.Name || "N/A",
    },
    {
      title: "Semester",
      dataIndex: "semester",
      key: "semester",
      render: (text, record: any) => record.Semester?.Name || "N/A",
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      render: (text, record: any) => record.Section?.Name || "N/A",
    },
    // {
    //   title: "Edit",
    //   key: "edit",
    //   align: "center",
    //   render: (text: any, record: any) => (
    //     <FormOutlined
    //       className="cursor"
    //       onClick={() => {
    //         setOpen(true);
    //         setAssessmentsId(record?._id);
    //         setRecordData(record);
    //       }}
    //     />
    //   ),
    // },
    {
      title: "Action",
      width: "5%",
      key: "edit",
      align: "center",
      render: (_: any, record: any) => (
        <>
          <Row>
            <Col md={12} span={12} sm={24}>
              <FormOutlined
                className="cursor editIconColour"
                onClick={() => {
                  setOpen(true);
                  setAssessmentsId(record?._id);
                  setRecordData(record);
                }}
              />
            </Col>
            <Col md={12} span={12} sm={24}>
              {record.RecordStatus === RecordStatusType.Deleted ? (
                <UndoOutlined
                  className="cursor"
                  onClick={() => {
                    // updateStatusMution.mutate({id:record?.id, restore:"restore"},{
                    //   onSuccess:()=>refetch()
                    // })
                    setAssessmentsId(record?._id);
                    setInactiveConfirmation(true);
                    setConfirmation(true);
                  }}
                />
              ) : (
                <DeleteOutlined
                  className="cursor deleteIconColour"
                  onClick={() => {
                    setInactiveConfirmation(false);
                    setAssessmentsId(record?._id);
                    setConfirmation(true);
                  }}
                />
              )}
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const {
    data: assessment,
    isLoading: assessmentLoading,
    // refetch,
  } = useQuery({
    queryKey: ["Assessments", start, length, selectAction],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_ASSESSMENTS_DATA + "?",
          {
            // start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
            // length: pagingItems?.pageSize,
            start,
            length,
            status: selectAction,
          }
        );
        if (res?.result?.items?.length > 0) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          return res?.result?.items?.map((item: any) => ({
            ...item,
            assessmentName: item?.AssessmentName,
            category: {
              id: item?.Category?.value,
              name: item?.Category?.label,
            },
            // isCountOverallScore:item?.IsCountOverallScore? "Yes" :"No",
            passMarks: item?.passMarks,
            course: {
              id: item?.course?.value,
              name: item?.course?.label,
            },
            semester: {
              id: item?.semester?.value,
              name: item?.semester?.label,
            },
            section: {
              id: item?.section?.value,
              name: item?.section?.label,
            },
            key: item?.id,
          }));
        } else {
          console.log("error");
          return [];
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });
  // console.log(assessment,"assessment,=")
  // const getBase64 = (file: RcFile): Promise<string> =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.onerror = (error) => reject(error);
  //   });

  // const handelFile = async (file: any, index: number) => {
  //   let currentFileBase64 = await getBase64(file as RcFile);
  //   setCurrectPath(currentFileBase64);
  // };

  // type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
  // const handlePreview = async (file: UploadFile) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj as FileType);
  //   }

  //   setPreviewImage(file.url || (file.preview as string));
  //   setPreviewOpen(true);
  // };

  const UpdateRecordStatus = async (action: string = "delete") => {
    try {
      setLoading(true);
      // setPageLoader(true);
      if (assessmentsId) {
        await HTTPSCalls.POST(
          `${HTTPSCalls.ENDPOINTS.UPDATE_ASSESSMENT_STATUS}?`,
          {
            id: assessmentsId,
            status: action,
          },
          {}
        );
        queryClient.invalidateQueries({ queryKey: ["Assessments"] });
      }
    } catch (error) {
      message.error("Error occurred while updating record status.");
    } finally {
      setLoading(false);
      // setPageLoader(false);
    }
  };

  return (
    <>
      <div>
        <Row justify={"space-between"} style={{ alignItems: "baseline" }}>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 500 }}>
              Assessments
            </Typography.Title>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {/* <RangePicker
              style={{ marginRight: "10px" }}
              onChange={(dates: any, dateStrings: [string, string]) => {
                console.log(dates,"dateRange", dateStrings);
                // [dayjs.utc(dates[0]), dayjs.utc(dates[1])];
              }}
            /> */}
            <Select
              style={{ width: "24%", textAlign: "left", marginRight: "10px" }}
              onSelect={(val) => {
                setSelectAction(val);
              }}
              placeholder="Select Action"
              allowClear
              defaultValue={"all"}
              options={ActiveInactiveOptions}
            ></Select>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ fontWeight: 500 }}
              onClick={showDrawer}
            >
              Add
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={assessment}
          style={{ marginTop: 14 }}
          loading={assessmentLoading}
          rowKey={(record) => record?._id}
          // loading={{
          //   spinning: assessmentLoading,
          //   indicator: <Spin indicator={<LoadingOutlined />} />,
          // }}
          pagination={{
            selectPrefixCls: "custom-table-select",
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: pagingItems.currentPage,
            pageSize: pagingItems.pageSize,
            showSizeChanger: true,
            total: pagingItems.totalRecords,
            pageSizeOptions: pageSizeOption,
          }}
          onChange={(paging, filter, sort: any) => {
            paging &&
              setPagingItems({
                ...pagingItems,
                currentPage: paging?.current ?? 1,
                pageSize: paging?.pageSize ?? 15,
              });
          }}
        />
        {open && (
          <AddAssessments
            onClose={onClose}
            assessmentsId={assessmentsId}
            recordData={recordData}
            // form={form}
            // handelFile={handelFile}
            // handlePreview={handlePreview}
            // previewImage={previewImage}
            // previewOpen={previewOpen}
            // setPreviewOpen={setPreviewOpen}
            // setPreviewImage={setPreviewImage}
            // currectPath={currectPath}
            open={open}
          />
        )}
        {confirmation && (
          <ConfirmationModel
            open={confirmation}
            text={`Are you sure you want to ${
              inactiveConfirmation ? "restore" : "delete"
            } assessment ?`}
            onOk={async () => {
              let action = inactiveConfirmation ? "restore" : "delete";
              await UpdateRecordStatus(action);
              setConfirmation(false);
              setAssessmentsId(null);
            }}
            onCancel={() => {
              setConfirmation(false);
              setAssessmentsId(null);
            }}
            parentLoading={loading}
          />
        )}
      </div>
    </>
  );
};

export default DisplayAssements;
