import * as React from "react";
import { useRef, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  UploadProps,
} from "antd";
import dayjs from "dayjs";
import HTTPSCalls from "../../Services/HTTPCalls";
import SunEditor from "suneditor-react";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/es/upload";
import { AttachmentsCategory, MasterTypes } from "../../Constant/Enums";
import { IApiResponse } from "../../Utilities/ApiUtility.axios";
import { MailTemplatesMaster } from "../../ApiUrls/URLS";
import { ApiCalls } from "../../ApisCaller/ApiCall";
import { useEnquiryCommunicationGetById } from "../../Utilities/tanStackQueries";

const MailModel: React.FC<{
  open: boolean;
  id: string;
  type: number;
  apiData?: any;
  onCancel: (rec: boolean, isUploadAtt: boolean) => void;
}> = (props) => {
  const [form] = Form.useForm();
  // const [textValue, setTextValue] = React.useState<string>("");
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [templateId, setTemplateId] = React.useState<string>("");
  const [templateData, setTemplateData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [body, setBody] = React.useState<string>("");
  const [previewId, setPreviewId] = React.useState<string>("");
  const [fileList, setFileList] = React.useState<[]>([]);

  const editor = useRef();
  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const [attachmentList, setAttachmentList] = React.useState<any[]>([
    {
      fileType: null,
      fileName: {
        id: "",
        name: "",
        contentType: "",
        length: 0,
        path: "",
      },
      description: null,
    },
  ]);

  const { data: previewMailDetail, } =
    useEnquiryCommunicationGetById({ Id: props?.id, Type: 3 });

  const getAttachments = (file: any) => {
    const previewUrl = file.path;
    const win = window.open(previewUrl, "_blank");
    if (win) {
      win.focus();
    }
  };

  useEffect(() => {
    if (props?.id) {
      previewMailDetail?.map((el: any) => {
        setPreviewId(props?.id);
        form.setFieldsValue({
          toMail: el?.toMail,
          subject: el?.subject,
          body: el?.body,
          schedule: el?.schedule ? dayjs(el.schedule) : null,
          attachment: el?.attachments,
        });
        setBody(el?.body);
        setAttachmentList(el?.attachments);

        const attachmentFiles = el?.attachments?.map((file: any) => ({
          id: file?.fileName.id,
          name: file?.fileName.name,
          contentType: "",
          length: 0,
          path: file?.fileName.path,
        }));
        setFileList(attachmentFiles || []);
      });
    }
  }, [props.id, previewMailDetail]);

  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      setTemplateData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.MailTemplates
        )?.[0]?.items
      );
      //console.log(transformedItems, "test");
    } else {
      message.error(res?.message);
    }
  };
  //console.log(counselorData, "testcou");
  const getTemplateById = async (templateId: string) => {
    setLoading(true);
    const api = new ApiCalls(
      MailTemplatesMaster.endPoints,
      MailTemplatesMaster.prefix
    );
    await api
      .GET(MailTemplatesMaster.endPoints.getById + "/" + templateId + "?", {
        masterType: MasterTypes.MailTemplates,
      })

      .then((data: IApiResponse) => {
        if (data?.result) {
          let res = data?.result;
          form.setFieldsValue({
            name: res?.name,
            body: res?.htmlContent,
            subject:res?.subject,
          });
          setBody(res?.htmlContent);
          // console.log(res, "this is res");
          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    getMaster();
    getTemplateById(templateId);
  }, [templateId]);
  const handelFile = async (file: any, fileList: any) => {
    fileList?.map(async (itm: any, index: any) => {
      let prevAtt = [...attachmentList];
      let currentFileBase64 = await getBase64(itm as RcFile);

      let fileInfo = {
        id: "",
        name: itm?.name,
        contentType: itm?.type,
        length: itm?.size,
        path: currentFileBase64,
      };

      prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

      setAttachmentList(prevAtt);
    });
  };
  const customRender = (
    originNode: React.ReactElement<any>,
    file: UploadFile,
    fileList: Array<UploadFile>
  ) => {
    return (
      <>
        {/* <div className="ant-upload-list-item">
                  {
                    file.name
                  }

                </div> */}
        {originNode}
        {/* <h1>This is heading</h1> */}
      </>
    );
  };
  const uploadProps: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: {
      previewIcon: true,
      showDownloadIcon: true,
      downloadIcon: "Download",
      showRemoveIcon: true,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
    beforeUpload(file, FileList) {
      handelFile(file, FileList);
      return false;
    },
    itemRender: customRender,
  };
  //   <!DOCTYPE html>
  //   <html lang="en">
  //   <head>
  //       <meta charset="UTF-8">
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0">
  //       <title>Mail</title>
  //   </head>
  //   <body>
  //       <div style="width: 60%; font-size: 16px; font-family: Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri, Helvetica, sans-serif;">
  //           <p><span style="font-size: 16px">Hi,  ${props?.editRecord?.fullName}</span></p>
  //           <p><span style="font-size: 16px">We are delighted to inform you that your enrollment in the B.Sc. Computer Science program at LSBU has been successfully processed. We believe that this course will equip you with valuable knowledge and skills essential for excelling in your chosen field.</span></p>
  //           <p><span style="font-size: 16px">I've attached to this email your enrollment form and invoice for the enrollment fee. If you have any questions or need any more help, please do not hesitate to reach out to us.</span></p><p><span style="font-size: 16px">Once again, congratulations on your successful enrollment!</span></p><p><span style="font-size: 16px">--</span>
  //           <div style="line-height:0mm; font-size: 50px; font-family: verdana, sans-serif;">
  //           </div>
  //           <div style="line-height:0mm; margin-top: 38px; font-family: 'trebuchet ms', sans-serif; font-size: 13px;">
  //               <h4 style="color:#666666;">CAPSITECH INSTITUTE OF TECHNOLOGY</h4>
  //               <h4 style="color:#a80000; font-size: 10.6667px; font-family: Arial, sans-serif;">H-299 | 3rd Phase, RIICO Industrial Area | Boranada | Jodhpur-342012</h4>
  //           </div>
  //           <div style="line-height:1mm; margin-top: 25px;">
  //               <p style="color:#666666; font-size: 10px; font-family: Arial, Helvetica, sans-serif;">Phone : +91 0291 294 2244 | Mob. +91 (0) 8302303370</p>
  //               <p style="color:#666666; font-size: 10px; font-family: Arial, Helvetica, sans-serif;">Email : vineeta.tookey@capsitech.com | Web URL : <a href="https://www.capsitech.com/" style="color:rgb(0, 68, 255)">https://www.capsitech.com/</a></p>
  //           </div>
  //           <div>
  //               <img src="https://cit.capsitech.com/images/Citlogo.svg" />
  //           </div>
  //       </div>
  //   </body>
  // </html>
  //  `;
  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        // debugger;
        setButtonLoading(true);
        let formValue = {
          ...values,
          enquiryId: props?.apiData?.id,
          attachmentsCategory: AttachmentsCategory.Email,
          type: props?.type,
          attachments: values?.attachments
            ? await Promise.all(
                values?.attachments?.fileList?.map(
                  async (itm: any, index: number) => {
                    let currentFileBase64 = await getBase64(
                      itm?.originFileObj as RcFile
                    );
                    let fileInfo = {
                      fileType: null,
                      fileName: {
                        id: "",
                        name: itm?.name,
                        contentType: itm?.type,
                        length: itm?.size,
                        path: currentFileBase64,
                      },
                      description: values?.meetingAddress,
                      category: AttachmentsCategory.Email,
                    };
                    return fileInfo;
                  }
                )
              )
            : [],
        };
        console.log(formValue, "=values");

        let response = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.EnquiryCommunication,
          {},
          formValue
        );
        if (response.status) {
          form.resetFields();
          message.success("Mail Submitted");
          setButtonLoading(false);
          props.onCancel(true, !!formValue?.attachments);
        } else {
          message.error(response.message);
          setButtonLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setButtonLoading(false);
      });
  };
  return (
    <>
      <Modal
        title={previewId ? "Preview mail" : "Create mail"}
        open={props.open}
        maskClosable={false}
        onCancel={() => {
          props.onCancel(false, false);
        }}
        width={1080}
        style={{ top: 20 }}
        footer={
          props.id ? null : (
            <Space
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: 0,
              }}
            >
              <Button
                onClick={() => {
                  props?.onCancel(false, false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={onSubmit} type="primary" loading={buttonLoading}>
                Send
              </Button>
            </Space>
          )
        }
      >
        <Divider className="marginDivider" />

        <Form
          form={form}
          layout="vertical"
          requiredMark={true}
          initialValues={{ toMail: props?.apiData?.contactDetail?.email }}
          disabled={!!previewId}
        >
          <Row gutter={8}>
            {/* <Col lg={12}>
              <Form.Item
                name="formMail"
                label="Form"
                className="marginbtm"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input valid email!",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col> */}
            <Col lg={18}>
              <Form.Item
                name="toMail"
                label="To"
                className="marginbtm"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input valid email!",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item name="schedule" label="Schedule" className="marginbtm">
                <DatePicker
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime
                  // allowClear={false}
                  // format={"DD/MM/YYYY"}
                  // defaultValue={dayjs()}
                  // disabledDate={(current) =>
                  //   current && current > dayjs().endOf("day")
                  // }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col lg={18}>
              <Form.Item name="subject" label="Subject" className="marginbtm"
              rules={[
                {
                  required: true,
                  message: "Please Enter Subject!",
                },
              ]}>
                <Input autoComplete="off" style={{ width: "100%" }} placeholder="" />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item
                name="selectTemplate"
                label="Select Template"
                hidden={!!previewId}
              >
                {/* <Form.Item name="selectTemplate" label="Select Template"> */}
                <Select
                  onChange={(e) => {
                    setTemplateId(e.value);
                  }}
                  placeholder="Choose Template"
                  optionFilterProp="children"
                  labelInValue
                  allowClear
                  popupMatchSelectWidth={true}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={templateData}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="body"
            label=""
            className="marginbtm"
            rules={[{ required: true, message: "Please add content here" }]}
          >
            <SunEditor
              setContents={body || ""}
              getSunEditorInstance={getSunEditorInstance}
              setOptions={{
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize"],
                  ["bold", "underline", "italic", "strike"],
                  ["fontColor", "hiliteColor"],
                  ["align", "list", "lineHeight"],
                  ["outdent", "indent"],
                  // ["table", "horizontalRule", "link", "image", "video"],
                  ["print"],
                  ["removeFormat"],
                ],
                defaultTag: "div",
                font: [
                  "Arial",
                  "Comic Sans MS",
                  "Courier New",
                  "Impact",
                  "Georgia",
                  "Tahoma",
                  "Trebuchet MS",
                  "Verdana",
                ],
                fontSize: [
                  8, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 24, 36,
                ],
                defaultStyle: "font-family: Arial; font-size: 14px;", // Set default style
                showPathLabel: false,
              }}
              // setOptions={{
              //   buttonList: [
              //     ["undo", "redo"],
              //     ["font", "fontSize"],
              //     ["bold", "underline", "italic", "strike"],
              //     ["fontColor", "hiliteColor"],
              //     ["align", "list", "lineHeight"],
              //     ["outdent", "indent"],
              //     /*  ["table", "horizontalRule", "link", "image", "video"],*/
              //     ["print"],
              //     ["removeFormat"],
              //   ],
              //   defaultTag: "div",
              //   defaultStyle: "font-family:Arial,font-size:14px",
              //   showPathLabel: false,
              // }}
              height="200px"
              readOnly={!!previewId}
            />
            {/* <Input.TextArea
              showCount
              rows={3}
              placeholder="Add Note Here....."
              maxLength={100}
              onChange={(e: any) => {
                setTextValue(e.target.value);
                //console.log(textValue, "textValue");
              }}
            /> */}
          </Form.Item>
          {!previewId ? (
            <Form.Item
              name={"attachments"}
              label="Add Attachments"
              // Optional validation rules, uncomment if needed
              // rules={[
              //   {
              //     required: true,
              //     message: "This field is required",
              //   },
              // ]}
            >
              <Dragger
                {...uploadProps}
                onPreview={getAttachments}
                disabled={!!previewId} // Disable if previewId is present
              >
                <>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </>
              </Dragger>
            </Form.Item>
          ) : null}
        </Form>

        {/* <Row gutter={8}>
            <Col lg={12}>
              <Form.Item
                name="startTime"
                className="marginbtm"
                label="Start Time"
              >
                <TimePicker
                  style={{ width: "100%" }}
                  format={"h:mm A"}
                  //defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                  placeholder="Start Time"
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item name="endTime" className="marginbtm" label="End Time">
                <TimePicker
                  style={{ width: "100%" }}
                  format={"h:mm A"}
                  //defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                  placeholder="End Time"
                />
              </Form.Item>
            </Col>
          </Row> */}

        {previewId && (
          <div style={{ marginTop: "16px" }}>
            <h3>Attachments</h3>
            {fileList.length > 0 ? (
              <div>
                {fileList.map((file: any) => (
                  <div key={file.id}>
                    <Row gutter={[16, 16]}>
                      {/* <Row gutter={[16, 16]} align="middle"> */}
                      <Col span={6}>
                        <Tooltip title={file.name}>
                          <span>
                            {file.name.length > 5
                              ? file.name.slice(0, 25) + "..."
                              : file.Name}
                          </span>
                        </Tooltip>
                      </Col>
                      <Col span={10}>
                        <Button
                          type="link"
                          onClick={() => getAttachments(file)}
                        >
                          Preview
                        </Button>
                      </Col>
                      {/* </Row> */}
                    </Row>
                  </div>
                ))}
              </div>
            ) : (
              <p>No attachments available for preview.</p>
            )}
          </div>
        )}
      </Modal>
    </>
  )
};

export default MailModel;
