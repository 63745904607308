import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "antd";
import SubjectAllocationAdd from "./SubjectAllocationAdd";
import { ColumnsType } from "antd/es/table";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { pageSizeOption } from "../../../component/Users/UserList";
import Search from "antd/es/input/Search";

const SubjectAllocationList: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [subjectAllocation, setSubjectAllocation] = useState<any>("");
  const [sendRecord, setSendRecord] = useState<any>({});
  const [trigger, setTrigger] = useState<boolean>(false);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState<string>("");
  const [listParams, setListParams] = useState({
    // start: 0,
    // length: 15,
    search: "",
    sortCol: "",
    sortDir: "",
  });

  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      dataIndex: "sno",
      width: "0.6%",
      key: "sno",
      render: (text: any, record: any, index: any) => (
        <p>
          {(pagingItems.currentPage - 1) * pagingItems.pageSize + index + 1}
        </p>
      ),
    },
    {
      title: "Departments",
      dataIndex: "department",
      key: "Department.Name",
      width: "2%",
      sorter: true,
      render: (department: any, record: any) => <>{department?.name}</>,
    },
    {
      title: "Teachers",
      dataIndex: "teacher",
      key: "Teacher.Name",
      width: "2%",
      sorter: true,
      render: (teacher: any, record: any) => <>{teacher?.name}</>,
    },
    {
      title: "Course",
      dataIndex: "course",
      key: "Course.Name",
      width: "2%",
      sorter: true,
      render: (course: any, record: any) => <>{course?.name}</>,
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "Batch.Name",
      width: "2%",
      sorter: true,
      render: (text: any, record: any) => <>{text?.name}</>,
    },
    {
      title: "Semesters",
      dataIndex: "semester",
      key: "semester",
      width: "2%",
      render: (semester: any, record: any) => <>{semester?.name}</>,
    },
    {
      title: "Sections",
      dataIndex: "section",
      key: "section",
      width: "2%",
      render: (section: any, record: any) => <>{section?.name}</>,
    },
    {
      title: "Subjects",
      dataIndex: "subject",
      key: "subject",
      width: "2%",
      ellipsis: true,
      render: (subjects: any, record: any) => <>{subjects?.name}</>,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "1%",
      align: "end",
      render: (_: any, record: any) => (
        <>
          <FormOutlined
            className="cursor editIconColour"
            onClick={() => {
              setSendRecord(record);
              setOpenDrawer(true);
            }}
          />
        </>
      ),
    },
  ];

  const changeListParams = (key: string, value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const getSubAllocationList = async () => {
    setLoading(true);
    try {
      const response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.SUBJECT_ALLOCATION_GETLIST + "?",
        {
          start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
          length: pagingItems?.pageSize,
          search: searchVal,
          sortCol: listParams?.sortCol,
          sortDir: listParams?.sortDir,
        }
      );
      if (response?.result) {
        setSubjectAllocation(response?.result?.items || []);
        setPagingItems((prev) => ({
          ...prev,
          totalRecords: response?.result?.totalRecords || 0,
        }));
      }
    } catch (error) {
      console.error("Failed to fetch leave types:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubAllocationList();
  }, [trigger, pagingItems.currentPage, pagingItems.pageSize, searchVal, listParams.sortCol, listParams.sortDir]);

  return (
    <>
      <Card>
        <Row>
          <Col span={21}>
            <Button
              type="primary"
              style={{ fontWeight: 500 }}
              onClick={() => {
                setSendRecord({});
                setOpenDrawer(true);
              }}
            >
              <PlusOutlined /> Subject Allocation
            </Button>
          </Col>
          <Col span={3}>
            <Search
              placeholder="Search..."
              onSearch={(val: string) => {
                setSearchVal(val);
              }}
              allowClear
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col span={24}>
            <Table
              className="masterList"
              loading={loading}
              columns={columns}
              dataSource={subjectAllocation}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                selectPrefixCls: "custom-table-select",
                current: pagingItems.currentPage,
                pageSize: pagingItems.pageSize,
                showSizeChanger: true,
                total: pagingItems.totalRecords,
                pageSizeOptions: pageSizeOption,
              }}
              // scroll={{ y: "65vh" }}
              scroll={{ y: "65vh" }}
              onChange={(paging, filter, sort: any) => {
                console.log(paging, filter, sort);
                
                if (sort?.order || sort.columnKey) {
                  changeListParams("sortDir", sort?.order);
                  changeListParams("sortCol", sort?.columnKey);
                }
                // setListParams({
                //   ...listParams,
                //   sortDir: sort?.order,
                //   sortCol: sort?.columnKey,
                // });
                // setPagingItems({
                //   ...pagingItems,
                //   currentPage: 1,
                //   pageSize: 15,
                // });
                // setListParams((prevState) => ({
                //   ...prevState,
                //   // [key]: value,
                // }));
                paging &&
                  setPagingItems({
                    ...pagingItems,
                    currentPage: paging?.current ?? 1,
                    pageSize: paging?.pageSize ?? 15,
                  });
              }}
            ></Table>
          </Col>
        </Row>
      </Card>

      {openDrawer && (
        <>
          <SubjectAllocationAdd
            record={sendRecord}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            setTrigger={setTrigger}
          />
        </>
      )}
    </>
  );
};

export default SubjectAllocationList;
