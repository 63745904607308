import { Col, DatePicker, Form, message, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { leaveStatusEnum } from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import { IdNameType } from "../../Types";
import { queryClient } from "../../App";

const AddCounsellorsModal = (props: {
  open: boolean;
  handleCancel: Function;
  record: IdNameType[];
  setRefresh: any;
}) => {
  const { open, handleCancel, record, setRefresh } = props;
  const [form] = Form.useForm();
  const[counselorDate, setCounselorDate] = useState<any>(dayjs());
  const [loading, setLoading] = useState<boolean>(false);
  const [counselors, setCounselors] = useState<
    { label: string; value: string }[]
  >([]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formValues = form.getFieldsValue();
      const payload = {
        id: record.map(id => id.id),
        date: dayjs(counselorDate).format("MM-DD-YYYY"),
        counselors: formValues.counselors.map(
          (counselor: { label: string; value: string }) => {
            return { id: counselor?.value, name: counselor?.label };
          }
        ),
      };

        let res = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.ADD_COUNSELORS_INTO_ENQUIRY,
          {},
          payload
        );
        if (res?.status) {
          message.success("Counselors assigned successfully.");
          queryClient.invalidateQueries({
                queryKey:["getEnquiriesCallList"]
              });
          setRefresh();
        }
        form.resetFields();
        handleCancel();
     
    } catch (error: any) {
      message.error(
        error.message || "An error occurred while assign the Counselors."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getList = async () => {
      let res = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.GET_COUNSELORS_LIST + "?",
        {}
      );
      if (res?.result?.length > 0) {
        setCounselors(
          res?.result?.map((item: any) => ({
            value: item?.id,
            label: item?.name,
            // role: item?.role,
          }))
        );
      }
    };
    getList();
  }, []);

  return (
    <>
      <Modal
        okText={"Save"}
        title="Add Counselors"
        width={700}
        open={open}
        onCancel={() => handleCancel()}
        onOk={() =>
          form
            .validateFields()
            .then(handleSubmit)
            .catch((err) => {
              console.log(err);
            })
        }
        confirmLoading={loading}
        // onCancel={handleCancel}
      >
        <div
          style={{
            height: "100%",
            border: "1.5px solid lightgray",
            borderRadius: "5px",
            padding: "10px",
            color: "black",
          }}
        >
          <Form requiredMark={false} form={form} style={{ lineHeight: 2.5 }}>
            <Row>
              <Col span={6}>
                <p style={{ color: "black", fontWeight: "600" }}> Enquiries' Name</p>
              </Col>
              <Col>
              <p>{record.map((item: { name: string }) => item.name).join(", ")}</p>
              </Col>
            </Row>
            {/* <Row>
              <Col span={6}>
                <p style={{ color: "black", fontWeight: "600" }}>
                  {" "}
                  Enquiry Date
                </p>
              </Col>
              <Col>
                <p>{dayjs.utc(record?.createdBy?.date).format("DD/MM/YYYY")}</p>
              </Col>
            </Row> */}

            <Row>
              <Col span={24} style={{ color: "black", fontWeight: "600" }}>
                <Form.Item
                  label="Counselors"
                  rules={[
                    { required: true, message: "Please select a counselors" },
                  ]}
                  name={"counselors"}
                >
                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    labelInValue={true}
                    placeholder="Select Counselors"
                    style={{ width: 200 }}
                    options={counselors}
                    filterOption={(inputValue, option:any) => {
                      return option.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ color: "black", fontWeight: "600" }}>
                <Form.Item
                  label="Date"
                  name={"date"}
                >
                  <DatePicker
                  name="date" 
                  defaultValue={counselorDate}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  onChange={(date, dateString) => setCounselorDate(date)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddCounsellorsModal;
