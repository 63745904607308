import { useQuery } from "@tanstack/react-query";
import HTTPSCalls from "../Services/HTTPCalls";
import { useSelector } from "react-redux";
import { selectBatchState } from "../Store/Slice/batchSlice";
import { store } from "../Store/store";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { adminContext } from "../Common/PageRoute";
import { showBatchFilter } from "./Utils";
import { EnquiryCallStatusEnum, EnquiryCounselorActionOptions } from "../Constant/Enums";



// import axios from "axios";
// const batchState= useSelector((state:any)=>state.batch)

//For getting Schedule list name and id

interface Paggination{
  start?:number,
  length?:number,
  search?:string
  sortCol?:string
  sortdir?:string
  action?:EnquiryCounselorActionOptions
  counselorId?:string
}

export const GetListWithIdName = async (payload: any = {}) => {
  return HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_LIST_WITH_ID_NAME, payload);
};

export const useGetListWithIdName = (payload: any) => {
  return useQuery({
    queryKey: ["GetListWithIdName"],
    queryFn: () => GetListWithIdName(payload),
  });
};

export const getScheduleById = async (scheduleId: any = {}) => {
  return HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.SCHEDULE_GET_BY_ID + "?id=" + scheduleId,
    {}
  );
};

export const useGetScheduleById = (scheduleId: any = "") => {
  return useQuery({
    queryKey: ["getScheduleById", scheduleId],
    queryFn: () => getScheduleById(scheduleId),
    enabled: !!scheduleId,
  });
};

//For getting Room list name and id
export const getMaster = async (payload: any = {}) => {
  return HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?", payload);
};

export const useGetMaster = (payload: any,localPath?:any) => {
  const storeData = store?.getState();
  const localCheck = localPath ? showBatchFilter(localPath):true;
  return useQuery({
    queryKey: ["GetMaster", payload],
    queryFn: () => getMaster(payload),
    enabled: !!storeData?.user?.id && localCheck,
  });
};

//For getting Schedule list items by Schedule id
export const getScheduleItemListById = async (payload: any = {}) => {
  return HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.SCHEDULE_ITEM_LIST + "?", payload);
};

export const useGetScheduleItemListById = (payload: any = "") => {
  return useQuery({
    queryKey: [
      "getScheduleItemListById",
      payload?.scheduleId,
      payload?.sectionId,
    ],
    queryFn: () => getScheduleItemListById(payload),
    enabled: !!payload?.scheduleId && !!payload?.sectionId,
  });
};

//For getting Schedule list items by Schedule id
export const GetEnquiryListWithIdName = async (search: string = "") => {
  return HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.GET_ENQUIRY_LIST_WTIH_IDNAME + "?",
    { search }
  );
};

export const useGetEnquiryListWithIdName = (
  modelOpen: boolean = false,
  search: string = ""
) => {
  return useQuery({
    queryKey: ["EnquiryListWithIdName", search],
    enabled: modelOpen,
    queryFn: () => GetEnquiryListWithIdName(search),
  });
};

export const GetSubjectTypes = async (payload: any = {}) => {
  return HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.POST_MASTER + "?", payload);
};

export const UseSubjectTypes = (payload: any = {}, refresh: boolean) => {
  return useQuery({
    queryKey: ["GetSubjectTypes", payload, refresh],
    queryFn: () => GetSubjectTypes(payload),
  });
};

export const enquiryCommunicationGetById = async (payload: any) => {
  return await HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.EnquiryCommunication_GETBYID + "?",
    payload
  );
};

export const useEnquiryCommunicationGetById = (payload: any) => {
  return useQuery({
    queryKey: ["CommunicationGetById", payload],
    queryFn: () => enquiryCommunicationGetById(payload),
    enabled: !!payload?.Id,
  });
};

export const batchAttendance = async (payload: any) => {
  let res = await HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.GET_ALL_STUDENT_ATTENDANCE + "?",
    payload
  );
  // console.log("result",res?.result)
  let data = res?.result?.flatMap((el: any) => {
    if (el?.records && el?.records?.length > 0) {
      return el.records.map((item: any) => {
        return {
          name: el.name,
          id: el.id,
          Batch: el.batch,
          isLeave: el.isLeave,
          isHoliday: el.isHoliday,
          ...item,
        };
      });
    } else {
      return {
        name: el.name,
        id: el.id,
        studentId: el.studentId,
        Batch: el.batch,
        isLeave: el.isLeave,
        isHoliday: el.isHoliday,
        // ...el
      };
    }
  });
  return data;
};

export const useBatchAttendance = (payload: any) => {
  return useQuery({
    queryKey: ["GetAllStudentAttendance", payload],
    queryFn: () => batchAttendance(payload),
    // enabled:payload?.batchId?true:false
  });
};

export const getStudentStatus = async (batchState: any) => {
  return await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_STUDENT_STATUS + "?", {
    batchId: batchState,
  });
};

export const useGetStudentStatus = () => {
  const batchState = useSelector(selectBatchState);

  return useQuery({
    queryKey: ["getStudentStatus", batchState],
    queryFn: () => getStudentStatus(batchState),
  });
};

export const updateMasterRecordStatus = async (payload:any) => {
  return await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.UPDATE_RECORD_STATUS+"?", {
    objId:payload.id,
    restore:payload.restore
  },{});
};

export const useUpdateMasterRecordStatus = () => {
  const { openNotification } = useContext(adminContext);
  return useMutation({
    mutationFn: (payload:any) => updateMasterRecordStatus(payload),
    onSuccess:()=>{
      openNotification("success","Action perform successfully")
    }, 
    onError:()=>{
      openNotification("error","SomeThing is wrong")
    }
  });
};

export const getAssessmentByDesignCourse = async (payload: any) => {
  let res= await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_ASSESSMENT_BY_DESIGNCOURSE + "?", 
    payload,
  );
  return res?.result?.map((e:any)=>{
    return{
      label:e.assessmentName,
      value:e.id
    }
  })
};

export const useGetAssessmentByDesignCourse = (formValues:any) => {
  formValues=formValues.formData;
  let payload = {
    departmentId: formValues?.department?.value || "",
    courseId: formValues?.course?.value || "",
    batchId: formValues?.batch?.value || "",
    semesterId: formValues?.semester?.value || "",
    sectionId: formValues?.section?.value || "",
  }
  let check = payload.sectionId?true:false;

  return useQuery({
    queryKey: ["getAssessmentByDesignCourse", payload.sectionId,payload.courseId,payload.batchId,payload.semesterId,payload.departmentId],
    queryFn: () => getAssessmentByDesignCourse(payload),
    enabled:check
  });
};

export const getStudentByAssessment = async (payload: any) => {
  return await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_STUDENT_BY_ASSESSMENT + "?", 
    payload,
  );
};

export const useGetStudentByAssessment = (payload:any) => {
  let check = payload.assessmentId?true:false;
  return useQuery({
    queryKey: ["getStudentByAssessment", payload.assessmentId],
    queryFn: () => getStudentByAssessment(payload),
    enabled:check
  });
};

export const saveAllSubjectMarks = async (payload:any) => {
  console.log("saveAllSubjectMarks",payload)
  return await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.SAVE_ALL_SUBJECT_MARKS,{}, {
    ...payload
  });
};

export const useSaveAllSubjectMarks = () => {
  const { openNotification } = useContext(adminContext);
  return useMutation({
    mutationFn: (payload:any) => saveAllSubjectMarks(payload),
    onSuccess:()=>{
      openNotification("success","Action perform successfully")
    }, 
    onError:()=>{
      openNotification("error","SomeThing is wrong")
    }
  });
};

export const getStudentMarks = async (payload: any) => {
  return await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_SUBJECT_MARKS + "?", 
    payload,
  );
};

export const useGetStudentMarks = (payload:any) => {
  let check = payload.assessmentId?true:false;
  console.log(check,"useGetStudentMarks",payload)
  return useQuery({
    queryKey: ["getStudentMarks", payload.assessmentId],
    queryFn: () => getStudentMarks(payload),
    enabled:check
  });
};

export const getAssessmentSubject = async (payload: any) => {
  return await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_ASSESSMENT_SUBJECT + "?", 
    payload,
  );
};

export const useGetAssessmentSubject =(payload:any) => {
  let check = payload.assessmentId?true:false;
  return useQuery({
    queryKey: ["getAssessmentSubject", payload.assessmentId],
    queryFn: async() => {
    let res = await getAssessmentSubject(payload);
    return res?.result?.map((e:any)=>{
      return{
        label:e.name,
        value:e.id
      }
    })||[]
    },
    enabled:check
  });
};

export const getStudentByAssessmentSubject = async (payload: any) => {
  return await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_STUDENT_BY_ASSESSMENT_SUBJECT + "?", 
    payload,
  );
};

export const useGetStudentByAssessmentSubject =(payload:any) => {
  let check = payload.subjectId?true:false;
  return useQuery({
    queryKey: ["getStudentByAssessmentSubject",payload.assessmentId, payload.subjectId],
    queryFn: async() => await getStudentByAssessmentSubject(payload),
    enabled:check
  });
};

export const getResultByDesignCourse = async (payload: any) => {
  return await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_RESULT_BY_DESIGN_COURSE + "?", 
    payload,
  );
};

export const useGetResultByDesignCourse = (formValues:any,date:any) => {
  //formValues=formValues.formData;
  // console.log(date,"formValues",formValues)
  let payload = {
    departmentId: formValues?.department?.value || "",
    courseId: formValues?.course?.value || "",
    batchId: formValues?.batch?.value || "",
    semesterId: formValues?.semester?.value || "",
    sectionId: formValues?.section?.value || "",
    studentId: formValues?.student?.value || "",
    categoryId: formValues?.category?.value || "",
    ...(date.length>0&&{From: date[0]}),
    ...(date.length>0&&{To: date[1]}),
  }
  let check = payload.sectionId?true:false;

  return useQuery({
    queryKey: ["getResultByDesignCourse", payload.sectionId,payload.studentId,payload.categoryId,date],
    queryFn: () => getResultByDesignCourse(payload),
    enabled:check
  });
};

export const getNotAssignedEnquiries = async (payload:Paggination) => {
  return await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_NOT_ASSIGNED_ENQUIRIES+"?",{
    ...payload
  } );
};

export const useGetNotAssignedEnquiries = (payload:Paggination) => {
  return useQuery({
    queryKey: ["getNotAssignedEnquiries",payload],
    queryFn: () => getNotAssignedEnquiries(payload),
  });
};

export const getEnquiriesCallList = async (payload:{
  date:string,
  callStatus: EnquiryCallStatusEnum,
  search?:string,
  counselor?:string,
}) => {
  return await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_ENQUIRY_CALL_LIST+"?",{
    ...payload
  } );
};

export const useGetEnquiriesCallList = (payload:{
   date:string,
   callStatus: EnquiryCallStatusEnum,
   search?:string,
   counselor?:string,
}) => {
  return useQuery({
    queryKey: ["getEnquiriesCallList",payload],
    queryFn: () => getEnquiriesCallList(payload),
  });
};

export const getEnquiriesCounselorList = async () => {
  return await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_COUNSELORS_LIST+"?",{} );
};
export const useGetEnquiriesCounselorList = () => {
  return useQuery({
    queryKey: ["getEnquiriesCounselorList"],
    queryFn: () => getEnquiriesCounselorList(),
  });
};
