import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  List,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import Search from "antd/es/input/Search";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddEditMailTemplaes from "./AddEditMailTemplaes";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { MailTemplatesMaster } from "../../../ApiUrls/URLS";
import {
  ActiveInactiveOptions,
  MasterTypes,
  RecordStatusType,
} from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import { ColumnsType } from "antd/es/table";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { pageSizeOption } from "../LeaveTypes/LeaveTypes";
interface I_CURRICULUM {
  key: string;
  sNo: number;
  id: string;
  name: string;
  recordStatus: any;
}
const MailTemplates = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [allCurriculum, setAllCurriculum] = useState<I_CURRICULUM[]>();
  const [mailTemplatesId, setMailTemplatesId] = useState<string | undefined>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [recordData, setRecordData] = useState<any>([]);
  const [changedStatus, setChangedStatus] = useState<any>("active");
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 100 });
  const [listParams, setListParams] = useState({
    search: "",
    start: 0,
    length: 15,
    sortCol: "Name",
    sortDir: "ascend",
  });

  const UpdateRecordStatus = async (
    noticeId?: string,
    action: string = "delete"
  ) => {
    try {
      if (!noticeId) {
        console.error("Notice ID is required to update record status.");
        message.error("Notice ID is missing. Unable to update record status.");
        return;
      }
      setPageLoader(true);
      await HTTPSCalls.POST(
        `${HTTPSCalls.ENDPOINTS.UPDATE_RECORD_STATUS}` + "?",
        {
          objId: noticeId,
          restore: action,
        },
        {}
      );
      setRefresh((prev: any) => !prev);
    } catch (error) {
      message.error("An unexpected error occured");
      console.log("Error during API call:", error);
    } finally {
      setPageLoader(false);
    }
  };
  const columns: ColumnsType<I_CURRICULUM> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "5%",
      render: (index: any) => <p>{index}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "90%",
      // showSorterTooltip: false,
      // sorter: true,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setMailTemplatesId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      align: "center",
      width: "18%",
      key: "subject",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setMailTemplatesId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "10%",
      ellipsis: true,
      align: "center",
      render: (text, record) => (
        <Space size={35}>
          <FormOutlined
            className="cursor editIconColour"
            onClick={() => {
              setOpen(true);
              console.log(record, "recordsasdf");

              setRecordData(record);
            }}
          />
          {record?.recordStatus === RecordStatusType?.Deleted ? (
            <UndoOutlined
              className="cursor"
              onClick={() => {
                UpdateRecordStatus(record?.id, "restore");
              }}
            />
          ) : (
            <Popconfirm
              okText={"Yes"}
              title={"Are you sure to delete this task?"}
              className="cursor"
              onConfirm={() => UpdateRecordStatus(record?.id,"delete")}
            >
              <DeleteOutlined className="cursor deleteIconColour" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  useEffect(() => {
    setPageLoader(true);
    get_curriculum(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems, refresh, changedStatus]);
  const api = new ApiCalls(
    MailTemplatesMaster.endPoints,
    MailTemplatesMaster.prefix
  );
  const get_curriculum = async (start: any, length: any) => {
    await api
      .GET(MailTemplatesMaster.endPoints.getList + "?", {
        masterType: MasterTypes.MailTemplates,
        start: start,
        length: length,
        search: listParams.search,
        sortCol: listParams.sortCol,
        sortDir: listParams.sortDir,
        status: changedStatus,
      })
      .then((res: IApiResponse) => {
        if (/*res?.errors === null &&*/ res?.result) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          setAllCurriculum(
            res?.result?.items.map((r: any, i: any) => ({
              key: i,
              sno: r?.sno,
              id: r?.id,
              name: r?.name,
              subject: r?.subject,
              recordStatus: r?.recordStatus,
              htmlContent: r?.htmlContent,
            }))
          );
          setPageLoader(false);
          console.log(allCurriculum, "allcurrrr");
        } else {
          message.error(res?.message);
          setPageLoader(false);
        }
      });
  };

  return (
    <>
      {open && (
        <AddEditMailTemplaes
          recordData={recordData}
          setTrigger={setTrigger}
          trigger={trigger}
          open={open}
          setOpen={setOpen}
          SetDrawerClose={setOpenDrawer}
          templateId={mailTemplatesId}
        />
      )}
      <Row
        justify={"space-between"}
        align="middle"
        style={{ marginBottom: "6px" }}
      >
        <ArrowLeftOutlined
          style={{ color: "#1677ff" }}
          onClick={() => {
            navigate("/settings");
          }}
        />
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Mail Template",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row gutter={18}>
            <Col span={20}>
              <Button
                type="primary"
                className="cit-add-btn mb-1"
                onClick={() => {
                  setMailTemplatesId(undefined);
                  setOpenDrawer(true);
                  setOpen(true);
                  setRecordData([]);
                }}
              >
                <PlusOutlined />
                Template
              </Button>
            </Col>
            <Col span={4}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Search status"
                optionFilterProp="label"
                onChange={(val) => {
                  setChangedStatus(val);
                }}
                defaultValue={"active"}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={ActiveInactiveOptions}
              />
            </Col>
            {/* <Col span={4}>
            <Search
              size="middle"
              placeholder="Search..."
              allowClear
              className="att-search-input mb-1"
              // onSearch={(val: string) => setListParamsAndRefresh(val)}
              // onChange={(e: any) =>
              //     e.target.value === "" ? setListParamsAndRefresh("") : null
              // }
              style={{ width: 170 }}
            />
            </Col> */}
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "12px" }}>
          <Table
            className="cit-table"
            loading={{
              spinning: pageLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            size="small"
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={allCurriculum}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: pageSizeOption,
            }}
            onChange={(paging, filter, sort: any) => {
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default MailTemplates;
