import "../ManageWeight/Weigth.css"
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Form, Modal, Row, Col, Input, Button, Table, Typography, Spin } from "antd";
import { PlusOutlined, DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { MasterTypes } from "../../../../Constant/Enums";
import { adminContext } from "../../../../Common/PageRoute";
const { Paragraph } = Typography;

interface I_Props {
  IsRublicModel: boolean;
  setIsRublicModel: React.Dispatch<React.SetStateAction<boolean>>;
  RubricDetail:any;
  SetRubricDetail:any
  RubricRefech:any
  setDefault:any
  sdefault:any
}

interface I_DataSource {
  key: number;
  criteria: {
    title: string;
    description: string;
  };
  gradingScale: Array<{
    pointTitle: string;
    pointDescription: string;
    pointFeedback: string;
  }>;
}

const AddRubric: React.FC<I_Props> = ({ IsRublicModel, setIsRublicModel,RubricDetail,SetRubricDetail,RubricRefech,setDefault,sdefault }) => {
  const [form] = Form.useForm();
  const [count, setCount] = useState(3);
  const [totalPoint, setTotalPoint] = useState<number>();
  const [loading,setLoading]= useState<boolean>();
  const { openNotification } = useContext(adminContext);
  const [dataSource, setDataSource] = useState<I_DataSource[]>([
    {
      key: 1,
      criteria: {
        title: "Criteria Title",
        description: "Criteria Description",
      },
      gradingScale: [
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
      ],
    },
    {
      key: 2,
      criteria: {
        title: "Criteria Title",
        description: "Criteria Description",
      },
      gradingScale: [
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
      ],
    },
  ]);

  const defaultSource:any = [
    {
      key: 1,
      criteria: {
        title: "Criteria Title",
        description: "Criteria Description",
      },
      gradingScale: [
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
      ],
    },
    {
      key: 2,
      criteria: {
        title: "Criteria Title",
        description: "Criteria Description",
      },
      gradingScale: [
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
      ],
    },
  ]

  useEffect(()=>{
    if (RubricDetail) {
      form.setFieldsValue({
        rubricName: RubricDetail?.name,
      })
      setDataSource(RubricDetail.RubricPoint)
    }
    if(sdefault){
      setDataSource(defaultSource)
      console.log("called Rubric",defaultSource)
    }
  },[RubricDetail,sdefault])

  const handleDelete = (del: any) => {
    // if (RubricDetail) {
    //   SetRubricDetail(RubricDetail?.RubricPoint?.filter((item:any) => item.key !== del));
    // }else{
      setDataSource(dataSource?.filter((item) => item.key !== del));
    // }
    console.log("name",defaultSource)
  };

  const handleAdd = () => {
    const newData: I_DataSource = {
      key: count,
      criteria: {
        title: "Criteria Title",
        description: "Criteria Description",
      },
      gradingScale: [
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
        {
          pointTitle: "4",
          pointDescription: "Excellent",
          pointFeedback: "Description or detail view",
        },
      ],
    };
    if (RubricDetail) {
      SetRubricDetail({ ...RubricDetail, RubricPoint: [...RubricDetail.RubricPoint, newData] });
    }else{
      setDataSource([...dataSource, newData]);
    }
    setCount(count + 1);
  };

  const handleSubmit = () => {
    try {
      setLoading(true);
      form
        .validateFields()
        .then(async (val:any) => {
          const res = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.POST_MASTER+"?",{},
            {
              id:RubricDetail?.id,
              MasterType:MasterTypes.Rubric,
              name:val?.rubricName,
              rubricPoint:dataSource
            }
          )
          if (res) {
            openNotification("success","Rubric Create Successfully");
          }else{
            openNotification("error","Rubric Create Failed");
          }
          setIsRublicModel(false);
          RubricRefech()
          setLoading(false)
        })
        .catch((error) => {
          openNotification("error",error);
        }).finally(()=>{
          setLoading(false)
        })
      } catch (error) {
        console.error("Error submitting form:", error);
      }finally{
        RubricRefech()
        setDefault(false)
    }
  };

  const handleCancel = () => {
    setIsRublicModel(false);
    SetRubricDetail(null)
    form.resetFields()
    setDefault(false)
  };

  const calculateTotalPoints = () => {
    console.log("datasource",RubricDetail)
    let total = dataSource.reduce((total, item) => {
      const highestPoint = Math.max(
        ...item?.gradingScale?.map((point) => Number(point.pointTitle))
      );
      
      return total + highestPoint;
    }, 0);
    setTotalPoint(total);
    return total;
  };

  useEffect(() => {
    calculateTotalPoints();
    console.log("ToalPoint");
  }, [dataSource,RubricDetail]);

  const columns = [
    {
      title: "Criteria",
      dataIndex: "criteria",
      render: (text: any, record: I_DataSource) => (
        <>
        {console.log("record",record)}
        <Form.Item
          name={`title${record.key}`}
          initialValue={text.title}
          style={{ marginBottom: 0 }}
        >
          <Paragraph
          className="weightText"
            editable={{
              onChange: (str) => {
                if (str !== "") {
                  record.criteria.title = str;
                  setDataSource([...dataSource]);
                }
              },
              triggerType: ["text"],
              autoSize: { minRows: 1, maxRows: 3 },
            }}
            style={{
              marginBottom: 10,
              marginLeft: 5,
              width: "auto",
              height: "auto",
            }} // Adjust width and height
          >
            {text.title}
          </Paragraph>
          <Paragraph
          className="weightText"
            editable={{
              onChange: (str) => {
                if (str !== "") {
                  record.criteria.description = str;
                  setDataSource([...dataSource]);
                }
              },
              triggerType: ["text"],
              autoSize: { minRows: 1, maxRows: 3 },
            }}
            style={{ margin: 0, marginLeft: 5, width: "auto", height: "auto" }} // Adjust width and height
          >
            {text.description}
          </Paragraph>
        </Form.Item>
        </>
      ),
    },
    {
      title: "Grading Scale",
      dataIndex: "gradingScale",
      render: (points: any, record: I_DataSource) => (
        <div>
          {record.gradingScale.map((point, index) => (
            <Form.Item
              name={`point${record.key}-${index}`}
              initialValue={point.pointTitle}
              style={{
                display: "inline-block",
                width: "calc(25% - 8px)",
                marginRight: 8,
              }}
              key={index}
            >
              <Paragraph
              className="weightText"
                editable={{
                  onChange: (str) => {
                    if (str !== "") {
                      point.pointTitle = str;
                      setDataSource([...dataSource]);
                    }
                  },
                  triggerType: ["text"],
                  autoSize: { minRows: 1, maxRows: 3 },
                }}
                style={{
                  margin: 0,
                  marginLeft: 6,
                  width: "auto",
                  height: "auto",
                }} // Adjust width and height
              >
                {point.pointTitle}
              </Paragraph>
              <Paragraph
              className="weightText"
                editable={{
                  onChange: (str) => {
                    if (str !== "") {
                      point.pointDescription = str;
                      setDataSource([...dataSource]);
                    }
                  },
                  triggerType: ["text"],
                  autoSize: { minRows: 1, maxRows: 3 },
                }}
                style={{
                  margin: 0,
                  marginLeft: 6,
                  width: "auto",
                  height: "auto",
                }} // Adjust width and height
              >
                {point.pointDescription}
              </Paragraph>
              <Paragraph
              className="weightText"
                editable={{
                  onChange: (str) => {
                    if (str !== "") {
                      point.pointFeedback = str;
                      setDataSource([...dataSource]);
                    }
                  },
                  triggerType: ["text"],
                  autoSize: { minRows: 1, maxRows: 3 },
                }}
                style={{
                  margin: 0,
                  marginLeft: 6,
                  width: "auto",
                  height: "auto",
                }} // Adjust width and height
              >
                {point.pointFeedback}
              </Paragraph>
            </Form.Item>
          ))}
        </div>
      ),
    },
    {
      title: "Pts",
      dataIndex: "gradingScale",
      render: (points: any, record: I_DataSource) => (
        <Typography.Text>
          {Math.max(
            ...record?.gradingScale.map((point) => Number(point.pointTitle))
          )}
        </Typography.Text>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: any, record: I_DataSource,index:any) =>
        dataSource.length >= 1 ? (
          <DeleteOutlined className="cursor deleteIconColour" onClick={() => handleDelete(record.key)} />
        ) : null,
    },
  ];

  return (
    <Modal
      title="Add Grading Scale"
      open={IsRublicModel}
      onCancel={handleCancel}
      footer={
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            <Button type="primary" htmlType="submit" onClick={handleSubmit}  loading={loading}>
              {RubricDetail?"Update":"Save"}
            </Button>
            <Button
              type="default"
              onClick={handleCancel}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      }
      width={"960px"}
      styles={{
        body: {
          maxHeight: 560,
          overflowY: "auto",
        },
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Rubric Scale Name"
              name="rubricName"
              rules={[
                {
                  required: true,
                  message: "Please input rubric scale name!",
                },
              ]}
            >
              <Input placeholder="Enter rubric scale name" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Table
              bordered
              // dataSource={RubricDetail?RubricDetail.RubricPoint: dataSource}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              footer={() => (
                <>
                  <Button
                    type="primary"
                    onClick={handleAdd}
                    icon={<PlusOutlined />}
                  >
                    Add Criteria
                  </Button>
                  <Typography.Text style={{ float: "right", marginTop: 10 }}>
                    Total Points: {totalPoint}
                  </Typography.Text>
                </>
              )}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddRubric;
