import * as React from "react";
// import { useContext, useState, useEffect } from "react";
import { Button, Modal, Space } from "antd";

const ConfirmationModel: React.FC<{
  open: boolean;
  onOk: any;
  onCancel: any;
  text: any;
  parentLoading?: boolean;
}> = ({
  open,
  onOk,
  onCancel,
  text,
  parentLoading = false, // default value is false for loading
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <>
      <Modal
        title={"Confirmation"}
        open={open}
        onCancel={onCancel}
        width={370}
        confirmLoading={parentLoading}
        style={{ marginTop: "200px" }}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button onClick={onCancel} type="default">
              NO
            </Button>
            <Button
              type="primary"
              loading={loading || parentLoading}
              onClick={onOk}
            >
              Yes
            </Button>
          </Space>
        }
      >
        <p style={{ color: "gray" }}>{text}</p>
      </Modal>
    </>
  );
};

export default ConfirmationModel;
