import "../../Common/MainLayout.css";
import {
  ArrowRightOutlined,
  BellOutlined,
  BookOutlined,
  BranchesOutlined,
  CalendarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  ContainerOutlined,
  ControlOutlined,
  CrownOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FilterOutlined,
  GlobalOutlined,
  HourglassOutlined,
  IdcardOutlined,
  InfoOutlined,
  MailOutlined,
  ProfileOutlined,
  RadarChartOutlined,
  ScheduleOutlined,
  SearchOutlined,
  SolutionOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Collapse, Input, List, Row, Space, Tooltip } from "antd";
import "../../Custom.css";
import { useNavigate } from "react-router";
import type { CollapseProps } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";

const Setting = () => {
  const [searchTerm, setSearchTerm] = useState<any>("");
  const navigate = useNavigate();
  const data = [
    {
      icon: <CrownOutlined style={{ fontSize: "19px" }} />,
      name: "Curriculum",
      summary: "Create edit or remove curriculum",
      onClick: () => {
        navigate("Curriculum");
      },
    },
    {
      icon: <ContainerOutlined style={{ fontSize: "19px" }} />,
      name: "Stream",

      summary: "Add Edit and delete stream",
      onClick: () => {
        navigate("Stream");
      },
    },
    {
      icon: <GlobalOutlined style={{ fontSize: "19px" }} />,
      name: "Class",
      summary: "Add Edit and remove class",
      onClick: () => {
        navigate("Class");
      },
    },
    {
      icon: <BookOutlined style={{ fontSize: "19px" }} />,
      name: "Subject Types",
      summary: "Add Edit and remove subject types",
      onClick: () => {
        navigate("subjectTypes");
      },
    },
    {
      icon: <DollarOutlined style={{ fontSize: "19px" }} />,
      name: "Payment",
      summary: "Add Edit and remove payments",
      onClick: () => {
        navigate("Payment");
      },
    },
    {
      icon: <UserAddOutlined style={{ fontSize: "19px" }} />,
      name: "Credentials",
      summary: "Add Edit and delete credentials",
      onClick: () => {
        navigate("Credentials");
      },
    },
    {
      icon: <FileDoneOutlined style={{ fontSize: "19px" }} />,
      name: "Document",
      summary: "Add Edit and remove documents",
      onClick: () => {
        navigate("Document");
      },
    },
    {
      icon: <FileDoneOutlined style={{ fontSize: "19px" }} />,
      name: "Mandatory Document Type",
      summary: "Add Edit and remove mendatory document types",
      onClick: () => {
        navigate("Mandatory-document-type");
      },
    },
    {
      icon: <CalendarOutlined style={{ fontSize: "19px" }} />,
      name: "Holiday",
      summary: "Add Edit and remove holidays",
      onClick: () => {
        navigate("Holiday");
      },
    },
    {
      icon: <ControlOutlined style={{ fontSize: "19px" }} />,
      name: "Status",
      summary: "Add Edit and remove status",
      onClick: () => {
        navigate("Status");
      },
    },
    {
      icon: <HourglassOutlined style={{ fontSize: "19px" }} />,
      name: "Medium",
      summary: "Add Edit and remove medium",
      onClick: () => {
        navigate("Medium");
      },
    },
    {
      icon: <UsergroupAddOutlined style={{ fontSize: "19px" }} />,
      name: "Role",
      summary: "Add Edit and remove roles",
      onClick: () => {
        navigate("Role");
      },
    },
    {
      icon: <BranchesOutlined style={{ fontSize: "19px" }} />,
      name: "FeesType",
      summary: "Add Edit and fees types",
      onClick: () => {
        navigate("FeesType");
      },
    },
    {
      icon: <InfoOutlined style={{ fontSize: "19px" }} />,
      name: "Source",
      summary: "Add Edit and remove sources",
      onClick: () => {
        navigate("Source");
      },
    },
    {
      icon: <SolutionOutlined style={{ fontSize: "19px" }} />,
      name: "Users",
      summary: "Add Edit and remove users",
      onClick: () => {
        navigate("User");
      },
    },
    {
      icon: <SolutionOutlined style={{ fontSize: "19px" }} />,
      name: "Reason",
      summary: "Add Edit and remove reasons",
      onClick: () => {
        navigate("Reason");
      },
    },
    {
      icon: <ProfileOutlined style={{ fontSize: "19px" }} />,
      name: "Process Steps For Website",
      summary: "Add Edit and remove process steps for websites",
      onClick: () => {
        navigate("processStepsForWebsite");
      },
    },
    {
      icon: <ProfileOutlined style={{ fontSize: "19px" }} />,
      name: "Process Steps For Application",
      summary: "Add Edit and remove process steps for applications",
      onClick: () => {
        navigate("processStepsForApplication");
      },
    },
    {
      icon: <VideoCameraOutlined style={{ fontSize: "19px" }} />,
      name: "PlatForm",
      summary: "Add Edit and remove platforms",
      onClick: () => {
        navigate("PlatForm");
      },
    },
    {
      icon: <ClockCircleOutlined style={{ fontSize: "19px" }} />,
      name: "Reminder",
      summary: "Add Edit and remove reminder",
      onClick: () => {
        navigate("Reminder");
      },
    },
    {
      icon: <CheckOutlined style={{ fontSize: "19px" }} />,
      name: "Task",
      summary: "Add Edit and remove tasks",
      onClick: () => {
        navigate("Task");
      },
    },
    {
      icon: <MailOutlined style={{ fontSize: "19px" }} />,
      name: "Mail Template",
      summary: "Add Edit and remove mail templates",
      onClick: () => {
        navigate("MailTemplate");
      },
    },
    {
      icon: <RadarChartOutlined style={{ fontSize: "19px" }} />,
      name: "Room",
      summary: "Add Edit and remove rooms",
      onClick: () => {
        navigate("Room");
      },
    },
    {
      icon: <CalendarOutlined style={{ fontSize: "19px" }} />,
      name: "Leave Types",
      summary: "Add Edit and remove leave types",
      onClick: () => {
        navigate("leaveTypes");
      },
    },
    {
      icon: <BellOutlined style={{ fontSize: "19px" }} />,
      name: "Notice",
      summary: "Add Edit and remove notices",
      onClick: () => {
        navigate("notice");
      },
    },
    {
      icon: <RadarChartOutlined style={{ fontSize: "19px" }} />,
      name: "Privacy Policy",
      summary: "Add Edit and remove privacy policy",
      onClick: () => {
        navigate("PrivacyPolicy");
      },
    },
    {
      icon: <BookOutlined style={{ fontSize: "19px" }} />,
      name: "Shift List",
      summary: "Add Edit and remove Shift List",
      onClick: () => {
        navigate("ShiftList");
      },
    },
  ];

  const studyPlan = [
    {
      icon: <BookOutlined style={{ fontSize: "19px" }} />,
      name: "Design Course",
      summary: "Add Edit and remove design course",
      onClick: () => {
        navigate("designCourse");
      },
    },
    {
      icon: <BookOutlined style={{ fontSize: "19px" }} />,
      name: "Faculty Type",
      summary:"Add Edit and remove Faculty Type",
      onClick: () => {
        navigate("FacultyType");
      },
    },
    {
      icon: <BookOutlined style={{ fontSize: "19px" }} />,
      name: "Faculty map with department",
      summary: "Add Edit and remove faculty map with department",
      onClick: () => {
        navigate("FacultyMapWithDept")
      },
    },
  ];
  const attendanceList = [
    {
      icon: <ScheduleOutlined style={{ fontSize: "19px" }} />,
      name: "Biometric Devices",
      summary: "Add Edit and remove biometric devices",
      onClick: () => {
        navigate("biometric-device");
      },
    }
  ];
  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredStudyPlan = studyPlan.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredAttendanceList = attendanceList.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <span style={{ fontWeight: 600 }}>Masters</span>,
      children: (
        <List
          style={{ width: "100%" }}
          dataSource={filteredData}
          size="small"
          className="customStyleList"
          renderItem={(item) => (
            <List.Item onClick={item?.onClick}>
              <Space direction="horizontal">
                <Button type="link" icon={item?.icon}>
                  {item?.name}
                </Button>
              </Space>
              <span>
                <ArrowRightOutlined onClick={item?.onClick} />
              </span>
            </List.Item>
          )}
        />
      ),
    },
    {
      key: "2",
      label: <span style={{ fontWeight: 600 }}>Study Plan</span>,
      // children: [{...studyPlan}],
      children: (
        <List
          style={{ width: "100%" }}
          dataSource={filteredStudyPlan}
          size="small"
          className="customStyleList"
          renderItem={(item) => (
            <List.Item onClick={item?.onClick}>
              <Space direction="horizontal">
                <Button type="link" icon={item?.icon}>
                  {item?.name}
                </Button>
              </Space>
              <span>
                <ArrowRightOutlined onClick={item?.onClick} />
              </span>
            </List.Item>
          )}
        ></List>
      ),
    },
    {
      key: "4",
      label: <span style={{ fontWeight: 600 }}>Attendance</span>,
      // children: [{...studyPlan}],
      children: (
        <List
          style={{ width: "100%" }}
          dataSource={filteredAttendanceList}
          size="small"
          className="customStyleList"
          renderItem={(item) => (
            <List.Item onClick={item?.onClick}>
              <Space direction="horizontal">
                <Button type="link" icon={item?.icon}>
                  {item?.name}
                </Button>
              </Space>
              <span>
                <ArrowRightOutlined onClick={item?.onClick} />
              </span>
            </List.Item>
          )}
        ></List>
      ),
    },
  ];
  const renderList = (list: typeof data) => (
    <List
      dataSource={list}
      size="small"
      renderItem={(item: {
        icon: JSX.Element;
        name: string;
        summary: string;
        onClick: () => void;
      }) => (
        <List.Item onClick={item?.onClick}>
          <Space style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >

            <div style={{ display: 'flex', alignItems: 'center', gap: '14px' }} className="cursor" >
              {item?.icon}
              <div>
                <span style={{ color: "#172B4D", fontWeight: 400, fontSize: "14px", }}>{item?.name}</span>
                <br />
                <span style={{ fontSize: '12px', color: '#172b4d', opacity: .5, fontWeight: 600 }}>{item?.summary}</span>
              </div>
            </div>
            <ArrowRightOutlined onClick={item?.onClick} style={{ alignSelf: 'center', color: '#888' }} />
          </Space>
        </List.Item>
      )}
    />
  );


  return (
    <>
      <Row align="middle">
        <Col>
          <Input style={{ width: "100%" }} onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} allowClear placeholder="Search by name...."
            suffix={
              <Tooltip title="Extra information">
                <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>

            } />
        </Col>
      </Row>
      <Row className="mt-2" gutter={[16, 16]} >
        {filteredData?.length > 0 && <Col span={24}>
          <Card title={"Masters"} className="customCardHead customCardBody" style={{ borderRadius: "18px" }}>
            {renderList(filteredData)}
          </Card>
        </Col>}
        {
          filteredStudyPlan?.length > 0 &&
          <Col span={24}>
            <Card title="Study Plan" className="customCardHead customCardBody" style={{ borderRadius: "18px" }}>
              {renderList(filteredStudyPlan)}
            </Card>
          </Col>
        }
        {
        //   filteredAttendanceList?.length>0 &&
        // <Col span={24}>
        //   <Card title="Attendance" className="customCardHead customCardBody" style={{ borderRadius: "18px" }}>
        //     {renderList(filteredAttendanceList)}
        //   </Card>
        // </Col>
        }
      </Row>
    </>
  );
};

export default Setting;
