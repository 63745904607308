import * as React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Radio,
  message,
  Table,
  TimePicker,
  DatePicker,
  Drawer,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { MasterTypes } from "../../../../Constant/Enums";
import dayjs from "dayjs";
import { queryClient } from "../../../../App";
import ConfirmationModel from "../../../../Common/ConfirmationModel";

interface I_Props {
  onClose: Function;
  // form: any;
  // handelFile: any;
  // handlePreview: any;
  // previewImage: any;
  // previewOpen: any;
  // setPreviewImage: any;
  // setPreviewOpen: any;
  assessmentsId: any;
  recordData: any;
  // currectPath:any;
  open: any;
}

const AddAssessments: React.FC<I_Props> = (props) => {
  // const [marksPoint, setMarksPoint] = React.useState<any>("");
  // const [passValidation, setPassValidation] = React.useState<
  //   number | undefined
  // >();
  const [loading, setLoading] = React.useState<any>(false);
  const [departMentData, setDepartMentData] = React.useState<any>([]);
  const [courseData, setCourseData] = React.useState<any>([]);
  const [batchData, setBatchData] = React.useState<any>([]);
  const [semesterData, setSemesterData] = React.useState<any>([]);
  const [sectionsData, setSectionsData] = React.useState<any>([]);
  // const [departmentId, setDepartmentId] = React.useState<any>([]);
  const [subjectsData, setSubjectsData] = React.useState<any>([]);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<number>(0);
  // const [recordData,setRecordData]=React.useState<number
  const [form] = Form.useForm();
  const { RangePicker } = TimePicker;
  // React.useEffect(() => {
  //   // setPassValidation(form.getFieldValue("totalMarks"));
  // }, []);
  // const { openNotification } = React.useContext(adminContext);
  // React.useEffect(() => {
  //   let temp = form.getFieldValue("marksIn") || "";
  //   setMarksPoint(temp);
  // }, [marksPoint]);

  const { data: AssessmentCategory } = useQuery({
    queryKey: ["assessmentCategory"],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?",
          {
            masterType: MasterTypes.AssessmentCategory,
            start: 0,
            length: 50,
          }
        );
        if (/*res?.errors === null &&*/ res?.result?.items?.length > 0) {
          // console.log(res?.result?.items, "categoryOption");
          return res?.result?.items.map((item: any) => ({
            key: item.id,
            label: item.name,
            value: item.id,
          }));
        } else {
          console.log("error");
          return [];
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });

  // const options: SelectProps["options"] = [];

  // for (let i = 10; i < 36; i++) {
  //   options.push({
  //     label: i.toString(36) + i,
  //     value: i.toString(36) + i,
  //   });
  // }

  // const handleChange = (value: string[]) => {
  //   console.log(`selected ${value}`);
  // };

  // const upload: UploadProps = {
  //   name: "file",
  //   action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };
  // const getBase64 = (file: FileType): Promise<string> =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.onerror = (error) => reject(error);
  //   });

  // type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

  // const normFile = (e: any) => {
  //   console.log("Upload event:", e);
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e?.fileList;
  // };

  const handleSubmit = () => {
    form.validateFields().then(async (val: any) => {
      try {
        setButtonLoading(true);
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const res = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.POST_ASSESSMENTS_DATA,
          {},
          {
            ...(props?.assessmentsId && { id: props?.assessmentsId }),
            category: {
              id: val?.category?.value,
              name: val?.category?.label,
            },
            assessmentName: val.assessmentName,
            isCountOverallScore: val?.isCountOverallScore,
            // passingMarks: val.passingMarks,
            department: {
              id: val.department?.value,
              name: val?.department?.label,
            },
            course: {
              id: val?.course?.value,
              name: val?.course?.label,
            },
            batch: {
              id: val?.batch?.value,
              name: val?.batch?.label,
            },
            semester: {
              id: val?.semester?.value,
              name: val?.semester?.label,
            },
            section: {
              id: val?.section?.value,
              name: val?.section?.label,
            },
            assessmentDetails: val?.assessmentDetails?.map((detail: any) => ({
              subject: {
                id: detail?.subject?.value,
                name: detail?.subject?.label,
              },
              maxMarks: detail?.maxMarks,
              passingMarks: detail?.passingMarks,
              date: dayjs(detail?.date).format("YYYY-MM-DD"),
              time: {
                from: dayjs(detail?.time[0]).format("HH:mm"),
                to: dayjs(detail?.time[1]).format("HH:mm"),
              },
            })),
            tz,
          }
        );

        if (res?.status) {
          message.success("Assessment created successfully");
          props.onClose();
          form.resetFields();
          queryClient.invalidateQueries({ queryKey: ["Assessments"] });
        } else {
          message.error(res?.message || "Something went wrong.");
        }
        setButtonLoading(false);
      } catch (error) {
        console.error("Error submitting the form:", error);
        setButtonLoading(false);
      }
    });
  };

  React.useEffect(() => {
    if (props?.recordData) {
      form.setFieldsValue({
        assessmentName: props?.recordData?.assessmentName,
        isCountOverallScore: props?.recordData?.IsCountOverallScore,
        category: {
          label: props?.recordData?.Category?.Name,
          value: props?.recordData?.Category?._id,
        },
        department: {
          label: props?.recordData?.Department?.Name,
          value: props?.recordData?.Department?._id,
        },
        semester: {
          label: props?.recordData?.Semester?.Name,
          value: props?.recordData?.Semester?._id,
        },
        batch: {
          label: props?.recordData?.Batch?.Name,
          value: props?.recordData?.Batch?._id,
        },
        course: {
          label: props?.recordData?.Course?.Name,
          value: props?.recordData?.Course?._id,
        },
        section: {
          label: props?.recordData?.Section?.Name,
          value: props?.recordData?.Section?._id,
        },
        assessmentDetails: props?.recordData?.AssessmentDetails?.map(
          (detail: any) => ({
            subject: {
              label: detail?.Subject?.Name,
              value: detail?.Subject?._id,
            },
            maxMarks: detail?.MaxMarks,
            passingMarks: detail?.PassingMarks,
            date: dayjs(detail?.Date),
            time: [dayjs(detail?.Time?.From), dayjs(detail?.Time?.To)],
          })
        ),
      });
      setTrigger((prev) => prev + 1);
    }
  }, [props?.recordData]);

  const handleFieldChange = (field: string, value: any) => {
    switch (field) {
      case "department":
        form.resetFields([
          "course",
          "batch",
          "semester",
          "section",
          "subject",
          "assessmentDetails"
        ]);
        break;
        case "course":
          form.resetFields([
            "batch",
            "semester",
            "section",
            "subject",
            "assessmentDetails"
            
        ]);
        break;
      case "batch":
        form.resetFields(["section", "semester", "subject","assessmentDetails"]);
        break;
      case "semester":
        form.resetFields(["section", "subject","assessmentDetails"]);
        break;
      case "section":
        form.resetFields([ "subject",]);
        break;
      default:
        break;
    }
    setTrigger((prev) => prev + 1);
  };

  const listColumns = [
    {
      title: <div style={{ textAlign: "center" }}>Subject</div>,
      dataIndex: "subject",
      width: "4%",
      key: "subject",
      render: (_: any, __: any, index: number) => (
        <Form.Item
          style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
          name={[index, "subject"]}
          rules={[
            {
              required: true,
              message: "select subject",
            },
          ]}
        >
          <Select
            labelInValue={true}
            style={{ width: "150px" }}
            placeholder="Select subject"
            options={subjectsData}
          />
        </Form.Item>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Max. Marks</div>,
      dataIndex: "maxMarks",
      width: "10%",
      key: "maxMarks",
      render: (_: any, __: any, index: number) => (
        <Form.Item
          style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
          name={[index, "maxMarks"]}
          rules={[
            {
              required: true,
              message: "add marks",
            },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Pass. Marks</div>,
      width: "10%",
      dataIndex: "passingMarks",
      // width:"40%",
      key: "passingMarks",
      render: (_: any, record: any, index: number) => (
        <>
          <Form.Item
            name={[index, "passingMarks"]}
            style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
            rules={[
              {
                required: true,
                message: "add pass marks",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Date</div>,
      width: "15%",
      dataIndex: "date",
      key: "date",
      render: (_: any, __: any, index: number) => (
        <Form.Item
          style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
          name={[index, "date"]}
          rules={[
            {
              required: true,
              message: "select date",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }}  format={"DD/MM/YYYY"}/>
        </Form.Item>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Time</div>,
      dataIndex: "time",
      width: "15%",
      key: "time",
      render: (_: any, __: any, index: number) => (
        <Form.Item
          style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
          name={[index, "time"]}
          rules={[
            {
              required: true,
              message: "select time",
            },
          ]}
        >
          {/* <TimePicker style={{ width: "90%" }} /> */}
          <RangePicker format={"HH:mm"} placeholder={["from", "to"]} />
        </Form.Item>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      width: "2%",
      key: "",
      render: (_: any, __: any, index: number) => {
        const assessmentDetails = form.getFieldValue("assessmentDetails") || [];
        return(
        <Form.Item
          style={{ marginBottom: "-1px", padding: "5px 0px 5px 0px" }}
          // name={[index, "last"]}
        >
          {assessmentDetails.length > 1 && (
            <DeleteOutlined
              className="cursor deleteIconColour"
              onClick={(e: any) => removeField(index)}
            />
          )}
        </Form.Item>
      )},
    },
  ];

  const addField = () => {
    const assessmentDetails = form.getFieldValue("assessmentDetails") || [];
    form.setFieldsValue({
      assessmentDetails: [
        ...assessmentDetails,
        { maxMarks: "", passingMarks: "", date: "", time: "" },
      ],
    });
  };

  const removeField = (index: number) => {
    const assessmentDetails = form.getFieldValue("assessmentDetails") || [];
    assessmentDetails.splice(index, 1);
    form.setFieldsValue({
      assessmentDetails,
    });
  };

  const getDesignCourseData = async (formValues: any) => {
    try {
      setLoading(true);
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.GET_SUBJECT_ALLOCATION_MASTER_DETAILS + "?",
        {},
        {
          departMentId: formValues?.department?.value || "",
          courseId: formValues?.course?.value || "",
          batchId: formValues?.batch?.value || "",
          semesterId: formValues?.semester?.value || "",
          sectionId: formValues?.section?.value || "",
        }
      );
      if (res?.result) {
        // console.log(res?.result, "resresult");
        let departmentData = res?.result?.departments?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setDepartMentData(departmentData);
        // console.log(departmentData?.value, "departmentdata");
        let courseData = res?.result?.courses?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setCourseData(courseData);

        let batchData = res?.result?.batches?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setBatchData(batchData);
        let semesterData = res?.result?.semesters?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSemesterData(semesterData);
        let sectionsData = res?.result?.sections?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSectionsData(sectionsData);
        let subjectsData = res?.result?.subjects?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSubjectsData(subjectsData);
      }
    } catch (error) {
      console.error("something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const formValues = form.getFieldsValue();
    getDesignCourseData(formValues);
  }, [trigger]);

  return (
    <div>
      <Drawer
        title="Add Assessment"
        open={props.open}
        // placement="right"
        // maskClosable={false}
        // closeIcon={false}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={() => props.onClose()}
          />
        }
        onClose={() => props.onClose()}
        destroyOnClose
        width={980}
        size="large"
        footer={
          <Row>
            <Button
              loading={buttonLoading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: 10 }}
              onClick={() => {
                if (props.assessmentsId) {
                  setConfirmation(true);
                } else {
                  handleSubmit();
                }
              }}
            >
              {props.assessmentsId ? "Update" : "Save"}
            </Button>
            <Button onClick={() => props.onClose()}>Cancel</Button>
          </Row>
        }
      >
        {confirmation && (
          <ConfirmationModel
            open={confirmation}
            text={`Are you sure you want to update this record, this will clear the marks of the student?`}
            onOk={async () => {
              handleSubmit()
              setConfirmation(false);
            }}
            onCancel={() => {
              setConfirmation(false);
            }}
            parentLoading={loading}
          />
        )}
        <Row style={{ marginLeft: 20, marginTop: 20 }}>
          <Col span={23}>
            {/* <Form form={form} layout="vertical"> */}
            <Form form={form}>
              <div
                style={
                  {
                    // maxHeight: `calc(100vh - 115px)` ,
                    // overflowY: "auto",
                  }
                }
              >
                <Row gutter={[20, 0]}>
                  <Col sm={12} lg={9} xs={24}>
                    <Form.Item
                      label="Category"
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: "Please select Category!",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Choose Category"
                        optionFilterProp="children"
                        labelInValue
                        allowClear
                        popupMatchSelectWidth={true}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={AssessmentCategory}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12} lg={15} xs={24}>
                    <Form.Item
                      name="assessmentName"
                      label="Assessment Name"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input Assessments Name!",
                      //   },
                      // {
                      //   whitespace: true,
                      //   message: "Invalid name",
                      // },
                      // ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Enter Name"
                        onInput={(e: any) => {
                          e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase();
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Form.Item
                    name={"isCountOverallScore"}
                    label="Counts towards Overall Score : "
                    rules={[
                      {
                        required: true,
                        message: "Please Select Field!",
                      },
                    ]}
                  >
                    <Radio.Group name="ScoreGroup" style={{ marginTop: 5 }}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {/* <Typography.Text
                  style={{ marginRight: 20, marginBottom: 24, fontSize: 15 }}
                >
                  Counts towards Overall Score:
                </Typography.Text> */}
                </Row>
                <Row gutter={18}>
                  <Col xs={24} sm={24} md={12} lg={9}>
                    <Form.Item
                      name={"department"}
                      rules={[{ required: true, message: "select department" }]}
                      label="Department"
                    >
                      <Select
                        style={{ width: "100%" }}
                        labelInValue={true}
                        options={departMentData}
                        // onChange={(e: any) => setTrigger((prev) => prev + 1)}
                        onChange={(value) => handleFieldChange("department", value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      name={"course"}
                      rules={[{ required: true, message: "select course" }]}
                      label="Course"
                    >
                      <Select
                        style={{ width: "100%" }}
                        labelInValue={true}
                        options={courseData}
                        // onChange={(e: any) => setTrigger((prev) => prev + 1)}
                        onChange={(value) => handleFieldChange("course", value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={7}>
                    <Form.Item
                      name={"batch"}
                      rules={[{ required: true, message: "select batch" }]}
                      label="Batch"
                    >
                      <Select
                        style={{ width: "100%" }}
                        labelInValue={true}
                        options={batchData}
                        // onChange={(e: any) => setTrigger((prev) => prev + 1)}
                        onChange={(value) => handleFieldChange("batch", value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={9}>
                    <Form.Item
                      name={"semester"}
                      rules={[{ required: true, message: "select semester" }]}
                      label="Semester"
                    >
                      <Select
                        style={{ width: "100%" }}
                        labelInValue={true}
                        options={semesterData}
                        onChange={(value) => handleFieldChange("semester", value)}
                        // onChange={(e: any) => setTrigger((prev) => prev + 1)}
                        />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      name={"section"}
                      rules={[{ required: true, message: "select section" }]}
                      label="Section"
                      >
                      <Select
                        style={{ width: "100%" }}
                        labelInValue={true}
                        options={sectionsData}
                        onChange={(value) => handleFieldChange("section", value)}
                        // onChange={(e: any) => setTrigger((prev) => prev + 1)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.List name="assessmentDetails" initialValue={[{}]}>
                      {(fields) => (
                        <>
                          <Table
                            size="small"
                            dataSource={fields}
                            columns={listColumns}
                            rowKey="key"
                            pagination={false}
                          />
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={addField}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Row
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </div>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default AddAssessments;
