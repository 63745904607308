import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Spin, Button, Col, Row, Table, Breadcrumb, message, Select, Popconfirm } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ArrowLeftOutlined, FormOutlined, LoadingOutlined, PlusOutlined, DeleteOutlined, UndoOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import MasterService from "../../../Services/MasterService";
import AddEditSource from "./AddEditSource";
import dayjs from "dayjs";
import "../.../../../../Custom.css";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { SourceMaster } from "../../../ApiUrls/URLS";
import { ActiveInactiveOptions, MasterTypes, RecordStatusType } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import { useNavigate } from "react-router";
import HTTPSCalls from "../../../Services/HTTPCalls";

interface ISource {
  key: string;
  sNo: number;
  id: string;
  name: string;
  recordStatus: string;
}
const api = new ApiCalls(SourceMaster.endPoints, SourceMaster.prefix);

const Source = () => {
  const navigate = useNavigate();
  const columns: ColumnsType<ISource> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "5%",
      render: (index: any) => <p>{index}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      showSorterTooltip: false,
      sorter: true,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setSourceId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),
    },
    // {
    //   title: "c",
    //   dataIndex: "c",
    //   showSorterTooltip: false,
    //   sorter: true,
    //   key: "c",
    //   render: (text: any) => <p>{dayjs(text).format("MMMM D, YYYY h:mm A")}</p>,

    //   // render:
    // },
    {
      title: " ",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          <FormOutlined
            className="editIconColour me-2 cursor"
            onClick={() => {
              setSourceId(record?.id);
              setOpenDrawer(true);
            }}
          />
        </Row>
      ),
    },
    {
      title: "",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          {record.recordStatus === RecordStatusType.Deleted ? (
            <UndoOutlined
              className="editIconColour me-2 cursor"
              onClick={() => UpdateRecordStatus(record.id, "restore")}
            />
          ) : (
            <Popconfirm title={"Are you sure to delete this task?"} okText={"Yes"} onConfirm={()=>UpdateRecordStatus(record.id, "delete")}>
             <DeleteOutlined
              className="deleteIconColour me-2 cursor"
              />
              </Popconfirm>
          )}
        </Row>
      ),
    },
  ];
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [sourceId, setSourceId] = useState<string | undefined>();
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [allSource, setAllSource] = useState<ISource[]>();
  const [changedStatus, setChangedStatus] = useState<string>("active");
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "Name",
    sortDir: "ascend",
  });

  useEffect(() => {
    setPageLoader(true);
    get_source(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems, changedStatus]);

  const get_source = async (start: any, length: any) => {
    await api
      .GET(SourceMaster.endPoints.getList + "?", {
        masterType: MasterTypes.Source,
        start: start,
        length: length,
        search: listParams.search,
        sortCol: listParams.sortCol,
        sortDir: listParams.sortDir,
        status: changedStatus,
      })
      .then((res: IApiResponse) => {
        if (/*res?.errors === null &&*/ res?.result) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          setAllSource(
            res?.result?.items.map((r: any, i: any) => ({
              key: i,
              sno: r?.sno,
              id: r?.id,
              name: r?.name,
              // c: r?.createdBy.date
              recordStatus: r?.recordStatus,
            }))
          );
          setPageLoader(false);
        } else {
          message.error(res?.message);
          setPageLoader(false);
        }
      });
  };

  const setListParamsAndRefresh = (value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams({ ...listParams, search: value });
  };

  const UpdateRecordStatus = async (sourceId?: string, action: string = "delete") => {
    if (!sourceId) return message.error("Source ID is required.");
    try {
      setPageLoader(true);
      await HTTPSCalls.POST(
        `${HTTPSCalls.ENDPOINTS.UPDATE_RECORD_STATUS}?objId=${sourceId}&restore=${action}`,
        {}, {}
      );
      setTrigger(!trigger); // Refresh data
    } catch (error) {
      message.error("Error occurred while updating record status.");
    } finally {
      setPageLoader(false);
    }
  };

  return (
    <>
      <AddEditSource
        setTrigger={setTrigger}
        openDrawer={openDrawer}
        sourceId={sourceId}
        SetDrawerClose={setOpenDrawer}
      />
      
      <Row justify={'space-between'} align="middle" style={{ marginBottom: "6px" }}>
      <ArrowLeftOutlined  style={{color:'#1677ff'}}  onClick={() => {
        navigate('/settings')
      }}/>
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Source",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row gutter={10}>
            <Col lg={18}>
              <Button
                type="primary"
                className="cit-add-btn mb-1"
                onClick={() => {
                  setSourceId(undefined);
                  setOpenDrawer(true);
                }}
              >
                <PlusOutlined />
                Source
              </Button>
            </Col>
            <Col lg={3}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Search status"
                optionFilterProp="label"
                onChange={(val) => {
                  setChangedStatus(val);
                  setPagingItems((prev) => ({ ...prev, currentPage: 1 })); 
                  setTrigger((prev) => !prev); 
                }}
                defaultValue={"active"}
                options={ActiveInactiveOptions}
              />

            </Col>
            <Col lg={3}>
              <Search
                size="middle"
                placeholder="Search..."
                allowClear
                className="att-search-input mb-1"
                onSearch={(val: string) => setListParamsAndRefresh(val)}
                onChange={(e: any) =>
                  e.target.value === "" ? setListParamsAndRefresh("") : null
                }
                style={{ width: 170 }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "12px" }}>
          <Table
            className="cit-table"
            loading={{
              spinning: pageLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            size="small"
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={allSource}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: ["15", "25", "50", "100"],
            }}
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                setListParams({
                  ...listParams,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey,
                });
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Source;
