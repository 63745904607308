import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  TableProps,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  useGetAssessmentByDesignCourse,
  useGetAssessmentSubject,
  useGetStudentByAssessmentSubject,
  useGetStudentMarks,
  useSaveAllSubjectMarks,
} from "../../../../Utilities/tanStackQueries";
import { useNavigate } from "react-router-dom";
// import { ApiUtility } from "../../../../Utilities/ApiUtility.axios";

function AddSingleSubjectMarks() {
  const [loading, setLoading] = useState<boolean>(false);
  const [departMentData, setDepartMentData] = useState();
  const [courseData, setCourseData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [sectionsData, setSectionsData] = useState([]);
  // const [allStudentDetail, setAllStudentDetail] = useState<any>();
  const [assessmentId, setAssessmentId] = useState<any>("");
  const [subjectId, setSubjectId] = useState<any>("");
  const [trigger, setTrigger] = React.useState<number>(0);
  //const [inputErrors, setInputErrors] = useState<{ [key: string]: string }>({});
  // const [pagingItems, setPagingItems] = useState<{
  //   totalRecords: number;
  //   currentPage: number;
  //   pageSize: number;
  // }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  //   const [start, setStart] = useState<any>(0);
  //   const [length, setLength] = useState<any>(15);
  const [form] = Form.useForm();
  const [inputForm] = Form.useForm();
  const navigate = useNavigate();
  const saveAllSubjectMarksMutation = useSaveAllSubjectMarks();
  const [presentStatus, setPresentStatus] = useState<{
    [key: string]: boolean;
  }>({});
  // const [csvLoading, setCsvLoading] = useState(false);

  // useEffect(() => {
  //   setStart((pagingItems?.currentPage - 1) * pagingItems?.pageSize);
  //   setLength(pagingItems?.pageSize);
  // }, [pagingItems]);
  // const handleDownloadCsv = async () => {
  //   try {
  //     setCsvLoading(true);
  //     await ApiUtility.downloadFile(
  //       HTTPSCalls.ENDPOINTS.DOWNLOAD_SUBJECT_MARKS_CSV + "?",
  //       {
  //         assessmentId: assessmentId,
  //       }
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setCsvLoading(false);
  //   }
  // };
  const getDesignCourseData = async (formValues: any) => {
    try {
      setLoading(true);
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.GET_SUBJECT_ALLOCATION_MASTER_DETAILS + "?",
        {},
        {
          departMentId: formValues?.department?.value || "",
          courseId: formValues?.course?.value || "",
          batchId: formValues?.batch?.value || "",
          semesterId: formValues?.semester?.value || "",
          sectionId: formValues?.section?.value || "",
        }
      );
      if (res?.result) {
        // console.log(res?.result, "resresult");
        let departmentData = res?.result?.departments?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setDepartMentData(departmentData);
        let courseData = res?.result?.courses?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setCourseData(courseData);

        let batchData = res?.result?.batches?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setBatchData(batchData);
        let semesterData = res?.result?.semesters?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSemesterData(semesterData);
        let sectionsData = res?.result?.sections?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSectionsData(sectionsData);
      }
    } catch (error) {
      console.error("something went wrong", error);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    const formValues = form.getFieldsValue();
    getDesignCourseData(formValues);
  }, [trigger]);

  const {
    data: assessmentData,
    isLoading: assessmentLoading,
    // refetch: assessmentRefetch,
  } = useGetAssessmentByDesignCourse({
    formData: form.getFieldsValue(),
  });

  const {
    data: subjectData,
    // isLoading: subjectLoading,
    // refetch: subjectRefetch,
  } = useGetAssessmentSubject({
    assessmentId,
  });

  const {
    data: studentData,
    isLoading: studentLoading,
    // refetch: studentRefetch,
  } = useGetStudentByAssessmentSubject({
    assessmentId,
    subjectId,
  });

  const {
    data: studentMarksDetail,
    isLoading: studentMarksLoading,
    refetch: studentMarksRefetch,
  } = useGetStudentMarks({
    assessmentId,
  });

  useEffect(() => {
    if (subjectId) {
      let studentVal = studentMarksDetail?.result?.filter(
        (e: any, index: any) => {
          return e?.subject?.id === subjectId;
        }
      );

      let setMarksVal = studentVal?.reduce((acc: any, e: any) => {
        e?.students?.forEach((student: any) => {
          acc[`${student.id}_${e.subject.id}`] = student?.obtainedMarks||0;
          acc[`present_${student.id}`] = student?.isPresent;
          setPresentStatus((prevStatus) => ({
            ...prevStatus,
            [student.id]: student?.isPresent ,
          }));
        });
        return acc;
      }, {});

      console.log(studentVal,"setMarksVal", setMarksVal);

      if (Object.keys(setMarksVal).length > 0) {
        console.log("set",presentStatus)
        inputForm.setFieldsValue(setMarksVal);
      } else {
        inputForm.resetFields();
        setPresentStatus({}); 
        console.log("reset",presentStatus)
      }
    }

  }, [subjectId,assessmentId]);

  const handlePresentChange = (studentId: string, isPresent: boolean) => {
    setPresentStatus((prevStatus) => ({
      ...prevStatus,
      [studentId]: isPresent,
    }));
  };

  let columns: TableProps<any>["columns"] = [
    {
      title: "Student Id",
      dataIndex: "studentId",
      key: "studentId",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      render: (text: any, record: any) => (
        <Typography.Link
        // color="#0D61FF"
        >
          {text}
        </Typography.Link>
      ),
    },
    {
      title: "Present",
      dataIndex: "present",
      key: "present",
      width: "5%",
      render: (text: any, record: any) => (
        <Form
          form={inputForm}
          style={{
            margin: 0,
            padding: 0,
            height: "100%",
          }}
        >
          <Form.Item
            name={`present_${record.id}`}
            valuePropName="checked"
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              margin: 0,
            }}
          >
            <Checkbox
              onChange={(e) => handlePresentChange(record.id, e.target.checked)}
            />
          </Form.Item>
        </Form>
      ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   align: "right",
    //   render: (_, record:any) => (
    //     <Row justify="end">
    //     {record.recordStatus === RecordStatusType.Deleted ? (
    //       <UndoOutlined
    //         className="ca-edit-btn me-2"
    //         style={{ color: "#a5abdd", cursor: "pointer" }}
    //         // onClick={() =>{
    //         //   updateStatusMution.mutate({id:record?.id, restore:"restore"},{
    //         //     onSuccess:()=>refetch()
    //         //   })}

    //         // }
    //       />
    //     ) : (
    //       <DeleteOutlined
    //         className="ca-edit-btn me-2"
    //         style={{ color: "#a5abdd", cursor: "pointer" }}
    //         // onClick={() => updateStatusMution.mutate({id:record?.id, restore:"delete"},{
    //         //   onSuccess:()=>refetch()
    //         // })}
    //       />
    //     )}
    //   </Row>
    //   ),
    // },
  ];

  if (studentData?.result && studentData.result.length > 0) {
    var subject = studentData.result?.[0]?.subject;
    const subjectColumns = {
      title: ` ${subject.name} (${studentData.result?.[0]?.maxMarks})`,
      dataIndex: subject.id,
      key: subject.id,
      render: (text: any, record: any) => (
        <>
          <Form
            form={inputForm}
            style={{
              margin: 0,
              padding: 0,
              height: "100%",
            }}
          >
            <Form.Item
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                margin: 0,
              }}
              name={`${record.id}_${subject.id}`}
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      //!value||
                      value > studentData.result?.[0]?.maxMarks
                    ) {
                      return Promise.reject(
                        new Error(
                          `Value cannot exceed Max Marks ${studentData.result?.[0]?.maxMarks}`
                        )
                      );
                    } else if (value < 0) {
                      return Promise.reject(
                        new Error(`Value should be whole number`)
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                size="small"
                disabled={!presentStatus[record.id]}
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
                onChange={(e) => {
                  inputForm.validateFields([`${record.id}_${subject.id}`]);
                }}
              />
            </Form.Item>
          </Form>
        </>
      ),
    };

    columns.push(subjectColumns);
  }

  const handleAllSubjectMarks = async () => {
    if (studentData) {
      inputForm.validateFields().then((e) => {
        let studentArray = studentData?.result?.map((student: any) => {
          const subjects = {
            subjectId: student?.subject?.id,
            subjectName: student?.subject?.name,
            maxMarks: student?.maxMarks,
            isPresent: e[`present_${student.id}`] || false,
            obtainedMarks: e[`present_${student.id}`]
              ? e[`${student.id}_${student?.subject?.id}`]
              : 0,
            studentId: student.studentId,
            id: student.id,
            name: student.name,
          };
          return subjects;
        });
        saveAllSubjectMarksMutation.mutate(
          {
            assessmentId,
            student: studentArray,
          },
          {
            onSuccess: () => {
              studentMarksRefetch();
            },
          }
        );
        // console.log(e, "Student Array:", studentArray);
      });
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    switch (field) {
      case "department":
        form.resetFields([
          "course",
          "batch",
          "section",
          "semester",
          "assessment",
          "subject",
        ]);
        break;
      case "course":
        form.resetFields([
          "batch",
          "section",
          "semester",
          "assessment",
          "subject",
        ]);
        break;
      case "batch":
        form.resetFields(["section", "semester", "assessment", "subject"]);
        break;
      case "semester":
        form.resetFields(["section", "assessment", "subject"]);
        break;
      case "section":
        form.resetFields(["assessment", "subject"]);
        break;
      case "assessment":
        form.resetFields(["subject"]);
        break;
      default:
        break;
    }
    setSubjectId("");
    setTrigger((prev) => prev + 1);
  };

  return (
    <>
      <ArrowLeftOutlined onClick={() => navigate(-1)} />
      <Card className="roundedCornerSmall mt-2">
        <Form form={form}>
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"department"}
                label="Department"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={departMentData}
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  // loading={loading}
                  // onChange={(e: any) => setTrigger((prev) => prev + 1)}
                  onChange={(value) => handleFieldChange("department", value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"course"}
                label="Course"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={courseData}
                  // loading={loading}
                  onChange={(value) => handleFieldChange("course", value)}
                  // onChange={(e: any) => setTrigger((prev) => prev + 1)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="batch"
                label="Batch"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  // loading={loading}
                  options={batchData}
                  onChange={(value) => handleFieldChange("batch", value)}
                  // onChange={(e: any) => setTrigger((prev) => prev + 1)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="semester"
                label="Semester"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={semesterData}
                  // loading={loading}
                  onChange={(value) => handleFieldChange("semester", value)}
                  // onChange={(e: any) => setTrigger((prev) => prev + 1)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="section"
                label="Section"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={sectionsData}
                  // loading={loading}
                  onChange={(value) => handleFieldChange("section", value)}
                  // onChange={(e: any) => setTrigger((prev) => prev + 1)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"assessment"}
                label="Assessment"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                    ?.toLowerCase()
                    .includes(input?.trim().toLowerCase())
                  }
                  onChange={(value) => handleFieldChange("assessment", value)}
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={assessmentData}
                  loading={assessmentLoading}
                  onSelect={(val: any) => {
                    // console.log("assessmentSelect", val);
                    setAssessmentId(val.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"subject"}
                label="Subject"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={subjectData}
                  loading={assessmentLoading}
                  onSelect={(val: any) => {
                    // console.log("assessmentSelect",val)
                    setSubjectId(val.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col span={24}>
            <Table
              size="small"
              columns={columns}
              dataSource={studentData?.result}
              style={{ marginTop: 14 }}
              footer={() => (
                <Row justify={"end"}>
                  <Col>
                    <Button
                      type="primary"
                      onClick={handleAllSubjectMarks}
                      disabled={studentData?.result?.length > 0 ? false : true}
                      loading={saveAllSubjectMarksMutation.isPending}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              )}
              loading={{
                spinning: studentLoading || studentMarksLoading,
                indicator: <Spin indicator={<LoadingOutlined />} />,
              }}
              pagination={false}
              // pagination={{
              //   showTotal: (total, range) =>
              //     `${range[0]}-${range[1]} of ${total} items`,
              //   selectPrefixCls: "custom-table-select",
              //   current: pagingItems.currentPage,
              //   pageSize: pagingItems.pageSize,
              //   showSizeChanger: true,
              //   total: pagingItems.totalRecords,
              //   pageSizeOptions: ["15", "25", "50", "100"],
              // }}
              // onChange={(paging, filter, sort: any) => {
              //   paging &&
              //     setPagingItems({
              //       ...pagingItems,
              //       currentPage: paging?.current ?? 1,
              //       pageSize: paging?.pageSize ?? 15,
              //     });
              // }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default AddSingleSubjectMarks;
