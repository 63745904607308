import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Input,
  Button,
  Space,
  Checkbox,
  Typography,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import type { CheckboxProps } from "antd";
import { MasterTypes } from "../../../../Constant/Enums";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { IApiResponse } from "../../../../Utilities/ApiUtility.axios";
import { adminContext } from "../../../../Common/PageRoute";

interface I_Props {
  IsGradeModel: boolean;
  setIsGradeModel: Function;
  gradeId: any;
  setGradeId:Function;
  refetch: Function;
}

const AddGrade: React.FC<I_Props> = (props) => {
  const { openNotification } = React.useContext(adminContext);
  const [form] = Form.useForm();
  const [isGPA, setIsGPA] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);

  const saveUpdateAssementsCategory = (value: any) => {
    setBtnLoading(true);
    let gradeDetail = {
      masterType: MasterTypes.GradeScale,
      ...(props?.gradeId?.id && { id: props?.gradeId?.id}),
      name: value?.gradeName,
      gradesValues: value?.grades,
    };
    HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.POST_MASTER,
      {},
      {
        ...gradeDetail,
      }
    ).then((res: IApiResponse) => {
      console.log("Post res = ", res);
      if (res?.status) {
        // onClose();
        openNotification(
          "success",
          props.gradeId
            ? "Grade updated successfully"
            : "Grade saved successfully"
        );
        setBtnLoading(false);
        props.setIsGradeModel(false);
        // setCategoryId(null);
        form.resetFields();
        props.refetch();
      } else {
        openNotification("error", res.message);
        setBtnLoading(false);
      }
    });
    console.log(gradeDetail, "Grade values");
  };

  useEffect(() => {
    if (props.gradeId) {
      form.setFieldsValue({
        "gradeName":props?.gradeId?.name,
        "grades": props?.gradeId?.gradesValues,
      });
      props?.gradeId?.gradesValues?.forEach((items:any)=>{
        if(items.gpa!==null && items.gpa!==undefined)
        {
          setIsGPA(true)
        }
        else{
          setIsGPA(false)
        }
      })
    }
    console.log(props.gradeId,"gradeId");
    
  }, [props.gradeId]);

  const handleAdd = () => {
    const values = form.getFieldsValue();
    const nextGrades = [...(values.grades || []), {}];
    form.setFieldsValue({ grades: nextGrades });
  };

  const handleRemove = (index: any) => {
    const values = form.getFieldsValue();
    const nextGrades = values.grades.filter((_: any, i: any) => i !== index);
    form.setFieldsValue({ grades: nextGrades });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        
        saveUpdateAssementsCategory(values);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleCancel = () => {
    props.setIsGradeModel(false);
    form.resetFields()
props.setGradeId(null)
    // setIsGPA(!isGPA);
    
  };

  const onCheckBoxChange: CheckboxProps["onChange"] = (e) => {
    setIsGPA(e.target.checked);
  };

  return (
    <Modal
      title="Add Grading Scale"
      open={props.IsGradeModel}
      destroyOnClose
      onCancel={handleCancel}
      footer={
        <Form.Item>
          <Row justify="start" style={{ marginTop: 20 }}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
                loading={btnLoading}
              >
                {props.gradeId ? "Update" : "Save"}
              </Button>
              <Button
                type="default"
                onClick={handleCancel}
                style={{ marginLeft: 10 }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Item>
      }
      width={"585px"}
      styles={{
        body: {
          maxHeight: 480,
          overflowY: "auto",
        },
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        initialValues={{
          grades: [
            { min: "", max: "", grades: "" },
            { min: "", max: "", grades: "" },
          ],
        }}
      >
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <Form.Item
              label="Grade Name"
              name="gradeName"
              rules={[
                {
                  required: true,
                  message: "Please Enter Grade Scale Name!",
                },
              ]}
            >
              <Input placeholder="Enter Grade Scale Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
            >
              <Checkbox onChange={onCheckBoxChange} checked={isGPA}>Set GPA</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Form.List name="grades">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "min"]}
                      label={index === 0 ? "Min%" : ""}
                      rules={[{ required: true, message: "Missing min" }]}
                    >
                      <Input
                        style={{ width: "80px" }}
                        placeholder="Min (%)"
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                      />
                    </Form.Item>
                    <Form.Item label={index === 0 ? " " : ""}>
                      <Typography.Text>To</Typography.Text>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ width: "80px" }}
                      name={[name, "max"]}
                      label={index === 0 ? "Max%" : ""}
                      rules={[
                        { required: true, message: "Missing max" },
                        {
                          validator(rule: any, val: any, callback) {
                            if (val > 100) {
                              callback("less than and equal to 100")
                            }
                            else{
                              callback()
                            }
                          }
                        }
                      ]}
                    >
                      <Input
                        style={{ width: "80px" }}
                        placeholder="Max (%)"
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                      />
                    </Form.Item>
                    <Form.Item label={index === 0 ? " " : ""}>
                      <Typography.Text>=</Typography.Text>
                    </Form.Item>
                    {isGPA && (
                      <Form.Item
                        {...restField}
                        name={[name, "gpa"]}
                        label={index === 0 ? "GPA" : ""}
                        rules={[{ required: true, message: "Missing GPA" }]}
                      >
                        <Input
                          placeholder="GPA"
                          style={
                            index > 0
                              ? { width: "100px", marginLeft: 7 }
                              : { width: "100px" }
                          }
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      {...restField}
                      name={[name, "grades"]}
                      label={index === 0 ? "Status" : ""}
                      rules={[{ required: true, message: "Missing grade" }]}
                    >
                      <Input
                        placeholder="Grade"
                        style={
                          index > 0 && isGPA === false
                            ? { width: "100px", marginLeft: 7 }
                            : { width: "100px" }
                        }
                      />
                    </Form.Item>

                    {index > 1 && (
                      <DeleteOutlined className="cursor deleteIconColour" onClick={() => handleRemove(index)} />
                    )}
                  </Space>
                ))}
              </>
            )}
          </Form.List>
        </Row>
        <Row justify={"end"}>
          <Form.Item>
            <Button
              type="primary"
              onClick={handleAdd}
              block
              icon={<PlusOutlined />}
            >
              Add field
            </Button>
          </Form.Item>
        </Row>

        {/* <Row justify="start" style={{ marginTop: 20 }}>
          <Col>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              type="default"
              onClick={handleCancel}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </Button>
          </Col>
        </Row> */}
      </Form>
    </Modal>
  );
};

export default AddGrade;
