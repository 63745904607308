import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  message,
  Select,
  Col,
} from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { DocumentsMaster } from "../../../ApiUrls/URLS";
import {
  AllowDocumentsFor,
  MasterTypes,
  ProcessStepsEnum,
  ProcessStepsOptions,
} from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";

interface AddEditDocumentIn {
  setTrigger: any;
  openDrawer: any;
  documentId: string | undefined;
  SetDrawerClose: any;
}
const api = new ApiCalls(DocumentsMaster.endPoints, DocumentsMaster.prefix);
const AddEditDocument = (props: AddEditDocumentIn) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [mandatoryDocumentsOption, setMandatoryDocumentsOption] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedProcessStep, setSelectedProcessStep] =
    useState<ProcessStepsEnum>();
  const [allowedDocuments, setAllowedDocuments] = useState<any>();
  // const formItemLayout = {
  //   labelCol: {
  //     xs: { span: 24 },
  //     sm: { span: 8 },
  //     lg: { span: 6 },
  //   },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 16 },
  //     lg: { span: 24 },
  //   },
  // };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 }, // Full-width label on extra small screens
      sm: { span: 8 },  // Label takes 8 grid units on small screens
      lg: { span: 6 },  // Label takes 6 grid units on large screens
    },
    wrapperCol: {
      xs: { span: 24 }, // Full-width input on extra small screens
      sm: { span: 16 }, // Input takes 16 grid units on small screens
      lg: { span: 18 }, // Input takes 18 grid units on large screens
    },
  };
  const saveData = () => {
    console.log(form.getFieldsValue());
     
     form.validateFields().then(async (values: any) => {
    
      
      setBtnLoading(true);
      let val = {
        ...values,
        masterType: MasterTypes.Documents,
        id: props?.documentId,
        name: values?.name,
        selectedProcessStep: selectedProcessStep,
        allowDocumentsFor: values?.allowDocumentsFor,
      };
      await api
        .POST(DocumentsMaster.endPoints.save, null, val)
        .then((res: any) => {
          if (res.status) {
            onClose();
            openNotification(
              "success",
              props?.documentId
                ? "Document updated successfully"
                : "Document saved successfully"
            );
            props?.setTrigger((x: boolean) => !x);
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
    });
  };

  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.documentId) {
        setLoading(true);
        getDocumentById(props?.documentId);
      }
    }
  }, [props?.openDrawer]);

  const getDocumentById = async (id: any) => {
    setLoading(true);
    await api
      .GET(DocumentsMaster.endPoints.getById + "/" + id + "?", {
        masterType: MasterTypes.Documents,
      })
      .then((data: IApiResponse) => {
        if (data?.result) {
          let res = data?.result;
          // console.log(res?.,"resdataaaaa");

          form.setFieldsValue({
            name: res?.name,
            allowDocumentsFor: res?.allowDocumentsFor,
          });
          if(res?.selectedProcessStep!==0){
            form.setFieldValue("selectedProcessStep",res?.selectedProcessStep);
            setSelectedProcessStep(res?.selectedProcessStep);
          }
          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };

  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
  };

  return (
    <>
      <Drawer
        title={props?.documentId ? "Edit Document" : "Add Document"}
        placement="right"
        width={600}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.documentId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="document-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            size="middle"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
            labelCol={{ xs: { span: 24 }, sm: { span: 8 } }} 
            wrapperCol={{ xs: { span: 24 }, sm: { span: 16 } }} 
          >
            
            <Form.Item
              label="Mandatory Document Type"
              name="selectedProcessStep"
              rules={[
                // {
                //   required: true,
                //   message: "Please enter Mandatory Document name!",
                // },
              ]}
            >

              {/* <Row>
                <Col span={24}> */}
                  <Select
                    placeholder="Select Mandatory Document"
                    options={ProcessStepsOptions}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        .includes(input?.trim().toLowerCase())
                    }
                    onChange={(e) => setSelectedProcessStep(e)}
                    value={selectedProcessStep}
                    style={{ width: "100%" }}
                  />
                {/* </Col>
              </Row> */}
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please enter document name!" },
              ]}
            >
              {/* <Row>
                <Col span={24}> */}
                  <Input
                    autoFocus
                    placeholder="Enter Document Name"
                    // size="middle"
                    onInput={(e: any) =>
                      (e.target.value =
                        e.target.value.length > 1
                          ? e.target.value
                          : e.target.value.toUpperCase())
                    }
                  />
                {/* </Col>
              </Row> */}
            </Form.Item>
            <Form.Item
              label="Allow Documents For"
              name="allowDocumentsFor"
              // rules={[
              //   { required: true, message: "Please select this option!" },
              // ]}
            >
              {/* <Row>
                <Col span={24}> */}
                  <Select
                  allowClear
                    // mode="multiple"
                    // onChange={(val: any) => setAllowedDocuments(val)}
                    // labelInValue={true}
                    options={[
                      {
                        label: "For Admin",
                        value: AllowDocumentsFor.ForAdmin,
                      },
                      {
                        label: "Allow For Student too",
                        value: AllowDocumentsFor.ForStudnetAndAdmin,
                      },
                      // { label: "Allow For Web", value: "Web" },
                    ]}
                    // value={allowedDocuments}
                  />
                {/* </Col>
              </Row> */}
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditDocument;
