import React from "react";
import { Upload, Button, Modal, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { RcFile } from "antd/es/upload/interface";
import ImgCrop from "antd-img-crop";
import HTTPSCalls from "../../Services/HTTPCalls";
interface IViewStudentDetails {
  apiData: any;
  afterUpload?: () => void;
}
const UploadProfile = (props:IViewStudentDetails) => {
  const { apiData } = props;
  const [attachment, setAttachment] = React.useState<any>(null);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [loading,setIsLoading]=React.useState(false);
  const handelFile = async (file: RcFile) => {
    const currentFileBase64 = await getBase64(file as RcFile);

    const img = {
      id: "",
      name: file?.name || "",
      contentType: file?.type || "",
      length: file?.size || "",
      path: currentFileBase64,
    };

    setAttachment(img);
    setIsModalVisible(true); // Show the modal
    return false;
  };
  const getBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  const handleOk =async ()  => {
    try{
      setIsLoading(true);
      console.log("Image details confirmed:", attachment);
      await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.STUDENTS_UPLOAD_PROFILE+"?",
        {enquiryId:apiData?.id},
        {
          id:attachment?.id,
          name:attachment?.name,
          contentType:attachment?.contentType,
          length:attachment?.length,
          path:attachment?.path,
        }
      ).then((res:any)=>{
        if(res?.status){
          setIsModalVisible(false);
          props?.afterUpload && props.afterUpload(); 
          message.success("Successfully uploaded")
        }
        else{
          message.error("there is a problem while uploading!")
        }
      })

    }
    catch(error){
      console.log(error,"Something went wrong")
   }
   finally{
     setIsModalVisible(false);
   }
  };

  const handleCancel = () => {
    console.log("Upload canceled");
    // setAttachment(null);
    setIsModalVisible(false);
  };

  return (
    <>
      <ImgCrop rotationSlider>
        <Upload
          showUploadList={false}
          beforeUpload={handelFile}
          accept="image/*"
        >
          <Button shape="circle" style={{marginTop:"80px",marginLeft:"-25px"}} icon={<UploadOutlined />}></Button>
        </Upload>
      </ImgCrop>
      <Modal 
        confirmLoading={loading}
        title="Are you sure you want to upload?"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* {attachment && (
          <div>
            <img
              src={attachment.path}
              alt={attachment.name}
              style={{ width: "100%", maxHeight: "200px", objectFit: "contain" }}
            />
          </div>
        )} */}
      </Modal>
    </>
  );
};

export default UploadProfile;
