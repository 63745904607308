import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  TimePicker,
  Checkbox,
  Col,
  message,
  Select,
  Tooltip,
  Card,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import Table, { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { MasterTypes } from "../../../Constant/Enums";
import { BatchMaster } from "../../../ApiUrls/URLS";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { useQuery, useMutation } from "@tanstack/react-query";
import { transpileModule } from "typescript";
import Item from "antd/es/list/Item";
import Course from "../Course/Course";
import Department from "../Department/Department";
import moment from "moment";
let timeout: any;
const api = new ApiCalls(BatchMaster.endPoints, BatchMaster.prefix);
interface IDay {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
}

interface IBatchType {
  key: string;
  checkbox: boolean;
}
const AddEditShift: React.FC<{
  setTrigger: Function;
  SetDrawerClose: Function;
  openDrawer: boolean;
  recordData?: any;
  setRecordData?: any;
}> = (props) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const batchDaysData = [
    {
      key: "1",
      checkbox: false,
    },
  ];
  const columnsNonBatch: ColumnsType<IBatchType> = [
    {
      title: "Mon",
      dataIndex: "mon",
      key: "1",

      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.mon}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, mon: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Tue",
      dataIndex: "tue",
      key: "2",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.tue}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, tue: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Wed",
      dataIndex: "wed",
      key: "3",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.wed}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, wed: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Thu",
      dataIndex: "thu",
      key: "4",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.thu}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, thu: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Fri",
      dataIndex: "fri",
      key: "5",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.fri}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, fri: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sat",
      dataIndex: "sat",
      key: "6",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.sat}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, sat: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sun",
      dataIndex: "sun",
      key: "7",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.sun}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, sun: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
  ];
  const [departmentData, setDepartmentData]: any = useState([]);
  const [lunchMinInput, setLunchMinInput] = useState<any>({
    lunchMin: 0,
    isLMCalc: false,
  });
  const [currentSchema, setCurrentSchema]: any = useState();
  const [overallHours, setOverAllHours] = useState("");
  const [workingHours,setWorkingHours]=useState<any>(0);
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const { setTrigger, recordData, SetDrawerClose, openDrawer, setRecordData } =
    props;
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [batchDays, setBatchDays] = useState<IDay>({
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });

  const onClose = () => {
    SetDrawerClose(false);
    form.resetFields();
  };

  const saveUpdateShiftDetail = () => {
    let weekdays = [
      { day: 1, isChecked: batchDays.sun },
      { day: 2, isChecked: batchDays.mon },
      { day: 3, isChecked: batchDays.tue },
      { day: 4, isChecked: batchDays.wed },
      { day: 5, isChecked: batchDays.thu },
      { day: 6, isChecked: batchDays.fri },
      { day: 7, isChecked: batchDays.sat },
    ];
    let checkDays = weekdays.some((item: any) => item.isChecked === true);
    if (!checkDays) {
      openNotification("error", "Please select Work days");
      form.validateFields();
    } else {
      form.validateFields().then(async (val) => {
        setBtnLoading(true);
        let ShiftDetail = {
          masterType: MasterTypes.Shift,
          ...(recordData?.id && { id: recordData?.id }),
          ...val,
          inTime: val?.inTime?.format("HH:mm"),
          outTime: val?.outTime?.format("HH:mm"),
          batchDays: weekdays,
          isLunchCal: lunchMinInput?.isLMCalc,
          lunchTime: lunchMinInput?.lunchMin,
        };

        HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.POST_MASTER,
          {},
          {
            ...ShiftDetail,
          }
        ).then((res: IApiResponse) => {
          console.log("Post res = ", res);
          if (res?.status) {
            onClose();
            openNotification(
              "success",
              recordData?.id
                ? "Shift updated successfully"
                : "Shift saved successfully"
            );
            setTrigger((x: boolean) => !x);
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
        console.log(ShiftDetail, "BatchValues");
      });
    }
  };

  useEffect(() => {
    if (recordData?.id) {
      setBatchDays({
        sun: recordData?.batchDays?.some((d: any) => {
          return d?.day === 1 && d?.isChecked;
        }),
        mon: recordData?.batchDays?.some((d: any) => {
          return d?.day === 2 && d?.isChecked;
        }),
        tue: recordData?.batchDays?.some((d: any) => {
          return d?.day === 3 && d?.isChecked;
        }),
        wed: recordData?.batchDays?.some((d: any) => {
          return d?.day === 4 && d?.isChecked;
        }),
        thu: recordData?.batchDays?.some((d: any) => {
          return d?.day === 5 && d?.isChecked;
        }),
        fri: recordData?.batchDays?.some((d: any) => {
          return d?.day === 6 && d?.isChecked;
        }),
        sat: recordData?.batchDays?.some((d: any) => {
          return d?.day === 7 && d?.isChecked;
        }),
      });
      setLunchMinInput({
        lunchMin: recordData?.lunchTime,
        isLMCalc: recordData?.isLunchCal,
      });
      form.setFieldsValue({
        name: recordData?.name,
        inTime: dayjs(recordData?.inTime, "HH:mm"),
        outTime: dayjs(recordData?.outTime, "HH:mm"),
        lunchTime: recordData?.lunchTime,
      });
    }
  }, [recordData]);

  const calculateWorkingHours = (inTime: any, outTime: any, lunchMinutes: number = 0) => {
    if (inTime && outTime) {
      const duration = moment.duration(outTime.diff(inTime));
      let totalMinutes = duration.asMinutes() - lunchMinutes;
      if (totalMinutes < 0) totalMinutes = 0; 
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours} hrs ${minutes}`;
    }
    return "";
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    const { inTime, outTime } = allValues;
    var lunch = form.getFieldValue("lunchTime");  
    if (inTime && outTime) {
      const overallHours= calculateWorkingHours(inTime,outTime);
      const workingHours = calculateWorkingHours(inTime, outTime, lunch);
      setOverAllHours(overallHours);
      setWorkingHours(workingHours)
    } else {
      setOverAllHours("");
    }
  };
  
  return (
    <Drawer
      title={props?.recordData?.id ? "Edit Shift" : "Add Shift"}
      placement="right"
      width={500}
      maskClosable={false}
      onClose={onClose}
      closeIcon={false}
      open={openDrawer}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          className="cusror"
          onClick={onClose}
        />
      }
      footer={
        <Row justify="end">
          <Button
            onClick={onClose}
            disabled={btnLoading}
            className="ttp-btn-light btn-m-sm"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="btn-m-sm"
            loading={btnLoading}
            onClick={saveUpdateShiftDetail}
            // onClick={()=>Mutation.mutate()}
          >
            {props?.recordData?.id ? "Update" : "Save"}
          </Button>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
        style={{ height: "100%" }}
      >
        <Form
          form={form}
          name="batch-form"
          className="DrawerPadding"
          colon={false}
          onValuesChange={handleFormChange}
          autoComplete="off"
          style={{ padding: 16 }}
          {...formItemLayout}
          labelAlign="left"
          requiredMark={false}
          // initialValues={{ schema: currentSchema }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter Shift Name!" },
                ]}
              >
                <Input
                  style={{ width: "80%" }}
                  placeholder="Enter shift name"
                  size="middle"
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="In Time"
                name="inTime"
                rules={[
                  { required: true, message: "Please select time!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const outTime = getFieldValue("outTime");
                      if (!value || !outTime || value.isBefore(outTime)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "In Time should not be greater than Out Time!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <TimePicker format="h:mm:A" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Out Time"
                name="outTime"
                rules={[
                  { required: true, message: "Please select time!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const inTime = getFieldValue("inTime");
                      if (!value || !inTime || inTime.isBefore(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Out Time should be greater than In Time!")
                      );
                    },
                  }),
                ]}
              >
                <TimePicker format="h:mm:A" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="fw500">Work Days</Col>
            <Col span={24} className="mt-1 CitTable">
              <Table
                bordered={true}
                columns={columnsNonBatch}
                pagination={{ hideOnSinglePage: true }}
                dataSource={batchDaysData}
                size={"small"}
                scroll={{ x: 400 }}
              />
            </Col>
          </Row>
          <Form.Item label="Lunch Time" name="lunchTime">
            <Input
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              suffix={
                <>
                  {" "}
                  |{" "}
                  <Checkbox
                    checked={lunchMinInput?.isLMCalc}
                    className="ms-2"
                    onChange={(e: any) =>
                      setLunchMinInput({
                        ...lunchMinInput,
                        isLMCalc: e.target.checked,
                      })
                    }
                  >
                    Calculation in Lunch Min
                  </Checkbox>
                  <Tooltip
                    color="#e6f4ff"
                    placement="topLeft"
                    overlayInnerStyle={{ fontSize: 13, color: "black" }}
                    title={
                      <Row>
                        <Col span={24} className="fs13">
                          {" "}
                          <CheckOutlined
                            style={{ fontSize: 13, color: "green" }}
                            className="me-2"
                          />
                          Tick checkbox (The lunch break duration will be
                          deducted, regardless of whether they use it or not.)
                        </Col>
                        <Col span={24} className="fs13">
                          {" "}
                          <CloseOutlined
                            style={{ fontSize: 13, color: "red" }}
                            className="me-2"
                          />
                          Untick checkbox (Deduct only the time that employees
                          actually spend on their lunch break.)
                        </Col>
                      </Row>
                    }
                  >
                    <InfoCircleOutlined
                      style={{ color: "#a5abdd" }}
                      className="cursor"
                    />
                  </Tooltip>
                </>
              }
              // disabled={disabled}
              value={lunchMinInput?.lunchMin}
              maxLength={3}
              className="borderLessInput"
              onChange={(e: any) => {
                setLunchMinInput({
                  ...lunchMinInput,
                  lunchMin: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Form>
        <Card
          style={{ backgroundColor: "#fbfcff", margin: "60px 40px 0px 40px" }}
          bodyStyle={{ padding: 20 }}
          title={
            <p style={{ color: "#1890ff" }}>
              Time Schedule Summary <HistoryOutlined />
            </p>
          }
          size="small"
        >
          <Row
            className="fw500 p-2 roundedCornerSmall"
            style={{ border: "1px solid #e4e7ef", backgroundColor: "#fff" }}
          >
            <Col span={24}>
              <Row className="ms-3 me-4 mb-2">
                <Col span={12} style={{ color: "#00000073" }}>
                  Working Hours
                </Col>
                <Col span={12}> {workingHours || 0}mins </Col>
                {/* <Col span={12}>{getWorkingDuration(times?.in, times?.out, lunchMinInput?.lunchMin)} Hrs</Col> */}
              </Row>
              <Row className="ms-3 me-4 mb-2">
                <Col span={12} style={{ color: "#00000073" }}>
                  Lunch Minutes
                </Col>
                <Col className="border-bottom">
                  {lunchMinInput?.lunchMin ?? 0} mins
                </Col>
              </Row>
              <Row className="ms-3 me-4 mb-2">
                <Col span={12} style={{ color: "#00000073" }}>
                  Overall Hours
                </Col>
                <Col span={12}> {overallHours || 0} mins </Col>
                {/* <Col span={12} >{getOverallDuration(times?.in, times?.out)} Hrs</Col> */}
              </Row>
            </Col>
          </Row>
        </Card>
      </Spin>
    </Drawer>
  );
};
export default AddEditShift;
