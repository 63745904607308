import React, { useEffect, useState } from "react";
import { Col, Modal, Pagination, Row, Table } from "antd";
import HTTPSCalls from "../../Services/HTTPCalls";
import { ColumnsType } from "antd/es/table/interface";
import { pageSizeOption } from "../LMS/SubjectAlllocation/SubjectAllocation";
import { CandidateRequestType, CenterVisited } from "../../Constant/Enums";
// import { useSelector } from "react-redux";
// import { selectBatchState } from "../../Store/Slice/batchSlice";
import dayjs from "dayjs";

const EnquiryViewModal = (props: any) => {
  const { openEnquiryModal, handleCancelEnquiry, sendCandidateReqType } = props;
  const [viewEnquiryData, setViewEnquiryData] = useState<any>([]);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [modelLoading, setModelLoading] = useState<boolean>(false);

  // const [listParams, setListParams] = useState({
  //   streamSearch: "",
  //   batchSearch: "",
  //   start: 0,
  //   length: 15,
  //   search: "",
  //   sortCol: "Student.Name",
  //   sortDir: "ascend",
  //   feesType: "",
  // });
  // const batchState = useSelector(selectBatchState);
  const getEnquiries = async () => {
    try {
      setModelLoading(true);
      let res = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.ENQUIRY_GETALL_INFO + "?",
        {
          // streamSearch: listParams?.streamSearch,
          // batchSearch: listParams?.batchSearch,
          // batchSearch: batchState,
          start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
          length: pagingItems?.pageSize,
          // sortCol: listParams.sortCol,
          // sortDir: listParams?.sortDir,
          // search: listParams?.search,
          type: sendCandidateReqType,
          ...(sendCandidateReqType === CandidateRequestType.Student && {
            exists: 1,
          }),
        }
      );
      if (res?.result?.items.length >= 0) {
        const result = res?.result?.items || [];
        setViewEnquiryData(() =>
          result.map((itm: any, index: number) => {
            return {
              sno: itm?.sno,
              key: index,
              student: itm?.student,
              id: itm?.id,
              fullName: itm?.fullName || itm?.name,
              currentClass: itm?.currentClass?.name,
              centerVisited: CenterVisited?.[itm?.centerVisited] || "Unknown",
              batch: itm?.batch?.name,
              contactDetail: itm?.contactDetail?.mobileNumber,
            };
          })
        );
        setPagingItems((p) => {
          p.totalRecords = res?.result?.totalRecords||0;
          return p;
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setModelLoading(false);
    }
  };

  useEffect(() => {
    openEnquiryModal && getEnquiries();
  }, [pagingItems, openEnquiryModal]);

  const columns: ColumnsType<any> = [
    {
      title: "S No",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "fullName",
      ellipsis: true,
      key: "name",
      width: "25%",
      render: (fullName: any) => (
        <span style={{ cursor: "pointer" }}>{fullName}</span>
      ),
    },
    {
      title: "Current Class",
      dataIndex: "currentClass",
      key: "currentClass",
      render: (currentClass: any) => <span>{currentClass}</span>,
    },
    {
      title: "Center Visited",
      dataIndex: "centerVisited",
      render: (centerVisited: any) => <span>{centerVisited}</span>,
    },
    {
      title: "Contact No.",
      dataIndex: "contactDetail",
      render: (contactDetail: any) => <span>{contactDetail}</span>,
    },

    ...(sendCandidateReqType === CandidateRequestType.Student
      ? [
          {
            title: "Batch",
            dataIndex: "batch",
            key: "batch",
            render: (batch: any) => <span>{batch}</span>,
          },
        ]
      : [
          {
            title: "Enquiry year",
            dataIndex: "enquiryDate",
            key: "enquiryDate",
            render: (batch: any) => <span>{dayjs(batch).format("YYYY")}</span>,
          },
        ]),
  ];

  return (
    <>
      <Modal
        width={1100}
        footer={false}
        title="Enquiry"
        open={openEnquiryModal}
        onCancel={handleCancelEnquiry}
      >
        <Row>
          <Col span={24} style={{ paddingTop: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 330px)",
                overflow: "hidden",
              }}
            >
              <div style={{ overflowY: "auto", flex: "1" }}>
                <Table
                  size="small"
                  className="Tabel-style masterList"
                  dataSource={viewEnquiryData}
                  loading={modelLoading}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: "calc(100vh - 400px)", x: 600 }}
                />
              </div>
              <Pagination
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                current={pagingItems.currentPage}
                pageSize={pagingItems.pageSize}
                showSizeChanger
                total={pagingItems.totalRecords}
                pageSizeOptions={pageSizeOption}
                onChange={(page, pageSize) => {
                  setPagingItems({
                    ...pagingItems,
                    currentPage: page,
                    pageSize,
                  });
                }}
                style={{ marginTop: "16px", textAlign: "right" }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EnquiryViewModal;
