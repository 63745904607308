import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Typography,
  Col,
  Button,
  Table,
  Space,
  Dropdown,
  Spin,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import AddGrade from "./AddGrade";
import AddRubric from "./AddRubric";
import { useQuery } from "@tanstack/react-query";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { MasterTypes } from "../../../../Constant/Enums";

//#region And table
interface DataType {
  key: string;
  name: string;
}





const DisplayGrade: React.FC = () => {
  const [IsGradeModel, setIsGradeModel] = useState<boolean>(false);
  const [IsRublicModel, setIsRublicModel] = useState<boolean>(false);
  const [gradeId, setGradeId] = useState<any>();
  const [sdefault,setDefault] = useState<any>(false);
  const [start, setStart] = useState<any>(0);
  const [length, setLength] = useState<any>(50);
  const [rubricDetail,setRubricDetail]= useState<any>();


  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <Typography.Link>Delete</Typography.Link>,
    },
  ];
  
  const handleEditRubric = (val:any)=>{
    setRubricDetail(val);
    setIsRublicModel(true);
  }
  
  const rublicColumns: TableProps<DataType>["columns"] = [
    {
      title: "Rubric Name",
      dataIndex: "name",
      key: "name",
      width: "70%",
      render: (text:any,record:any) =><Typography.Link onClick={()=>handleEditRubric(record)}>{text}</Typography.Link>,
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_) => (
        <Space size="middle">
          <Dropdown menu={{ items }} placement="bottomRight">
            <BsThreeDotsVertical />
          </Dropdown>
        </Space>
      ),
    },
  ];

  const {
    data: GradeData,
    isLoading: gradeLoading,
    refetch,
  } = useQuery({
    queryKey: ["Grade", start, length],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?",
          {
            masterType: MasterTypes.GradeScale,
            start: start,
            length: length,
          }
        );
        if (/*res?.errors === null &&*/ res?.result?.items?.length > 0) {
          // setPagingItems((p) => {
          //   p.totalRecords = res?.result?.totalRecords;
          //   return p;
          // });
          return res?.result?.items.map((item: any) => ({
            key: item.id,
            id: item.id,
            MasterTypes: item.masterType,
            name: item.name,
            gradesValues: item.gradesValues,
          })); // Add key prop to each item
        } else {
          return []; // return an empty array when API call fails or no data
        }
      } catch (error) {
        console.error(error);
        return []; // return an empty array when API call fails
      }
    },
  });

  const {
    data: RubricData,
    isLoading: RubricLoading,
    refetch:rubricRefech
  } = useQuery({
    queryKey: ["Rubric", start, length],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?",
          {
            masterType: MasterTypes.Rubric,
            start: start,
            length: length,
          }
        );
        if (res?.result?.items?.length > 0) {
          // setPagingItems((p) => {
          //   p.totalRecords = res?.result?.totalRecords;
          //   return p;
          // });
          return res?.result?.items.map((item: any) => ({
            // key: item.id,
            id: item.id,
            name: item.name,
            // RubricPoint:item.rubricPoint,
            RubricPoint: item.rubricPoint.map((point:any,index:any) => ({
              key: index,
              ...point,
            })),
          })); 
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const gradeColumns: TableProps<DataType>["columns"] = [
    {
      title: "Grade Name",
      dataIndex: "name",
      key: "name",
      width: "70%",
      render: (text,record) => <Typography.Link color="#0D61FF" 
      onClick={()=>{
        setIsGradeModel(!IsGradeModel)
        setGradeId(record);
      }}
      >{text}</Typography.Link>,
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_) => (
        <Space size="middle">
          <Dropdown menu={{ items }} placement="bottomRight">
            <BsThreeDotsVertical />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div>
        <Row justify={"space-between"} style={{ alignItems: "baseline" }}>
          <Col>
            <Typography.Title level={5} style={{ fontWeight: 500 }}>
              Grades
            </Typography.Title>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ fontWeight: 500 }}
              onClick={() => {
                setIsGradeModel(!IsGradeModel);
              }}
            >
              Add Grades
            </Button>
          </Col>
        </Row>
        <Table
        className="masterList"
          columns={gradeColumns}
          dataSource={GradeData}
          style={{ marginTop: 14 ,minHeight:340 ,overflowY:"auto"}}
          scroll={{y:280}}
          pagination={false}
          loading={{
            spinning: gradeLoading,
            indicator: <Spin indicator={<LoadingOutlined />} />,
          }}
        />

        {/*  Grade Model  */}
        <AddGrade
          IsGradeModel={IsGradeModel}
          setIsGradeModel={setIsGradeModel}
          gradeId={gradeId}
          setGradeId={setGradeId}
          refetch={refetch}
        />
      </div>

      <div style={{ marginTop: "" }}>
        <Row justify={"space-between"} style={{ alignItems: "baseline" }}>
          <Col>
            <Typography.Title level={5} style={{ fontWeight: 500 }}>
              Rubric
            </Typography.Title>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ fontWeight: 500 }}
              onClick={() => {
                setIsRublicModel(!IsRublicModel);
                setDefault(true)
              }}
            >
              Add Rubric
            </Button>
          </Col>
        </Row>
        <Table
         className="masterList"
          columns={rublicColumns}
          dataSource={RubricData || []}
          style={{ marginTop: 14 ,minHeight:316 ,overflowY:"auto"}}
          scroll={{y:280}}
          pagination={false}
          
          loading={{
            spinning: RubricLoading,
            indicator: <Spin indicator={<LoadingOutlined />} />,
          }}
        />
        <AddRubric
          IsRublicModel={IsRublicModel}
          RubricDetail={rubricDetail}
          setDefault={setDefault}
          sdefault={sdefault}
          SetRubricDetail={setRubricDetail}
          setIsRublicModel={setIsRublicModel}
          RubricRefech={rubricRefech}
        />
      </div>
    </>
  );
};

export default DisplayGrade;
