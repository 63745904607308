import {
  Avatar,
  Card,
  Col,
  Image,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import {
  CenterVisited,
  IndianState,
  languageProficiency,
  outcomeType,
  StudyModeObj,
} from "../../Constant/Enums";
import Meta from "antd/es/card/Meta";
import {
  EnvironmentTwoTone,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import UploadProfile from "../../component/EnquiryCommunicationTable/UploadProfile";
const { Text } = Typography;
interface IViewStudentDetails {
  apiData: any;
  fetchStudent:Function;
}

const ViewStudentDetails = (props: IViewStudentDetails) => {
  const { apiData } = props;
  // const [form] = useForm();
  // console.log(apiData, "78");
  const currentDate = dayjs();
  const enrolledDate = dayjs(apiData?.enrolledDate);
  // const [fileInfo, setFileInfo] = useState<any | null>(null);
  const yearsDiff = currentDate.diff(enrolledDate, "year");
  const monthsDiff = currentDate.diff(
    enrolledDate.add(yearsDiff, "year"),
    "month"
  );
  const daysDiff = currentDate.diff(
    enrolledDate.add(yearsDiff, "year").add(monthsDiff, "month"),
    "day"
  );
  // const [fileList, setFileList] = useState<any[]>([]);
  // const [btnLoading, setBtnLoading] = useState<boolean>(false);
  // const [loading, setIsLoading] = useState<boolean>(false);
  // const [previewVisible, setPreviewVisible] = useState<any>(false);
  // const handleUploadChange = ({ file }: any) => {
  //   console.log(file,"fileeee");

  //   if (file && file.originFileObj) {

  //     const fileUrl :any= URL.createObjectURL(file.originFileObj);
  //     setPreviewImage(fileUrl);

  //     const { name, size, type, lastModifiedDate } = file.originFileObj;
  //     setFileInfo({ name, size, type, lastModifiedDate });
  //   }
  // };

  // const onChange = async ({ fileList: newFileList }: any) => {
  //   // debugger
  //   console.log("newFileList", newFileList);
  //   const updatedFileList = await Promise.all(
  //     newFileList.map(async (file: any) => {
  //       if (file.originFileObj && !file.url) {
  //         const base64 = await getBase64(file.originFileObj);
  //         return {
  //           ...file,
  //           base64,
  //         };
  //       }
  //       return file;
  //     })
  //   );
  //   console.log("updatedFileList", updatedFileList);

  //   setFileList(updatedFileList);
  // };
  // const getBase64 = (file: File) =>
  //   new Promise<string>((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.onerror = (error) => reject(error);
  //   });

  // const saveData = async (e: any) => {
  //   console.log(e);

  //   form.validateFields().then(async (values: any) => {
  //     console.log(values, "vallllllsdfasfasdsdf");

  //     values.candidateImage = fileList;

  //     // Proceed with your API call or further processing here
  //   });
  // };
  // const saveData = async (e:any) => {
  //   console.log(e);

  //   form.validateFields().then(async(values:any)=>{
  //     console.log(values,"vallllllsdfasfasdsdf")
  //   })
  // form.validateFields().then(async (values: any) => {
  //   setBtnLoading(true);
  //    console.log(values,"valuess====");
  //   const attachments = fileList?.map((file:any) => {
  //     console.log(file,"fileeeeeeeee")
  //     console.log(values,"valuess====");
  //     return {
  //       Filename: {
  //         Id: file?.id || null,
  //         Name: file?.name,
  //         ContentType: file.type,
  //         Path: file?.base64,
  //         Length: file?.size || file?.response?.size,
  //       },
  //     };
  //   });
  //   console.log(attachments[0],"attafasfsfd");

  //   // let val = {
  //   //   ...values,
  //   //   id: props?.holidayId,
  //   //   masterType: MasterTypes.Holidays,
  //   //   name: values?.name,
  //   //   fromTo:
  //   //     values?.fromTo === undefined
  //   //       ? null
  //   //       : {
  //   //           from: dayjs.utc(values?.fromTo[0]),
  //   //           to: dayjs.utc(values?.fromTo[1]),
  //   //         },
  //   //   isActive: values?.isActive,
  //   //   attachments,
  //   // };
  //   // console.log("val = ", val)

  //   await HTTPSCalls.POST(
  //     HTTPSCalls.ENDPOINTS.STUDENTS_UPLOAD_PROFILE+"?",{},
  //     {
  //       enquiryId:apiData?.id,
  //       uploadedProfile:attachments[0],
  //     },

  //   )
  //     .then((res: any) => {
  //       // setShouldRefresh((x: boolean) => !x);
  //       message.success("Image uploaded Successfully");
  //       setIsLoading(false);
  //     })
  //     .catch((error: any) => {
  //       // setRecall(1);
  //       // setShouldRefresh((x: boolean) => !x);
  //       setIsLoading(false);
  //       message.error(error);
  //     });
  // });
  // };
  return (
    <>
      <div style={{ maxHeight: `calc(100vh - 220px)` }}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card
              bordered
              size="small"
              // style={{ height: "100vh", overflowY: "auto" }}
              title={
                <>
                  <Meta
                    style={{ fontSize: 12 }}
                    title={
                      <Space
                        size={[8, 16]}
                        direction="vertical"
                        style={{
                          height: 300,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <Row justify="center">
        
                          <Avatar
                          className="avatarHover"
                             style={{
                              width: "125px",
                              height: "125px",
                              borderRadius: "50%",
                              border: "4px solid rgba(9, 88, 217, 0.57)",
                            }}
                            src={apiData.candidateImage.path  }
                          />
                          <ImgCrop rotationSlider>
                          
                         
                           <Upload listType="picture-card"><UploadOutlined/> {} </Upload>
                           </ImgCrop>
                        </Row> */}

                        <Row justify="center">
                        <Avatar
                            // onClick={() => setPreviewVisible(true)}
                            className="avatarHover"
                            style={{
                              width: "125px",
                              height: "125px",
                              borderRadius: "50%",
                              border: "4px solid rgba(9, 88, 217, 0.57)",
                            }}
                            // src={apiData.candidateImage.path} // Show preview image or fallback to default
                            src={<Image
                              width={124}
                              height={124}
                              style={{ objectFit: "fill" }}
                              src={apiData?.candidateImage?.path}
                              alt="avatar"
                              />
                            }
                          />
                        
                          <UploadProfile
                            apiData={apiData}
                            afterUpload={() =>props.fetchStudent()}
                            // style={{ paddingLeft: "8px" }}
                          />{" "}
                          {/* </Upload> */}
                          {/* </ImgCrop> */}

                          {/* Display additional file information */}
                          {/* {fileInfo && (
                            <div style={{ marginTop: "16px" }}>
                              <p>
                                <b>File Name:</b> {fileInfo.name}
                              </p>
                              <p>
                                <b>File Size:</b>{" "}
                                {(fileInfo.size / 1024).toFixed(2)} KB
                              </p>
                              <p>
                                <b>File Type:</b> {fileInfo.type}
                              </p>
                              <p>
                                <b>Last Modified:</b>{" "}
                                {fileInfo.lastModifiedDate.toLocaleString()}
                              </p>
                            </div>
                          )} */}
                        </Row>
                        <Row justify="center">
                          <span
                            style={{
                              color: "#377dff",
                              textAlign: "center",
                              fontSize: 15,
                              marginTop: 10,
                            }}
                          >
                            {apiData.name} {apiData.middleName}{" "}
                            {apiData.lastName}
                          </span>
                        </Row>
                        <Row justify="center">
                          <span
                            style={{
                              color: "gray",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: 15,
                            }}
                          >
                            Student
                          </span>
                        </Row>
                      </Space>
                    }
                  />
                </>
              }
            >
              <Row justify="center">
                <span
                  style={{
                    color: "gray",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingRight: "5px",
                  }}
                >
                  Student Id:
                </span>
                <span style={{ color: "black" }}>{apiData.studentId}</span>
              </Row>
              <Card
                style={{
                  marginTop: 15,
                  marginLeft: 10,
                  marginRight: 10,
                  backgroundColor: "#f8f9ff",
                }}
              >
                <Row
                  justify="center"
                  style={{
                    textAlign: "center",
                    marginBottom: 5,
                    color: "black",
                  }}
                >
                  Enrolled Since
                </Row>
                <Row
                  justify="center"
                  style={{ color: "#377dff", marginBottom: 5 }}
                >
                  {dayjs(apiData?.enrolledDate)?.format("DD MMM YYYY")}
                </Row>
                {yearsDiff > 0 ? (
                  <Row justify="center" style={{ color: "gray" }}>
                    ( {yearsDiff} years {monthsDiff} months {daysDiff} days )
                  </Row>
                ) : monthsDiff > 0 ? (
                  <Row justify="center" style={{ color: "gray" }}>
                    ( {monthsDiff} months {daysDiff} days )
                  </Row>
                ) : (
                  <Row justify="center" style={{ color: "gray" }}>
                    ( {daysDiff} days )
                  </Row>
                )}
              </Card>
              <Card
                style={{
                  marginTop: 15,
                  marginLeft: 10,
                  marginRight: 10,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <Row
                  style={{
                    textAlign: "left",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <span>
                    <PhoneOutlined />
                  </span>
                  <span style={{ paddingLeft: 10, color: "black" }}>
                    {apiData?.contactDetail?.mobileNumber}
                  </span>
                </Row>
                <Row
                  style={{
                    textAlign: "left",
                    marginTop: 10,
                    marginBottom: 10,
                    color: "black",
                  }}
                >
                  <span>
                    <MailOutlined />
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <Tooltip
                      placement="right"
                      title={apiData.contactDetail.email}
                    >
                      <span>
                        {apiData.contactDetail.email.length > 25
                          ? apiData.contactDetail.email.slice(0, 25) + "..."
                          : apiData.contactDetail.email}
                      </span>
                    </Tooltip>
                  </span>
                </Row>
                <Row
                  style={{
                    textAlign: "left",
                    marginTop: 10,
                    marginBottom: 10,
                    color: "black",
                  }}
                >
                  <span>
                    <EnvironmentTwoTone />
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <Tooltip
                      placement="right"
                      title={apiData.contactDetail.currentAddress}
                    >
                      <span>
                        {apiData.contactDetail.currentAddress.length > 25
                          ? apiData.contactDetail.currentAddress.slice(0, 20) +
                            "..."
                          : apiData.contactDetail.currentAddress}
                      </span>
                    </Tooltip>
                  </span>
                </Row>
              </Card>
            </Card>
            </Col>
          <Col span={18} style={{overflowY:"auto", maxHeight: `calc(100vh - 280px)`,overflowX:"hidden" }}>
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Card
                 className="studentForm"
                  bordered
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <Space size={10}>
                            <span  style={{ fontSize: 14, fontWeight:600 }}>
                              General Information
                            </span>
                          </Space>
                        }
                      />
                    </>
                  }
                >
                  <Row >
                    <Col lg={3} style={{fontWeight:500 }}>
                      Enquiry Date
                    </Col>
                    <Col lg={21} style={{color:"slategray"}}>
                      {dayjs(apiData?.enquiryDate)?.format("DD MMM YYYY")}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ fontWeight:500 }}>
                      First Name
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{apiData?.name}</Col>
                    <Col lg={3}style={{fontWeight:500 }}>
                      Middle Name:
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{apiData?.middleName}</Col>
                    <Col lg={3} style={{fontWeight:500 }}>
                      Last Name:
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{apiData?.lastName}</Col>
                    <Col lg={3} style={{fontWeight:500 }}>
                      Date of Birth:
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>
                      {dayjs(apiData?.dateOfBirth)?.format("DD MMM YYYY")}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{fontWeight:500 }}>
                      Gender
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{apiData?.gender?.name}</Col>
                    <Col lg={3} style={{fontWeight:500 }}>
                      Nationality
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{apiData?.nationality}</Col>
                    <Col lg={3} style={{fontWeight:500 }}>
                      English Proficiency
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>
                      {languageProficiency[apiData?.englishProficiency]}
                    </Col>
                    <Col lg={3} style={{fontWeight:500 }}>
                      MentorName
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}> {apiData?.mentorName}</Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{fontWeight:500 }}>
                      Sources
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{apiData?.sources?.name}</Col>
                    {/* <Col lg={3} style={{ color: 'gray' }}>Assign To</Col>
                                <Col lg={3}>--</Col> */}
                    <Col lg={3} style={{fontWeight:500 }}>
                      Center Visited
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{CenterVisited[apiData?.centerVisited]} </Col>
                    <Col lg={3} style={{fontWeight:500 }}>
                      Status
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{outcomeType[apiData?.response]}</Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 16]} style={{ marginTop: 18 }}>
              <Col span={24} >
                <Card
                  className="studentForm"
                  size="small"
                  title={
                    <div >
                      <Meta
                     
                        style={{ fontSize: 12 }}
                        title={
                        
                            <span style={{ fontSize: 14,fontWeight:600}}>
                              Address & Contact
                            </span>
                       
                        }
                      />
                    </div>
                  }
                >
                  <Row>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Contact Number
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}> {apiData?.contactDetail?.mobileNumber}</Col>
                    <Col lg={3} style={{ fontWeight:500 }}>
                      City
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{apiData?.city}</Col>
                    <Col lg={3} style={{ fontWeight:500 }}>
                      State
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>
                      {
                        IndianState.find((x: any) => x.value === apiData?.state)
                          ?.label
                      }
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Email
                    </Col>
                    <Col lg={9} >
                      <Text
                      style={{color:"slategray"}}
                        copyable
                        title={apiData?.contactDetail?.email}
                        ellipsis
                      >
                        {apiData?.contactDetail?.email}
                      </Text>
                    </Col>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Official Email
                    </Col>
                    <Col lg={8}>
                      <Text style={{color:"slategray"}} copyable title={apiData?.officialEmail} ellipsis>
                        {apiData?.officialEmail}
                      </Text>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Current Address
                    </Col>
                    <Col lg={9} style={{color:"slategray"}}>{apiData?.contactDetail?.currentAddress}</Col>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Permanent Address
                    </Col>
                    <Col lg={9} style={{color:"slategray"}}>{apiData?.contactDetail?.permanentAddress}</Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                className="studentForm"
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <Space size={10}>
                            <span style={{ fontSize: 14, color: "#4c4c4c",fontWeight:700 }}>
                              Parents and Guardian
                            </span>
                          </Space>
                        }
                      />
                    </>
                  }
                >
                  <Row>
                    <Col
                      lg={24}
                      style={{
                        color: "#4c4c4c",
                        marginBottom: "10px",
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    ></Col>
                  </Row>
                  <Row >
                    <Col lg={3} style={{ fontWeight:500}}>
                      Father's Name
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{apiData?.fatherName}</Col>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Father's Occupation
                    </Col>
                    <Col lg={3}  style={{color:"slategray"}}>{apiData?.fatherOccupation}</Col>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Mother's Name
                    </Col>
                    <Col lg={3}  style={{color:"slategray"}}>{apiData?.motherName}</Col>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Mother's Occupation
                    </Col>
                    <Col lg={3}  style={{color:"slategray"}}>{apiData?.motherOccupation}</Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Guardian's Name
                    </Col>
                    <Col lg={3}  style={{color:"slategray"}}>{apiData?.guardianName}</Col>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Guardian Relation
                    </Col>
                    <Col lg={3}  style={{color:"slategray"}}>{apiData?.guardianRelation}</Col>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Guardian's Phone No
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>{apiData?.guardianPhone}</Col>
                    <Col lg={4} style={{ fontWeight:500}}>
                      Guardian's Occupation
                    </Col>
                    <Col lg={3}  style={{color:"slategray"}}>{apiData?.guardianOccupation}</Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Guardian's Address
                    </Col>
                    <Col lg={3}  style={{color:"slategray"}}>{apiData?.guardianAddress}</Col>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Zip Code
                    </Col>
                    <Col lg={3}  style={{color:"slategray"}}>{apiData?.guardianZipCode}</Col>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Guardian's City
                    </Col>
                    <Col lg={3}  style={{color:"slategray"}}>{apiData?.guardianCity}</Col>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Guardian's State
                    </Col>
                    <Col lg={3} style={{color:"slategray"}}>
                      {" "}
                      {
                        IndianState.find(
                          (state: any) => state.value === apiData?.state
                        )?.label
                      }
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 16]} style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                 className="studentForm"
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <Space size={10}>
                            <span style={{ fontSize: 14, color: "#4c4c4c",fontWeight:700 }}>
                              Education
                            </span>
                          </Space>
                        }
                      />
                    </>
                  }
                >
                  <Row gutter={[24, 16]}>
                    <Col span={4} style={{ fontWeight:500}}>
                      School/Collage Name
                    </Col>
                    <Col span={3} style={{ fontWeight:500}}>
                      Grade/Graduate
                    </Col>
                    <Col span={3} style={{ fontWeight:500}}>
                      Year
                    </Col>
                    <Col span={3} style={{ fontWeight:500}}>
                      Percent
                    </Col>
                    <Col span={3} style={{ fontWeight:500}}>
                      Study Mode
                    </Col>
                    <Col span={4} style={{ fontWeight:500}}>
                      Curriculum
                    </Col>
                    <Col span={3} style={{ fontWeight:500}}>
                      Stream
                    </Col>
                  </Row>

                  {apiData?.academicRecord?.map((itm: any, indx: number) => (
                    <Row key={indx} gutter={[24, 16]} style={{color:"slategray"}}>
                      <Col span={4}>{itm?.schoolName}</Col>
                      <Col span={3}>{itm?.class?.name}</Col>
                      <Col span={3}>{itm?.year}</Col>
                      <Col span={3}>{itm?.percentage}</Col>
                      <Col span={3}>{StudyModeObj[itm?.studyMode]}</Col>
                      <Col span={4}>{itm?.curriculum?.name}</Col>
                      <Col span={3}>{itm?.stream?.name}</Col>
                    </Row>
                  ))}
                </Card>
              </Col>
            </Row>
            {/* <Row style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                className="studentForm"
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <>
                            {" "}
                            <span style={{ fontSize: 14, color: "#4c4c4c",fontWeight:700 }}>
                              Upload Documents
                            </span>
                          </>
                        }
                      />
                    </>
                  }
                >
                  <Row style={{ marginTop: 18 }} gutter={[24, 16]}>
                    <Col lg={6}>
                    </Col>
                    <Col lg={6}>

                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row> */}
            <Row style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                  size="small"
                  className="studentForm"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <span style={{ fontSize: 14, color: "#4c4c4c" ,fontWeight:700}}>
                            Required Information
                          </span>
                        }
                      ></Meta>
                    </>
                  }
                >
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={2} style={{ fontWeight:500}}>
                      Total Fees
                    </Col>
                    <Col lg={2} style={{color:"slategray"}}>{apiData?.amount}</Col>
                    <Col lg={2} style={{ fontWeight:500}}>
                      Student ID
                    </Col>
                    <Col lg={2} style={{color:"slategray"}}>{apiData?.studentId}</Col>
                    <Col lg={2} style={{ fontWeight:500}}>
                      Card No.
                    </Col>
                    <Col lg={2} style={{color:"slategray"}}>{apiData?.cardNo}</Col>
                    <Col lg={2} style={{ fontWeight:500}}>
                      Biometric Code
                    </Col>
                    <Col lg={2} style={{color:"slategray"}}>{apiData?.biometricCode}</Col>
                    <Col lg={2} style={{ fontWeight:500}}>
                      Enrolled Date
                    </Col>
                    <Col lg={2} style={{color:"slategray"}}>
                      {dayjs(apiData?.enrolledDate)?.format("DD-MMM-YYYY")}
                    </Col>
                    <Col lg={2} style={{ fontWeight:500}}>
                      Batch
                    </Col>
                    <Col lg={2} style={{color:"slategray"}}>{apiData?.batch?.name}</Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                  size="small"
                  className="studentForm"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <>
                            {" "}
                            <span style={{ fontSize: 14, color: "#4c4c4c" ,fontWeight:700}}>
                              Other Information
                            </span>
                          </>
                        }
                      />
                    </>
                  }
                >
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ fontWeight:500}}>
                      Other Information
                    </Col>
                    <Col lg={8} style={{color:"slategray"}}>{apiData?.otherInformation ?? "---"}</Col>
                    <Col lg={6} style={{ fontWeight:500}}>
                      Referral Source / Where did you hear about us?
                    </Col>
                    <Col lg={7} style={{color:"slategray"}}>{apiData?.referralSource ?? "---"}</Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewStudentDetails;
