import { LoadingOutlined } from "@ant-design/icons";
import { Col, DatePicker, Row, Space, Spin, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import { IApiResponse } from "../../Utilities/ApiUtility.axios";
import { BsHeadphones } from "react-icons/bs";
import dayjs from "dayjs";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/es/table";

const TATACallLogs = () => {
  const { RangePicker } = DatePicker;
  const [callDetails, setCallDetails] = useState<any>([]);
  // const [selectedDate, setSelectedDate] = useState<any>([null,null]);
  const [loading, setLoading] = useState<boolean>(false);

  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "",
    sortDir: "ascend",
    start: 0,
    length: 15,
    totalRecords: 0,
    from: null,
    to: null,
  });

  const columns:ColumnsType<any> = [
    {
      title: "S. No",
      dataIndex: "uuid",
      fixed: true,
      width: "4%",
      ellipsis: true,
      render: (text: any, record: any, index: any) => (
        <p>{listParams.start + index + 1}</p>
      ),
    },
    {
      title: "Agent Name",
      dataIndex: "answeredAgentName",
      key: "answeredAgentName",
      width: "13%",
      ellipsis: true,
      render: (text: any) => text || "-",
    },
    {
      title: "Call Type",
      key: "callType",
      dataIndex: "callType",
      width: "7%",
      // sorter: true,
      ellipsis: true,
      render: (text: any) =>
        text === "inbound" ? (
          <Tag color="green">Incoming</Tag>
        ) :  (
          <Tag color="magenta">Outgoing</Tag>
        ) 
    },
    {
      title: "Call Status",
      dataIndex: "callStatus",
      key: "callStatus",
      width: "7%",
      ellipsis: true,
      // sorter: true,
      render: (text: any) =>
        text === "answered" ? (
          <Tag color="green">{text}</Tag>
        ) : text === "missed" ? (
          <Tag color="red">{text}</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "Agent Number",
      dataIndex: "answeredAgentNumber",
      key: "AnsweredAgentNumber",
      width: "10%",
      ellipsis: true,
      render: (text: any) => text || "-",
    },
    {
      title: "Caller Id",
      dataIndex: "callerId",
      key: "callerId",
      width: "10%",
      ellipsis: true,
      render: (text: any) => text || "-",
    },
    {
      title: "Call to Number",
      dataIndex: "callToNumber",
      key: "callToNumber",
      width: "10%",
      ellipsis: true,
      render: (text: any) => text || "-",
    },
    {
      title: "Date",
      dataIndex: "start",
      width: "6%",
      ellipsis: true,
      render: (date: any) => (date ? dayjs(date).format("DD/MM/YYYY") : "-"),
    },
    {
      title: "Start Time",
      dataIndex: "answeredStart",
      key: "answeredStart",
      width: "7%",
      ellipsis: true,
      render: (time: any) => (time ? dayjs(time).format("HH:mm:ss") : "-"),
    },
    {
      title: "End Time",
      dataIndex: "end",
      width: "7%",
      ellipsis: true,
      render: (time: any) => (time ? dayjs(time).format("HH:mm:ss") : "-"),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: "7%",
      ellipsis: true,
      render: (seconds: any) => {
        if (!seconds) return "-";
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(secs).padStart(2, "0")}`;
      },
    },
    {
      title: "Hangup Cause",
      dataIndex: "hangupCause",
      width: "12%",
      ellipsis: true,
      render: (text: any) => text || "-",
    },{
      title: "Link",
      dataIndex: "recordingUrl",
      width: "5%",
      align: "center",
      render: (text: any) =>
        text ? (
          
          // <div style={{ display: "flex", justifyContent: "center",color: "slategray" }} >
            <BsHeadphones
            size={20}
              style={{ cursor: "pointer",color: "slategray",padding: "0px"}}
              onClick={() =>
                window.open(`${text}`, "_blank", "rel=noopener noreferrer")
              }
            />
          // </div>
        ) : (
          "-"
        ),
    },
  ];

  useEffect(() => {
    getLogs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listParams.search,
    listParams.start,
    listParams.length,
    listParams.sortCol,
    listParams.sortDir,
    listParams?.from,
    listParams?.to,
  ]);

  const getLogs = async () => {
    try {
      setLoading(true);
      const res: IApiResponse = await HTTPSCalls.GET(
        `${HTTPSCalls.ENDPOINTS.TATA_CALL_LOG_LIST}?`,
        {
          search: listParams?.search,
          start: listParams.start,
          length: listParams?.length,
          sortCol: listParams?.sortCol,
          sortDir: listParams?.sortDir,
          ...(listParams?.from && listParams?.to
            ? { from: listParams?.from, to: listParams?.to }
            : {}),
        }
      );
      if (res?.result) {
        setCallDetails(res.result.items);
        changeListParams("totalRecords", res.result.totalRecords);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const changeListParams = (key: string, value: any) => {
    setListParams((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={17}>
          <h3>Call Logs</h3>
        </Col>
        <Col span={7}>
          <Space size={15} align="center">
            <RangePicker
              format="DD/MM/YYYY"
              onChange={(dates: any) => {
                if (dates) {
                  changeListParams(
                    "from",
                    dayjs(dates[0]).format("MM/DD/YYYY")
                  );
                  changeListParams("to", dayjs(dates[1]).format("MM/DD/YYYY"));
                } else {
                  changeListParams("from", null);
                  changeListParams("to", null);
                }

                // setSelectedDate([dayjs.utc(dates[0]), dayjs.utc(dates[1])]);
              }}
              // allowClear={false}
            />
            <Search
              size="middle"
              placeholder="Search Name..."
              allowClear
              className="att-search-input mb-1"
              onSearch={(val: string) => changeListParams("search", val)}
              style={{ width: "100%" }}
            />
          </Space>
        </Col>
        <Row className="pt-3">
          <Col span={24}>
            <Table
             className="callLogTable"
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={callDetails}
              scroll={{ y: "65vh" }}
              loading={{
                spinning: loading,
                indicator: <Spin indicator={<LoadingOutlined />} />,
              }}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: listParams.totalRecords,
                pageSizeOptions: [15, 30, 50, 100],
              }}
              onChange={(paging, _, sorter: any) => {
                changeListParams("length", paging?.pageSize ?? 15);
                changeListParams(
                  "start",
                  ((paging?.current ?? 1 )- 1) * (paging?.pageSize ?? 15)
                );
                if (sorter?.order) {
                  changeListParams("sortDir", sorter.order);
                  changeListParams("sortCol", sorter.columnKey);
                }
              }}
            />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default TATACallLogs;
