import "../Common/MainLayout.css";
import { useState, useEffect } from "react";
import BasicStudentDetails from "./StudentDetailsTabComponents/BasicStudentDetails";
import AllActivityLog from "../component/EnquiryCommunicationTable/AllActivityLog";
import { Button, Card, Col, Row, Space } from "antd";
import HTTPSCalls from "../Services/HTTPCalls";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import CredentialsTable from "../component/EnquiryCommunicationTable/CredentialsTable";
import { IEnquiryModelType } from "./EnquiryLead";
import AttachmentTable from "../component/EnquiryCommunicationTable/AttachmentTable";
import CourseData from "../component/EnquiryCommunicationTable/CourseData";
import EnquirySettings from "../component/EnquiryCommunicationTable/EnquirySettings";
interface EnquiryProps {
  userType?: string;
  type: any;
  onDismiss: (rec: boolean) => void;
  setShouldRefresh: any;
  editRecord: any;
}
interface ISignature {
  name: string;
  id: string;
  path: string;
  contentType: string;
}
//#region Add In Option
// const AddInOption = ({ funcType, setRefresh }: any) => {
//     const [loading, setLoading] = useState(false);
//     const [name, setName] = useState("");
//     const inputRef = useRef<InputRef>(null);
//     const { openNotification } = React.useContext(adminContext);
//     const onNameChange = (e: any) => {
//         setName(e?.target?.value);
//     };
//     const addOption = async (e: any) => {
//         e?.stopPropagation();
//         if (!name || name?.trim() === "") {
//             openNotification("error", "Option cant'be empty.");
//         } else {
//             if (funcType === "gender") {
//                 setLoading(true);
//                 let val = {
//                     masterType: MasterTypes.Gender,
//                     name: name,
//                 };
//                 await genderApi
//                     .POST(GenderMaster.endPoints.save, null, val)
//                     .then((res: any) => {
//                         if (res?.status) {
//                             setRefresh((x: boolean) => !x);
//                             openNotification("success", "Gender saved successfully");
//                             setName("");
//                             setLoading(false);
//                         } else {
//                             openNotification("error", res?.message);
//                             setLoading(false);
//                         }
//                     });
//             } else if (funcType === "curriculum") {
//                 setLoading(true);
//                 let val = {
//                     masterType: MasterTypes.Curriculum,
//                     name: name,
//                 };
//                 await curriculumApi
//                     .POST(CurriculumMaster.endPoints.save, null, val)
//                     .then((res: any) => {
//                         if (res?.status) {
//                             setRefresh((x: boolean) => !x);
//                             openNotification("success", "Curriculum saved successfully");
//                             setName("");
//                             setLoading(false);
//                         } else {
//                             openNotification("error", res?.message);
//                             setLoading(false);
//                         }
//                     });
//             } else if (funcType === "stream") {
//                 setLoading(true);
//                 let val = {
//                     masterType: MasterTypes.Stream,
//                     name: name,
//                 };
//                 await curriculumApi
//                     .POST(StreamMaster.endPoints.save, null, val)
//                     .then((res: any) => {
//                         if (res?.status) {
//                             setRefresh((x: boolean) => !x);
//                             openNotification("success", "Stream saved successfully");
//                             setName("");
//                             setLoading(false);
//                         } else {
//                             openNotification("error", res?.message);
//                             setLoading(false);
//                         }
//                     });
//             } else if (funcType === "class") {
//                 setLoading(true);
//                 let val = {
//                     masterType: MasterTypes.Classes,
//                     name: name,
//                 };
//                 await curriculumApi
//                     .POST(ClassesMaster.endPoints.save, null, val)
//                     .then((res: any) => {
//                         if (res?.status) {
//                             setRefresh((x: boolean) => !x);
//                             openNotification("success", "Class saved successfully");
//                             setName("");
//                             setLoading(false);
//                         } else {
//                             openNotification("error", res?.message);
//                             setLoading(false);
//                         }
//                     });
//             } else if (funcType === "document") {
//                 setLoading(true);
//                 let val = {
//                     masterType: MasterTypes.Documents,
//                     name: name,
//                 };
//                 await curriculumApi
//                     .POST(DocumentsMaster.endPoints.save, null, val)
//                     .then((res: any) => {
//                         if (res?.status) {
//                             setRefresh((x: boolean) => !x);
//                             openNotification("success", "Document saved successfully");
//                             setName("");
//                             setLoading(false);
//                         } else {
//                             openNotification("error", res?.message);
//                             setLoading(false);
//                         }
//                     });
//             } else if (funcType === "status") {
//                 setLoading(true);
//                 let val = {
//                     masterType: MasterTypes.Status,
//                     name: name,
//                 };
//                 await curriculumApi
//                     .POST(StatusMaster.endPoints.save, null, val)
//                     .then((res: any) => {
//                         if (res?.status) {
//                             setRefresh((x: boolean) => !x);
//                             openNotification("success", "Status saved successfully");
//                             setName("");
//                             setLoading(false);
//                         } else {
//                             openNotification("error", res?.message);
//                             setLoading(false);
//                         }
//                     });
//             } else if (funcType === "credential") {
//                 setLoading(true);
//                 let val = {
//                     masterType: MasterTypes.Credential,
//                     name: name,
//                 };
//                 await curriculumApi
//                     .POST(CredentialMaster.endPoints.save, null, val)
//                     .then((res: any) => {
//                         if (res?.status) {
//                             setRefresh((x: boolean) => !x);
//                             openNotification("success", "Credential saved successfully");
//                             setName("");
//                             setLoading(false);
//                         } else {
//                             openNotification("error", res?.message);
//                             setLoading(false);
//                         }
//                     });
//             } else if (funcType === "medium") {
//                 setLoading(true);
//                 let val = {
//                     masterType: MasterTypes.Medium,
//                     name: name,
//                 };
//                 await curriculumApi
//                     .POST(MediumMaster.endPoints.save, null, val)
//                     .then((res: any) => {
//                         if (res?.status) {
//                             setRefresh((x: boolean) => !x);
//                             openNotification("success", "Medium saved successfully");
//                             setName("");
//                             setLoading(false);
//                         } else {
//                             openNotification("error", res?.message);
//                             setLoading(false);
//                         }
//                     });
//             } else if (funcType === "batch") {
//                 setLoading(true);
//                 let val = {
//                     masterType: MasterTypes.Batch,
//                     name: name,
//                 };
//                 await curriculumApi
//                     .POST(BatchMaster.endPoints.save, null, val)
//                     .then((res: any) => {
//                         if (res?.status) {
//                             setRefresh((x: boolean) => !x);
//                             openNotification("success", "Batch saved successfully");
//                             setName("");
//                             setLoading(false);
//                         } else {
//                             openNotification("error", res?.message);
//                             setLoading(false);
//                         }
//                     });
//             }
//         }
//     };
//     return (
//         <>
//             <Divider style={{ margin: "8px 0" }} />
//             <Row
//                 gutter={[0, 16]}
//                 justify={"space-around"}
//                 style={{ padding: "8px 8px 8px 5px" }}
//             >
//                 <Col sm={19}>
//                     <Input
//                         placeholder="Enter Option"
//                         ref={inputRef}
//                         value={name}
//                         onChange={onNameChange}
//                         onPressEnter={addOption}
//                         size={
//                             funcType === "class" ||
//                                 funcType === "document" ||
//                                 funcType === "status" ||
//                                 funcType === "credential"
//                                 ? "small"
//                                 : "middle"
//                         }
//                     />
//                 </Col>
//                 <Col sm={3}>
//                     <Button
//                         type="primary"
//                         ghost
//                         icon={<PlusOutlined />}
//                         onClick={addOption}
//                         loading={loading}
//                         size={
//                             funcType === "class" ||
//                                 funcType === "document" ||
//                                 funcType === "status" ||
//                                 funcType === "credential"
//                                 ? "small"
//                                 : "middle"
//                         }
//                     />
//                 </Col>
//             </Row>
//         </>
//     );
// };
//#endregion

const NewStudentForm = ({
  editRecord,
  userType,
  type,
  onDismiss,
  setShouldRefresh,
}: EnquiryProps) => {
  const tabListNoTitle = [
    {
      key: "basicDetails",
      label: "Basic Details",
    },
    {
      key: "credentials",
      label: "Credentials",
    },
    {
      key: "attachments",
      label: "Attachments",
    },
    // {
    //   key: "attendance",
    //   label: "Attendance",
    // },
    // {
    //   key: "paymentDetails",
    //   label: "Payment Details",
    // },
    {
      key: "logs",
      label: "Logs",
    },
    {
      key: "courseData",
      label: "Course Data",
    },
    {
      key: "settings",
      label: "Settings",
    },
  ];
  const [credentialRefresh, setCredentialContentRefresh] =
    useState<boolean>(false);
  const [attachmentRefresh, setAttachmentContentRefresh] =
    useState<boolean>(false);
  const [callApi, setCallApi] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<string>("basicDetails");
  const [editOperation, setEditOperation] = useState<{
    key: string;
    onSave: () => void | undefined;
    onCancel: () => void | undefined;
  } | null>(null);
  const [tabContent, setTabContent] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [apiData, setApiData] = useState<any>(undefined);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [studentSignature, setStudentSignature] = useState<ISignature[]>([]);
  const [studentImage, setStudentImage] = useState<ISignature[]>([]);
  const [counselorSignature, setCounselorSignature] = useState<ISignature[]>(
    []
  );
  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };
  const setTabData = (data: any) => {
    setTabContent({
      basicDetails: (
        <BasicStudentDetails
          studentSignature={studentSignature}
          studentImage={studentImage}
          counselorSignature={counselorSignature}
          setCounselorSignature={setCounselorSignature}
          setStudentImage={setStudentImage}
          setStudentSignature={setStudentSignature}
          apiData={data}
          editOperation={editOperation}
          setEditOperation={setEditOperation}
          isEdit={isEdit}
          setSaveLoading={setSaveLoading}
          saveLoading={saveLoading}
          fetchStudent={()=>setCallApi((prev)=>!prev)}
        />
      ),
      credentials: (
        <CredentialsTable
          type={IEnquiryModelType.credentials}
          apiData={data}
          credentialRefresh={credentialRefresh}
          onSubmit={(rec: boolean) => {
            if (rec) {
              setCredentialContentRefresh(true);
              setCallApi(!callApi);
            }
          }}
        />
      ),
      attachments: (
        <AttachmentTable
          type={IEnquiryModelType.acknowledgement}
          apiData={data}
          attachmentContentRefresh={attachmentRefresh}
          onSubmit={(rec: boolean) => {
            if (rec) {
              setAttachmentContentRefresh(true);
              setCallApi(!callApi);
            }
          }}
        />
      ),
      attendance: <p>attendance</p>,
      paymentDetails: <p>paymentDetails</p>,
      logs: <AllActivityLog apiData={data} shouldRefresh={false} maxHeight="70vh" />,
      courseData: <CourseData apiData={data} />,
      settings: <EnquirySettings apiData={data} />,
    });
  };
  const getById = async () => {
    setLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.ENQUIRY_GET_BY_ID + "?",
      { id: editRecord?.id }
    );
    if (res?.result?.userId) {
      delete res.result.userId;
    }

    if (credentialRefresh || attachmentRefresh) {
      setTabData(res?.result);
      setCredentialContentRefresh(false);
    } else {
      setTabData(res?.result);
    }
    setApiData(res?.result);
    setLoading(false);
  };
  useEffect(() => {
    getById();
  }, [callApi]);
  useEffect(() => {
    setTabData(apiData);
  }, [editOperation, refresh, credentialRefresh, attachmentRefresh]);
  // console.log("saveLoading in Parent = ", saveLoading);
  return (
    <>
      {!!editOperation && isEdit ? (
        ""
      ) : (
        <Row
          gutter={[8, 8]}
          style={{ paddingTop: "5px", paddingBottom: "6px" }}
        >
          <Col lg={15}>
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                onDismiss(false);
              }}
            />
          </Col>
        </Row>
      )}
      <Card
        loading={loading}
        style={{ width: "100%",minHeight:"700px" }}
        tabList={tabListNoTitle}
        activeTabKey={activeTabKey}
        tabBarExtraContent={
          <>
            {activeTabKey === "basicDetails" && (
              <Space align="center" direction="horizontal">
                {!!editOperation && isEdit ? (
                  <>
                    <Button
                      onClick={() => {
                        editOperation?.onCancel();
                        setEditOperation(null);
                        setIsEdit(false);
                        setStudentImage([]);
                        setCounselorSignature([]);
                        setStudentSignature([]);
                        setRefresh(!refresh);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={saveLoading}
                      type="primary"
                      onClick={() => {
                        setRefresh(!refresh);
                        editOperation?.onSave();
                      }}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setIsEdit(true);
                      setEditOperation((p: any) => ({
                        ...p,
                        key: activeTabKey,
                      }));
                    }}
                  >
                    Edit
                  </Button>
                )}
              </Space>
            )}
          </>
        }
        onTabChange={onTabChange}
        tabProps={{
          size: "middle",
        }}
      >
        {tabContent && tabContent[activeTabKey]}
      </Card>
    </>
  );
};

export default NewStudentForm;
