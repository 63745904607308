import { ConfigProvider, DatePicker, Typography } from "antd";
import { Col, Row, Select, Spin, Table } from "antd";
import { useState } from "react";
import {
  useGetEnquiriesCallList,
  useGetEnquiriesCounselorList,
} from "../../Utilities/tanStackQueries";
import dayjs from "dayjs";
import { EyeOutlined, FormOutlined, LoadingOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { EnquiryCallStatusEnum } from "../../Constant/Enums";
import { ColumnsType } from "antd/es/table";
import { AssigneeAvatar } from "../../Common/AssigneeAvatar";
import CallLogModel from "../../component/EnquiryLeadModel/CallLogModel";
import { isUserAdmin } from "../../Utilities/Utils";
import { userData as UD } from "../../Store/Slice/userSlice";
import { useSelector } from "react-redux";

const EnquiryCallList = () => {
  const userData= useSelector(UD);
  const [callActionModalOpen, setCallActionModalOpen] =
    useState<boolean>(false);
  const [record, setRecord] = useState<any>(null);
  const [isEditable,setIsEditable] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<EnquiryCallStatusEnum>(
    EnquiryCallStatusEnum.Pending
  );
  const [selectedDate, setSelectedDate] = useState<any>(dayjs());
  const [search, setSearch] = useState<string>("");
  const [selectedCounselor, setSelectedCounselor] = useState<
    string | undefined
  >(isUserAdmin()? undefined: userData?.id);
  // const [counselors, setCounselors] = useState<IdNameType[]>([]);

  // const [paggingItems, setPaggingItems] = useState<{
  //   totalRecords: number;
  //   currentPage: number;
  //   pageSize: number;
  // }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  // const [start, setStart] = useState<number>(0);
  // const [length, setLength] = useState<number>(15);
  // const [enquirySeach, setEnquirySearch] = useState<string>("");
  // const {
  //   data: notAssignedCounselorEnquiry,
  //   isLoading: enquiryCounselorLoading,
  //   refetch: GetNotAssignedEnquiriesRefetch,
  // } = useGetNotAssignedEnquiries({start,length, search: enquirySeach,action: selectedEnquiryCounselor});
  const {
    data: getEnquiriesCallList,
    isLoading: getEnquiriesCallListLoading,
    refetch: getEnquiriesCallListRefetch,
  } = useGetEnquiriesCallList({
    date: selectedDate.format("MM-DD-YYYY"),
    callStatus: selectedStatus,
    search,
    ...(selectedCounselor && { counselor: selectedCounselor }),
  });

  const { data: counselorList } = useGetEnquiriesCounselorList();

  const THeader = ({
    title,
    inputName,
    setShouldRefresh,
    changeListParams,
    ifSelect,
    listParams,
    setListParams,
    option,
    defaultValue,
  }: any) => {
    return (
      <>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "#1B1A1A",
                colorFillAlter: "transparent",
              },
              Select: {
                colorTextPlaceholder: "#1B1A1A",
              },
            },
          }}
        >
          <div
            className="searchDivStyle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {ifSelect ? (
              <Select
                allowClear
                style={{ width: "100%" }}
                onSelect={(value) => {
                  setSelectedCounselor(value);
                  // props?.changeListParams(inputName, value);
                  // Type guard to check if changeListParams is defined
                  if (changeListParams) {
                    changeListParams(inputName, value);
                  }
                  // setListParams({ ...listParams, start: 0 });
                  // setShouldRefresh((x: boolean) => !x);
                }}
                onClear={() => {
                  setSelectedCounselor(undefined);
                  // if (
                  //   inputName === "counselors" ||
                  //   inputName === "sourceSearch"
                  // )
                  //   changeListParams(inputName, "");
                  // else changeListParams(inputName);
                  // setListParams({ ...listParams, start: 0 });
                  // setShouldRefresh((x: boolean) => !x);
                }}
                popupMatchSelectWidth={false}
                variant="borderless"
                size="small"
                className="invSelectVendor"
                placeholder={title}
                options={option}
                value={selectedCounselor}
                // defaultValue={defaultValue}
              />
            ) : (
              <Search
                placeholder={title}
                className="CitSearchInput"
                allowClear
                onChange={(e) => {
                  if (changeListParams) {
                    changeListParams(inputName, e.target?.value);
                  }
                  // props?.changeListParams(inputName, e.target?.value)
                }}
                onSearch={(val) => {
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                size="small"
                variant="borderless"
              />
            )}
          </div>
        </ConfigProvider>
      </>
    );
  };
  const enquiryColumns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      width: "30%",
      render: (text: any, record: any) => {
        return <div>{record?.enquiry?.fullName}</div>;
      },
    },
    {
      title: "Enquiry Date",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      render: (text: any, record: any) => {
        return (
          <div>{dayjs(record?.enquiry?.enquiryDate).format("DD/MM/YYYY")}</div>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "contactDetail",
      key: "contactDetail",
      render: (text: any, record: any) => {
        return <div>{record?.enquiry?.contactDetail?.mobileNumber}</div>;
      },
    },
    {
      title: "Call Date",
      dataIndex: "date",
      key: "date",
      render: (text: any, record: any) => {
        return <div>{dayjs(text).format("DD/MM/YYYY")}</div>;
      },
    },
    {
      // title: "Counselor",
      title: (
        isUserAdmin() ? 
        <THeader
          title={"Counselling"}
          inputName={"counselors"}
          // changeListParams={changeListParams}
          // setShouldRefresh={setRefresh}
          // listParams={props?.listParams1}
          // setListParams={props?.setListParams1}
          ifSelect={true}
          option={counselorList?.result?.map((item: any) => ({
            value: item?.id,
            label: item?.name,
          }))}
        />:"Counselors"
      ),
      dataIndex: "assignTo",
      key: "assignTo",
      responsive: ["xl"],
      width: 150,
      render: (val: any, record: any) => (
        <span className=" font13">
          {!!val ? <AssigneeAvatar data={val} size={"small"} /> : "-"}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "enquiryCallStatus",
      key: "enquiryCallStatus",
      render: (enquiryCallStatus: any, record: any) => (
        <>
          <div className="status">
            {EnquiryCallStatusEnum[enquiryCallStatus]}
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: "5%",
      align: "right",
      render: (_: any, record: any) => (
        <div>
          {isUserAdmin()?
          <EyeOutlined
          onClick={() => {
            setCallActionModalOpen(true);
            setRecord(record);
            setIsEditable(false); 
          }}
          />
          :
          <FormOutlined
            onClick={() => {
              setIsEditable(true); 
              setCallActionModalOpen(true);
              setRecord(record);
            }}
          />
        }
        </div>
      ),
    },
  ];

  //   useEffect(() => {
  //     if (notAssignedCounselorEnquiry) {
  //         setPagingItems((p) => {
  //             p.totalRecords = notAssignedCounselorEnquiry?.result?.totalRecords;
  //             return p;
  //           });
  //         }
  //   }, [notAssignedCounselorEnquiry]);
  // useEffect(() => {
  //     setStart((pagingItems?.currentPage - 1) * pagingItems?.pageSize);
  //     setLength(pagingItems?.pageSize);
  //   }, [pagingItems]);

  // const [selectedRows, setSelectedRows] = useState<IdNameType[]>([]);
  // const hasSelected = selectedRows.length > 0;
  // const rowSelection: TableProps["rowSelection"] = {
  //   selectedRowKeys: selectedRows.map((item) => item.id),
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
  //     setSelectedRows(
  //       selectedRows.map((row) => ({ id: row?.id, name: row?.fullName }))
  //     );
  //   },
  // };
  // useEffect(() => {
  //   if (notAssignedCounselorEnquiry) {
  //     setPaggingItems((p) => {
  //       p.totalRecords = notAssignedCounselorEnquiry?.result?.totalRecords;
  //       return p;
  //     });
  //   }
  // }, [notAssignedCounselorEnquiry]);

  // useEffect(() => {
  //   setStart((paggingItems?.currentPage - 1) * paggingItems?.pageSize);
  //   setLength(paggingItems?.pageSize);
  // }, [paggingItems]);
  // useEffect(() => {
  //   const getList = async () => {
  //     let res = await HTTPSCalls.GET(
  //       HTTPSCalls.ENDPOINTS.GET_COUNSELORS_LIST + "?",
  //       {}
  //     );
  //     if (res?.result?.length > 0) {
  //       setCounselors(
  //         res?.result?.map((item: any) => ({
  //           value: item?.id,
  //           label: item?.name,
  //         }))
  //       );
  //     }
  //   };
  //   getList();
  //   // getMaster()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <>
      <div className="py-2 roundedCornerMedium bg-white">
        <Row gutter={18} style={{ padding: 12 }} justify={"end"}>
          <Col span={12} style={{ alignItems: "start" }}>
            <Typography.Title level={5} style={{ fontWeight: 500 }}>
              Enquries Call List
            </Typography.Title>
          </Col>
          <Col span={3}>
            <Select
              popupClassName="cit-select-box"
              placeholder={"Select Status"}
              options={[
                { label: "All", value: EnquiryCallStatusEnum.Undefined },
                {
                  label: "Interested",
                  value: EnquiryCallStatusEnum.Interested,
                },
                { label: "Pending", value: EnquiryCallStatusEnum.Pending },
                {
                  label: "Not Interested",
                  value: EnquiryCallStatusEnum.NotInterested,
                },
                { label: "Follow Up", value: EnquiryCallStatusEnum.FollowUp },
              ]}
              showSearch={false}
              value={selectedStatus}
              style={{ width: "100%" }}
              onSelect={(val: any) => {
                setSelectedStatus(val);
              }}
            />
          </Col>
          <Col span={4}>
            <DatePicker
              allowClear={false}
              value={selectedDate}
              style={{ width: "100%" }}
              // picker="month"
              format="DD/MM/YYYY"
              disabledDate={(current: any) => {
                return current && current > dayjs().endOf("month");
              }}
              onChange={(val: any) => {
                setSelectedDate(dayjs(val));
              }}
            />
          </Col>
          <Col span={5}>
            <Search
              size="middle"
              placeholder="Search..."
              allowClear
              className="att-search-input"
              onSearch={(val: string) => {
                setSearch(val);
              }}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              rowKey={(record) => record?.id}
              className="masterList"
              loading={{
                spinning: getEnquiriesCallListLoading,
                indicator: <Spin indicator={<LoadingOutlined />} />,
              }}
              style={{
                width: "100vw",
                // borderBottom: "none",
                minHeight: 270,
                overflow: "auto",
              }}
              size="small"
              scroll={{ y: "240px" }}
              dataSource={getEnquiriesCallList?.result}
              columns={enquiryColumns}
              // pagination={false}
              pagination={false}
              // onChange={(paging, filter, sort) => {
              //   setPaggingItems((prev) => ({
              //     ...prev,
              //     currentPage: paging?.current ?? 1,
              //     pageSize: paging?.pageSize ?? 10,
              //   }));
              // }}
            />
          </Col>
        </Row>
        <CallLogModel
          isEditable={isEditable}
          open={callActionModalOpen}
          record={record}
          onCancel={() => {
            setCallActionModalOpen(false);
          }}
          onSave={() => {
            setCallActionModalOpen(false);
          }}
        />
      </div>
    </>
  );
};
export default EnquiryCallList;
