import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  TabsProps,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { useState, useEffect } from "react";
import "../../Common/Common.css";
import HTTPSCalls from "../../Services/HTTPCalls";
import { MasterTypes } from "../../Constant/Enums";
import { adminContext } from "../../Common/PageRoute";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import { queryClient } from "../../App";
import {
  useGetListWithIdName,
  useGetMaster,
  useGetScheduleById,
  useGetScheduleItemListById,
} from "../../Utilities/tanStackQueries";
interface I_ManageClassSchedule {
  // setTrigger: any;
  // calenderData: any;
  // sectionOption: any;
  // scheduleOption: any;
  // selectedSection: any;
  // fillCalData: any;
  // subjectData: any;
  // teachers: any;
  // scheduleData: any;
  // setCategorySel: any
  manageScheduleId: string;
  setManageScheduleId: any;
}

const ManageClassSchedule = (props: I_ManageClassSchedule) => {
  dayjs.extend(duration);
  dayjs.extend(isBetween);
  const { data: listWithIdName, isLoading: listWithIdNameLoading } =
    useGetListWithIdName({});

  // console.log("props...", props);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [selectScheduleItem,setSelectScheduleItem]=useState()
  const { openNotification } = React.useContext(adminContext);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<any>("");
  const [selectedSection, setSelectedSection] = useState<any>("");
  const [teachers, setTeachers] = useState<any>([]);
  const [subjectData, setSubjectData] = useState<any>([]);
  const { data: roomMaster } = useGetMaster({
    start: 0,
    length: 999,
    MasterType: MasterTypes.Room,
  });
  const { data: scheduleData, isLoading: scheduleLoading } =
    useGetScheduleById(selectedSchedule);

  const { data: scheduleItemListData } = useGetScheduleItemListById({
    scheduleId: selectedSchedule,
    sectionId: selectedSection?.value,
  });

  const roomOptions = React.useMemo(() => {
    if (!roomMaster) {
      return [];
    }
    return (
      roomMaster?.result?.items?.map((r: any) => {
        return {
          label: r?.name,
          value: r?.id,
        };
      }) ?? []
    );
  }, [roomMaster]);

  const scheduleOptions = React.useMemo(() => {
    if (!listWithIdName) {
      return [];
    }
    return listWithIdName?.result?.items?.map(
      (i: { name: string; id: string }) => {
        return {
          value: i.id,
          label: i.name,
        };
      }
    );
  }, [listWithIdName]);

  const sectionOptions = React.useMemo(() => {
    if (!scheduleData?.result?.section) {
      return [];
    }
    return scheduleData?.result?.section?.map(
      (i: { name: string; id: string }) => {
        return {
          value: i.id,
          label: i.name,
        };
      }
    );
  }, [scheduleData]);
  // console.log(scheduleData?.result?.type,"scheduledata=-=-=-=-")
  useEffect(() => {
    if (!scheduleData?.result?.section) {
      setSelectedSection(null);
      return;
    }

    const options = scheduleData.result.section.map(
      (i: { name: string; id: string }) => ({
        value: i.id,
        label: i.name,
      })
    );
    setSelectedSection(options[0]);
    form.setFieldValue("section", options[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData, scheduleOptions]);

  useEffect(() => {
    if (scheduleData?.result) {
      form.setFieldValue("schedule", {
        label: scheduleData?.result?.name,
        value: scheduleData?.result?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData]);

  useEffect(() => {}, [sectionOptions]);

  const onSave = async () => {
    try {
      setButtonLoading(true);
      let values = form.getFieldsValue();
      // const workDay = Object.keys(values?.workDay[0]) || [];
      const workDay = Object.keys(values?.workDay[0] || {}).filter(
        (day) => values?.workDay[0][day] === true
      );

      let formValue = {
        ...values,
        subject: {
          id: values?.subject?.value,
          name: values?.subject?.label,
        },
        primaryTeacher: {
          id: values?.primaryTeacher?.value,
          name: values?.primaryTeacher?.label,
        },
        room: {
          id: values?.room?.value,
          name: values?.room?.label,
        },
        workDay: workDay,
      };

      let res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.UPDATE_SCHEDULE + "?",
        {
          ScheduleId: selectedSchedule?.value
            ? selectedSchedule?.value
            : selectedSchedule,
          SectionId: selectedSection?.value
            ? selectedSection?.value
            : selectedSection,
        },
        formValue
      );

      if (res.status) {
        form.resetFields([
          "subject",
          "title",
          "primaryTeacher",
          "workDay",
          "startTime",
          "endTime",
          "room",
        ]);

        openNotification("success", "Schedule Updated Successfully");

        queryClient.invalidateQueries({
          queryKey: ["getScheduleItemListById"],
        });
      } else {
        openNotification("error", res?.errors?.[0]?.message);
        console.log(res?.errors, "error");
      }
      console.log(values, "FormVAlue", formValue);
    } catch (err) {
      console.log("err", err);
    } finally {
      setButtonLoading(false);
    }
  };

  const validateAtLeastOneChecked = (_: any, value: any) => {
    const checkedDays = value.filter((day: any) =>
      Object.values(day).some((val) => val)
    );
    return checkedDays.length > 0
      ? Promise.resolve()
      : Promise.reject(
          new Error("At least one checkbox needs to be selected.")
        );
  };
  const handleCheckboxChange = () => {
    form.validateFields();
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Add Through Forms",
      children: (
        <div style={{ maxHeight: "90vh", overflowY: "scroll" }}>
          <Row>
            <Form form={form} layout="vertical" onFinish={onSave}>
              <Col lg={24}>
                <Form.Item
                  name="subject"
                  label="Subject"
                  rules={[
                    {
                      required: true,
                      message: "Please select a subject",
                    },
                  ]}
                >
                  <Select
                    loading={loading}
                    placeholder="Choose Subject"
                    style={{ width: "390px" }}
                    options={subjectData}
                    optionFilterProp="children"
                    labelInValue={true}
                    popupMatchSelectWidth={true}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item
                  name="title"
                  label="Title"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please add a Title",
                  //   },
                  // ]}
                >
                  <Input placeholder="Add a title" style={{ width: "390px" }} />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item
                  name="primaryTeacher"
                  label="Teacher"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Teacher",
                    },
                  ]}
                >
                  <Select
                    placeholder="Choose Teacher"
                    style={{ width: "390px" }}
                    options={teachers}
                    optionFilterProp="children"
                    labelInValue={true}
                    popupMatchSelectWidth={true}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={loading}
                  />
                </Form.Item>
              </Col>
              {/* {scheduleItemListData.type === 2? 
                                            } */}
              {scheduleData?.result?.type === 2 ? "" : <p>Select day</p>}

              <Col lg={21} style={{ paddingTop: 8 }}>
                <Row gutter={80}>
                  <Form.List
                    name="workDay"
                    initialValue={[{}]}
                    rules={[{ validator: validateAtLeastOneChecked }]}
                  >
                    {(fields) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <>
                            <Col lg={2}>
                              <Form.Item
                                {...restField}
                                name={[name, "mon"]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  style={{ color: "gray" }}
                                  onChange={handleCheckboxChange}
                                >
                                  Mon
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col lg={2}>
                              <Form.Item
                                {...restField}
                                name={[name, "tues"]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  style={{ color: "gray" }}
                                  onChange={handleCheckboxChange}
                                >
                                  Tues
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col lg={2}>
                              <Form.Item
                                {...restField}
                                name={[name, "wed"]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  style={{ color: "gray" }}
                                  onChange={handleCheckboxChange}
                                >
                                  Wed
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col lg={2}>
                              <Form.Item
                                {...restField}
                                name={[name, "thu"]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  style={{ color: "gray" }}
                                  onChange={handleCheckboxChange}
                                >
                                  Thu
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col lg={2}>
                              <Form.Item
                                {...restField}
                                name={[name, "fri"]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  style={{ color: "gray" }}
                                  onChange={handleCheckboxChange}
                                >
                                  Fri
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col lg={2}>
                              <Form.Item
                                {...restField}
                                name={[name, "sat"]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  style={{ color: "gray" }}
                                  onChange={handleCheckboxChange}
                                >
                                  Sat
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col lg={2}>
                              <Form.Item
                                {...restField}
                                name={[name, "sun"]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  style={{ color: "gray" }}
                                  onChange={handleCheckboxChange}
                                >
                                  Sun
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col lg={10}></Col>
                            <Col lg={24} style={{ marginTop: "-30px" }}>
                              <Form.Item
                                shouldUpdate
                                dependencies={[name]} // Trigger validation when any checkbox changes
                                validateTrigger="onChange" // Trigger validation on checkbox value change
                                name={[name]}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      const isAnyChecked = Object.values(
                                        value || {}
                                      ).some((checked) => checked);
                                      if (!isAnyChecked) {
                                        return Promise.reject(
                                          new Error(
                                            "At least one day must be selected"
                                          )
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              ></Form.Item>
                            </Col>
                          </>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Row>
              </Col>
              <Col lg={24}>
                <p>Select Time</p>
                <Space size={18} style={{ paddingTop: 8 }}>
                  <Form.Item
                    name="startTime"
                    rules={[
                      {
                        required: true,
                        message: "Please select Start Time",
                      },
                    ]}
                  >
                    <TimePicker
                      use12Hours
                      format="h:mm a"
                      showNow={false}
                      style={{ width: 180 }}
                      placeholder="Start time"
                    />
                  </Form.Item>
                  <Form.Item
                    name="endTime"
                    rules={[
                      {
                        required: true,
                        message: "Please select Start Time",
                      },
                    ]}
                  >
                    <TimePicker
                      use12Hours
                      format="h:mm a"
                      showNow={false}
                      style={{ width: 180 }}
                      placeholder="End time"
                    />
                  </Form.Item>
                </Space>
              </Col>
              <Col lg={24}>
                <Form.Item
                  label="Choose Room"
                  name={"room"}
                  rules={[
                    {
                      required: true,
                      message: "Please select a Room",
                    },
                  ]}
                >
                  <Select
                    placeholder={"Choose Room"}
                    style={{ width: "390px" }}
                    options={roomOptions}
                    optionFilterProp="children"
                    labelInValue={true}
                    popupMatchSelectWidth={true}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={loading}
                  />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Space size={8}>
                  <Button
                    type="primary"
                    loading={buttonLoading}
                    htmlType="submit"
                  >
                    {" "}
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Space>
              </Col>
            </Form>
          </Row>
        </div>
      ),
    },
  ];

  // const generateTimeSlots = () => {
  //   const Start = scheduleData?.result?.startTime;
  //   const End = scheduleData?.result?.endTime;
  //   const DurationInMinutes = scheduleData?.result?.durationMinutes;
  //   const sessionBreaks = scheduleData?.result?.sessionBreaks || [];

  //   const slots: any = [];

  //   let currentTime = dayjs(Start);
  //   const endTime = dayjs(End);

  //   const formatTime = (time: dayjs.Dayjs) => {
  //     const hour = time.hour();
  //     const minute = time.minute();
  //     const period = hour < 12 || hour === 24 ? "AM" : "PM";
  //     const displayHour = hour === 0 ? 12 : hour <= 12 ? hour : hour - 12;
  //     const displayMinute =
  //       minute === 0 ? "00" : String(minute).padStart(2, "0");
  //     return `${displayHour}:${displayMinute} ${period}`;
  //   };

  //   const weekData: any = {};
  //   scheduleItemListData?.result?.forEach((s: any) => {
  //     weekData[s.workDay] = s.items;
  //   });

  //   let i = 0;
  //   let breakIndex = 0;

  //   const addSlot = (slotStartTime: dayjs.Dayjs, slotEndTime: dayjs.Dayjs) => {
  //     slots.push({
  //       key: `${slotStartTime.format("HH:mm")}`,
  //       time: (
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "center",
  //           }}
  //         >
  //           <div>{formatTime(slotStartTime)}</div>
  //           <div style={{ marginTop: 5 }}>|</div>
  //           <div>{formatTime(slotEndTime)}</div>
  //         </div>
  //       ),
  //       mon: i < weekData?.mon?.length ? weekData?.mon[i] : null,
  //       tues: i < weekData?.tues?.length ? weekData?.tues[i] : null,
  //       wed: i < weekData?.wed?.length ? weekData?.wed[i] : null,
  //       thu: i < weekData?.thu?.length ? weekData?.thu[i] : null,
  //       fri: i < weekData?.fri?.length ? weekData?.fri[i] : null,
  //       sat: i < weekData?.sat?.length ? weekData?.sat[i] : null,
  //       sun: i < weekData?.sun?.length ? weekData?.sun[i] : null,
  //     });
  //   };

  //   while (currentTime.isBefore(endTime)) {
  //     // Handle session breaks
  //     if (sessionBreaks.length > 0 && breakIndex < sessionBreaks.length) {
  //       const breakStart = dayjs(sessionBreaks[breakIndex]?.time?.from);
  //       const breakEnd = dayjs(sessionBreaks[breakIndex]?.time?.to);

  //       // If the current time matches the start of a break, add the break slot
  //       if (currentTime.isSame(breakStart)) {
  //         addSlot(breakStart, breakEnd);
  //         currentTime = breakEnd; // Skip to the end of the break
  //         breakIndex++;
  //         i++;
  //         continue;
  //       }
  //     }

  //     // Handle regular time slots
  //     const slotStartTime = currentTime;
  //     const slotEndTime = currentTime.add(DurationInMinutes, "minute");

  //     addSlot(slotStartTime, slotEndTime);

  //     currentTime = slotEndTime; // Move to the next time slot
  //     i++;
  //   }

  //   return slots;
  // };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const generateTimeSlots = () => {
    const addSlot = (slotStartTime: dayjs.Dayjs, slotEndTime: dayjs.Dayjs) => {
      slots.push({
        key: `${slotStartTime.format("HH:mm")}`,
        time: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>{formatTime(slotStartTime)}</div>
            <div style={{ margin: "-5px 0px" }}>|</div>
            <div>{formatTime(slotEndTime)}</div>
          </div>
        ),
        mon: i < weekData?.mon?.length ? weekData?.mon[i] : null,
        tues: i < weekData?.tues?.length ? weekData?.tues[i] : null,
        wed: i < weekData?.wed?.length ? weekData?.wed[i] : null,
        thu: i < weekData?.thu?.length ? weekData?.thu[i] : null,
        fri: i < weekData?.fri?.length ? weekData?.fri[i] : null,
        sat: i < weekData?.sat?.length ? weekData?.sat[i] : null,
        sun: i < weekData?.sun?.length ? weekData?.sun[i] : null,
      });
    };
    const formatTime = (time: dayjs.Dayjs) => {
      const hour = time.hour();
      const minute = time.minute();
      const period = hour < 12 || hour === 24 ? "AM" : "PM";
      const displayHour = hour === 0 ? 12 : hour <= 12 ? hour : hour - 12;
      const displayMinute =
        minute === 0 ? "00" : String(minute).padStart(2, "0");
      return `${displayHour}:${displayMinute} ${period}`;
    };

    const slots: any = [];
    const weekData: any = {};
    scheduleItemListData?.result?.forEach((s: any) => {
      weekData[s.workDay] = s?.items;
    });

    let i = 0;
    if (scheduleItemListData?.result?.length > 0) {
      scheduleItemListData?.result[0]?.items?.forEach((item: any) => {
        addSlot(dayjs(item.startTime), dayjs(item.endTime));
        i++;
      });
    }
    return slots;
  };

  const durationData = React.useMemo(
    () => generateTimeSlots(),
    [scheduleData, scheduleItemListData]
  );

  // const weakDays: Record<any, any> = {
  //   Monday: "mon",
  //   Tuesday: "tues",
  //   Wednesday: "wed",
  //   Thursday: "thu",
  //   Friday: "fri",
  //   Saturday: "sat",
  //   Sunday: "sun",
  // };

  const getDetails = async ({
    departMentId,
    courseId,
    batchId,
    semesterId,
  }: any) => {
    try {
      setLoading(true);
      let res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.GET_SUBJECT_ALLOCATION_MASTER_DETAILS,
        {},
        {
          departMentId,
          courseId,
          batchId,
          semesterId,
        }
      );
      if (res?.result) {
        let subjectsData = res?.result?.subjects?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });

        setSubjectData(subjectsData);

        let teacherNames = res?.result?.teachers?.map((itm: any) => {
          return {
            value: itm?.teacher?.id,
            label: itm?.teacher?.name,
          };
        });
        setTeachers(teacherNames);
      } else {
        console.log(res.errors);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getScheduleItemData = async (record: any) => {
    const scheduleItem = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_SCHEDULE_ITEM_BY_ID + "?",
      {
        scheduleItemId: record.id,
      }
    );
    if (scheduleItem?.result) {
      const workDaysArray = scheduleItem.result.workDay;
      form.setFieldsValue({
        subject: {
          label: scheduleItem.result?.subject?.name,
          value: scheduleItem.result?.subject?.id,
        },
        title: scheduleItem.result?.title,
        primaryTeacher: {
          label: scheduleItem.result?.primaryTeacher?.name,
          value: scheduleItem.result?.primaryTeacher?.id,
        },

        // "workDay":[scheduleItem.result?.workDay],
        workDay: [
          {
            mon: workDaysArray.includes("mon"),
            tues: workDaysArray.includes("tues"),
            wed: workDaysArray.includes("wed"),
            thu: workDaysArray.includes("thu"),
            fri: workDaysArray.includes("fri"),
            sat: workDaysArray.includes("sat"),
            sun: workDaysArray.includes("sun"),
          },
        ],
        startTime: dayjs(scheduleItem.result?.startTime),
        endTime: dayjs(scheduleItem.result?.endTime),
        room: {
          label: scheduleItem.result?.room?.name,
          value: scheduleItem.result?.room?.id,
        },
      });
    }
  };

  useEffect(() => {
    if (
      scheduleData?.result?.department?.id &&
      scheduleData?.result?.course?.id &&
      scheduleData?.result?.batch?.id &&
      scheduleData?.result?.semester?.id
    ) {
      getDetails({
        departMentId: scheduleData?.result?.department?.id,
        courseId: scheduleData?.result?.course?.id,
        batchId: scheduleData?.result?.batch?.id,
        semesterId: scheduleData?.result?.semester?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData]);

  const columns = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      className: "time-column",
      render: (text: any) => (
        <div
          style={{
            height: "80px",
            width: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Monday",
      dataIndex: "mon",
      key: "mon",
      render: (text: any, record: any) => {
        const isWorkingDay = scheduleData?.result?.workingDays?.[0].mon;
        const contentExists =
          text?.subject?.name ||
          text?.title ||
          text?.primaryTeacher?.name ||
          text?.room?.name;

        const content =
          text?.type === 4 ? (
            <div
              style={{
                backgroundColor: "#F5EFEF",
                height: 90,
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {text?.title ? text?.title : "Break"}
            </div>
          ) : text?.type === 1 || text?.type === 2 || text?.type === 3 ? (
            <div
              onClick={() => getScheduleItemData(text)}
              style={{
                height: 90,
                backgroundColor: text?.primaryTeacher?.name && "whitesmoke",
                width: "100%",
                paddingTop: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.subject?.name}</Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>
                  {text?.primaryTeacher?.name}
                </Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.room?.name}</Col>
              </Row>
            </div>
          ) : (
            ""
          );

        const cellContent = (
          <div
            style={{
              height: 90,
              backgroundColor: !isWorkingDay ? "#F5EFEF" : "",
            }}
          >
            {content}
          </div>
        );

        if (!isWorkingDay || text?.type === 4 || !contentExists) {
          return cellContent;
        }

        return (
          <Popover
            content={
              <div
                style={{
                  width: "100%",
                }}
              >
                <Row gutter={12}>
                  <Col>Subject:</Col>
                  <Col>{text?.subject?.name}</Col>
                </Row>
                {text?.title && (
                  <Row gutter={12}>
                    <Col>Title:</Col>
                    <Col>{text?.title}</Col>
                  </Row>
                )}
                <Row gutter={[1, 8]}>
                  <Col>Teacher:</Col>
                  <Col>{text?.primaryTeacher?.name}</Col>
                </Row>
                <Row gutter={5}>
                  <Col>Room:</Col>
                  <Col>{text?.room?.name}</Col>
                </Row>
              </div>
            }
            title="Details"
          >
            {cellContent}
          </Popover>
        );
      },
    },

    {
      title: "Tuesday",
      dataIndex: "tues",
      key: "tues",
      render: (text: any, record: any) => {
        const isWorkingDay = scheduleData?.result?.workingDays?.[0].tues;
        const contentExists =
          text?.subject?.name ||
          text?.title ||
          text?.primaryTeacher?.name ||
          text?.room?.name;

        const content =
          text?.type === 4 ? (
            <div
              style={{
                backgroundColor: "#F5EFEF",
                height: 90,
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {text?.title ? text?.title : "Break"}
            </div>
          ) : text?.type === 1 || text?.type === 2 || text?.type === 3 ? (
            <div
              onClick={() => getScheduleItemData(text)}
              style={{
                height: 90,
                backgroundColor: text?.primaryTeacher?.name && "whitesmoke",
                width: "100%",
                paddingTop: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.subject?.name}</Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>
                  {text?.primaryTeacher?.name}
                </Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.room?.name}</Col>
              </Row>
            </div>
          ) : (
            ""
          );

        const cellContent = (
          <div
            style={{
              height: 90,
              backgroundColor: !isWorkingDay ? "#F5EFEF" : "",
            }}
          >
            {content}
          </div>
        );

        if (!isWorkingDay || text?.type === 4 || !contentExists) {
          return cellContent;
        }

        return (
          <Popover
            content={
              <div
                style={{
                  width: "100%",
                }}
              >
                <Row gutter={12}>
                  <Col>Subject:</Col>
                  <Col>{text?.subject?.name}</Col>
                </Row>
                {text?.title && (
                  <Row gutter={12}>
                    <Col>Title:</Col>
                    <Col>{text?.title}</Col>
                  </Row>
                )}
                <Row gutter={[1, 8]}>
                  <Col>Teacher:</Col>
                  <Col>{text?.primaryTeacher?.name}</Col>
                </Row>
                <Row gutter={5}>
                  <Col>Room:</Col>
                  <Col>{text?.room?.name}</Col>
                </Row>
              </div>
            }
            title="Details"
          >
            {cellContent}
          </Popover>
        );
      },
    },

    {
      title: "Wednesday",
      dataIndex: "wed",
      key: "wed",
      render: (text: any, record: any) => {
        const isWorkingDay = scheduleData?.result?.workingDays?.[0].wed;
        const contentExists =
          text?.subject?.name ||
          text?.title ||
          text?.primaryTeacher?.name ||
          text?.room?.name;

        const content =
          text?.type === 4 ? (
            <div
              style={{
                backgroundColor: "#F5EFEF",
                height: 90,
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {text?.title ? text?.title : "Break"}
            </div>
          ) : text?.type === 1 || text?.type === 2 || text?.type === 3 ? (
            <div
              onClick={() => getScheduleItemData(text)}
              style={{
                height: 90,
                backgroundColor: text?.primaryTeacher?.name && "whitesmoke",
                width: "100%",
                paddingTop: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.subject?.name}</Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>
                  {text?.primaryTeacher?.name}
                </Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.room?.name}</Col>
              </Row>
            </div>
          ) : (
            ""
          );

        const cellContent = (
          <div
            style={{
              height: 90,
              backgroundColor: !isWorkingDay ? "#F5EFEF" : "",
            }}
          >
            {content}
          </div>
        );

        if (!isWorkingDay || text?.type === 4 || !contentExists) {
          return cellContent;
        }

        return (
          <Popover
            content={
              <div
                style={{
                  width: "100%",
                }}
              >
                <Row gutter={12}>
                  <Col>Subject:</Col>
                  <Col>{text?.subject?.name}</Col>
                </Row>
                {text?.title && (
                  <Row gutter={12}>
                    <Col>Title:</Col>
                    <Col>{text?.title}</Col>
                  </Row>
                )}
                <Row gutter={[1, 8]}>
                  <Col>Teacher:</Col>
                  <Col>{text?.primaryTeacher?.name}</Col>
                </Row>
                <Row gutter={5}>
                  <Col>Room:</Col>
                  <Col>{text?.room?.name}</Col>
                </Row>
              </div>
            }
            title="Details"
          >
            {cellContent}
          </Popover>
        );
      },
    },

    {
      title: "Thursday",
      dataIndex: "thu",
      key: "thu",
      render: (text: any, record: any) => {
        const isWorkingDay = scheduleData?.result?.workingDays?.[0].thu;
        const contentExists =
          text?.subject?.name ||
          text?.title ||
          text?.primaryTeacher?.name ||
          text?.room?.name;

        const content =
          text?.type === 4 ? (
            <div
              style={{
                backgroundColor: "#F5EFEF",
                height: 90,
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {text?.title ? text?.title : "Break"}
            </div>
          ) : text?.type === 1 || text?.type === 2 || text?.type === 3 ? (
            <div
              onClick={() => getScheduleItemData(text)}
              style={{
                height: 90,
                backgroundColor: text?.primaryTeacher?.name && "whitesmoke",
                width: "100%",
                paddingTop: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.subject?.name}</Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>
                  {text?.primaryTeacher?.name}
                </Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.room?.name}</Col>
              </Row>
            </div>
          ) : (
            ""
          );

        const cellContent = (
          <div
            style={{
              height: 90,
              backgroundColor: !isWorkingDay ? "#F5EFEF" : "",
            }}
          >
            {content}
          </div>
        );

        if (!isWorkingDay || text?.type === 4 || !contentExists) {
          return cellContent;
        }

        return (
          <Popover
            content={
              <div
                style={{
                  width: "100%",
                }}
              >
                <Row gutter={12}>
                  <Col>Subject:</Col>
                  <Col>{text?.subject?.name}</Col>
                </Row>
                {text?.title && (
                  <Row gutter={12}>
                    <Col>Title:</Col>
                    <Col>{text?.title}</Col>
                  </Row>
                )}
                <Row gutter={[1, 8]}>
                  <Col>Teacher:</Col>
                  <Col>{text?.primaryTeacher?.name}</Col>
                </Row>
                <Row gutter={5}>
                  <Col>Room:</Col>
                  <Col>{text?.room?.name}</Col>
                </Row>
              </div>
            }
            title="Details"
          >
            {cellContent}
          </Popover>
        );
      },
    },

    {
      title: "Friday",
      dataIndex: "fri",
      key: "fri",
      render: (text: any, record: any) => {
        const isWorkingDay = scheduleData?.result?.workingDays?.[0].fri;
        const contentExists =
          text?.subject?.name ||
          text?.title ||
          text?.primaryTeacher?.name ||
          text?.room?.name;

        const content =
          text?.type === 4 ? (
            <div
              style={{
                backgroundColor: "#F5EFEF",
                height: 90,
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {text?.title ? text?.title : "Break"}
            </div>
          ) : text?.type === 1 || text?.type === 2 || text?.type === 3 ? (
            <div
              onClick={() => getScheduleItemData(text)}
              style={{
                height: 90,
                backgroundColor: text?.primaryTeacher?.name && "whitesmoke",
                width: "100%",
                paddingTop: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.subject?.name}</Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>
                  {text?.primaryTeacher?.name}
                </Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.room?.name}</Col>
              </Row>
            </div>
          ) : (
            ""
          );

        const cellContent = (
          <div
            style={{
              height: 90,
              backgroundColor: !isWorkingDay ? "#F5EFEF" : "",
            }}
          >
            {content}
          </div>
        );

        if (!isWorkingDay || text?.type === 4 || !contentExists) {
          return cellContent;
        }

        return (
          <Popover
            content={
              <div
                style={{
                  width: "100%",
                }}
              >
                <Row gutter={12}>
                  <Col>Subject:</Col>
                  <Col>{text?.subject?.name}</Col>
                </Row>
                {text?.title && (
                  <Row gutter={12}>
                    <Col>Title:</Col>
                    <Col>{text?.title}</Col>
                  </Row>
                )}
                <Row gutter={[1, 8]}>
                  <Col>Teacher:</Col>
                  <Col>{text?.primaryTeacher?.name}</Col>
                </Row>
                <Row gutter={5}>
                  <Col>Room:</Col>
                  <Col>{text?.room?.name}</Col>
                </Row>
              </div>
            }
            title="Details"
          >
            {cellContent}
          </Popover>
        );
      },
    },

    {
      title: "Saturday",
      dataIndex: "sat",
      key: "sat",
      render: (text: any, record: any) => {
        const isWorkingDay = scheduleData?.result?.workingDays?.[0].sat;
        const contentExists =
          text?.subject?.name ||
          text?.title ||
          text?.primaryTeacher?.name ||
          text?.room?.name;

        const content =
          text?.type === 4 ? (
            <div
              style={{
                backgroundColor: "#F5EFEF",
                height: 90,
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {text?.title ? text?.title : "Break"}
            </div>
          ) : text?.type === 1 || text?.type === 2 || text?.type === 3 ? (
            <div
              onClick={() => getScheduleItemData(text)}
              style={{
                height: 90,
                backgroundColor: text?.primaryTeacher?.name && "whitesmoke",
                width: "100%",
                paddingTop: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.subject?.name}</Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>
                  {text?.primaryTeacher?.name}
                </Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.room?.name}</Col>
              </Row>
            </div>
          ) : (
            ""
          );

        const cellContent = (
          <div
            style={{
              height: 90,
              backgroundColor: !isWorkingDay ? "#F5EFEF" : "",
            }}
          >
            {content}
          </div>
        );

        if (!isWorkingDay || text?.type === 4 || !contentExists) {
          return cellContent;
        }

        return (
          <Popover
            content={
              <div
                style={{
                  width: "100%",
                }}
              >
                <Row gutter={12}>
                  <Col>Subject:</Col>
                  <Col>{text?.subject?.name}</Col>
                </Row>
                {text?.title && (
                  <Row gutter={12}>
                    <Col>Title:</Col>
                    <Col>{text?.title}</Col>
                  </Row>
                )}
                <Row gutter={[1, 8]}>
                  <Col>Teacher:</Col>
                  <Col>{text?.primaryTeacher?.name}</Col>
                </Row>
                <Row gutter={5}>
                  <Col>Room:</Col>
                  <Col>{text?.room?.name}</Col>
                </Row>
              </div>
            }
            title="Details"
          >
            {cellContent}
          </Popover>
        );
      },
    },
    {
      title: "Sunday",
      dataIndex: "sun",
      key: "sun",
      render: (text: any, record: any) => {
        const isWorkingDay = scheduleData?.result?.workingDays?.[0].sun;
        const contentExists =
          text?.subject?.name ||
          text?.title ||
          text?.primaryTeacher?.name ||
          text?.room?.name;

        const content =
          text?.type === 4 ? (
            <div
              style={{
                backgroundColor: "#F5EFEF",
                height: 90,
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {text?.title ? text?.title : "Break"}
            </div>
          ) : text?.type === 1 || text?.type === 2 || text?.type === 3 ? (
            <div
              onClick={() => getScheduleItemData(text)}
              style={{
                height: 90,
                backgroundColor: text?.primaryTeacher?.name && "whitesmoke",
                width: "100%",
                paddingTop: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.subject?.name}</Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>
                  {text?.primaryTeacher?.name}
                </Col>
              </Row>
              <Row justify={"center"} gutter={12}>
                <Col style={{ fontSize: "12px" }}>{text?.room?.name}</Col>
              </Row>
            </div>
          ) : (
            ""
          );

        const cellContent = (
          <div
            style={{
              height: 90,
              backgroundColor: !isWorkingDay ? "#F5EFEF" : "",
            }}
          >
            {content}
          </div>
        );

        if (!isWorkingDay || text?.type === 4 || !contentExists) {
          return cellContent;
        }

        return (
          <Popover
            content={
              <div
                style={{
                  width: "100%",
                }}
              >
                <Row gutter={12}>
                  <Col>Subject:</Col>
                  <Col>{text?.subject?.name}</Col>
                </Row>
                {text?.title && (
                  <Row gutter={12}>
                    <Col>Title:</Col>
                    <Col>{text?.title}</Col>
                  </Row>
                )}
                <Row gutter={[1, 8]}>
                  <Col>Teacher:</Col>
                  <Col>{text?.primaryTeacher?.name}</Col>
                </Row>
                <Row gutter={5}>
                  <Col>Room:</Col>
                  <Col>{text?.room?.name}</Col>
                </Row>
              </div>
            }
            title="Details"
          >
            {cellContent}
          </Popover>
        );
      },
    },
  ];

  useEffect(() => {
    if (props?.manageScheduleId) {
      setSelectedSchedule(props?.manageScheduleId);
      form.setFieldValue("schedule", props?.manageScheduleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.manageScheduleId]);

  return (
    <>
      <Spin spinning={scheduleLoading} fullscreen />
      <Row justify={"space-between"}>
        <Col lg={16}>
          <Form form={form}>
            <Row gutter={14}>
              <Col>
                <Form.Item name="schedule">
                  <Select
                    loading={listWithIdNameLoading}
                    options={scheduleOptions}
                    style={{ width: 227 }}
                    placeholder="Choose Schedule"
                    optionFilterProp="children"
                    labelInValue={true}
                    popupMatchSelectWidth={true}
                    // value={selectedSchedule}
                    showSearch
                    onChange={(e: any) => {
                      setSelectedSchedule(e?.value);
                      props?.setManageScheduleId(e?.value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="section">
                  <Select
                    style={{ width: "200px" }}
                    placeholder="Choose section"
                    defaultActiveFirstOption
                    value={selectedSection}
                    options={sectionOptions}
                    optionFilterProp="children"
                    labelInValue={true}
                    popupMatchSelectWidth={true}
                    showSearch
                    onChange={(e: any) => {
                      setSelectedSection(e);
                    }}
                    filterOption={(input, option: any) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col>{/*<Button type="primary"> Get Data </Button>*/}</Col>
            </Row>
          </Form>
          <Row>
            <Col lg={24}>
              <Table
                size="small"
                bordered
                columns={columns}
                pagination={false}
                dataSource={durationData}
                className="custom-table1"
                scroll={{ y: "60vh" }}
              />
            </Col>
          </Row>
        </Col>

        <Col lg={7}>
          <Tabs
            // style={{paddingBottom:"16px"}}
            defaultActiveKey="1"
            items={items}
          />
        </Col>
      </Row>
    </>
  );
};

export default ManageClassSchedule;
