import { DatePicker, Form, FormInstance, Input, message, Select } from "antd";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import HTTPSCalls from "../../../Services/HTTPCalls";
import dayjs from "dayjs";
import { useGetMaster } from "../../../Utilities/tanStackQueries";
import { MasterTypes } from "../../../Constant/Enums";

interface IAddEditCourse {
  data: any;
  form: FormInstance<any>;
  teacherData?: any;
}

const AddEditFaculty = ({ data, form,teacherData }: IAddEditCourse) => {
  const [loading, setLoading] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [date, setDate] = useState<any>("");
  const [dataSource, setDataSource] = useState<any>([]);
  const [listParams, setListParams] = useState({
    roleSearch: "TEACHER",
    status: 0,
    start: 0,
    length: 500,
  });
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const { data: FacultyTypeMaster } = useGetMaster({
    start: 0,
    length: 999,
    MasterType: MasterTypes.FacultyType,
  });

  const getUserList = async () => {
    setLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_USER_LIST + "?",
      listParams
    );
    if (res?.result?.items?.length > 0) {
      setDataSource(
        res?.result?.items?.map((item: any) => ({
          value: item?.id,
          label: item?.name?.first,
          role: item?.role,
          email: item?.email,
          status: item?.status,
          totalRecord: res?.result?.totalRecords,
        }))
      );
      setLoading(false);
    } else {
      setLoading(false);
      messageApi.error(res?.message);
    }
  };
  useEffect(() => {
    getUserList();
    form.setFieldsValue({ joiningDate: dayjs() });
  }, []);
  
  useEffect(() => {
    if (!!data) {
      form.setFieldsValue({ 
        department: data?.department,
        teacherEmpId: data?.teacherEmpId,
        joiningDate: dayjs(data?.joiningDate),
        facultyName: { value: data?.teacher?.id, label: data?.teacher?.name },
        facultyType: {
          value: data?.facultyType?.id,
          label: data?.facultyType?.name,
        },
       });
    };
  },[data])

  const disabledTeacherIds = teacherData.map((item: any) => item.teacher.id);
  console.log(teacherData,"teacherData",disabledTeacherIds);
  const options = dataSource.map((item: any) => ({
    label: item.label,
    value: item.value,
    disabled: disabledTeacherIds.includes(item.value),
  }));

  return (
    <Form
      form={form}
      name="department-form"
      className="DrawerPadding"
      colon={false}
      autoComplete="off"
      style={{ padding: 16 }}
      labelAlign="left"
      requiredMark={false}
      {...formItemLayout}
    >
      <Form.Item
        label="Department"
        name="department"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Department"} disabled />
      </Form.Item>
      <Form.Item
        label="Faculty Name"
        name="facultyName"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select
          labelInValue={true}
          placeholder="Select section"
          options={options}
          filterOption={(input: any, option: any) =>
            (option?.label?.toString() ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item label="Joining Date" name="joiningDate">
        <DatePicker
          // disabledDate={(current) => {
          //   return current && current > dayjs().endOf("day");
          // }}
          format="DD/MM/YYYY"
          //defaultValue={dayjs()}
          style={{ width: "100%" }}
          //onChange={(val) => setDate(dayjs(val)?.format("MM-DD-YYYY"))}
        />
      </Form.Item>
      <Form.Item
        label="Faculty Type"
        name="facultyType"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select
          labelInValue={true}
          allowClear
          placeholder="Select Subjects"
          options={FacultyTypeMaster?.result?.items?.map((item: any) => ({
            value: item?.id,
            label: item?.name,
          }))}
          filterOption={(input: any, option: any) =>
            (option?.label?.toString() ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        label="Teacher Employee Id"
        name="teacherEmpId"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Input
            placeholder="Enter Employee Card Id"
            size="middle"
          />
      </Form.Item>
    </Form>
  );
};

export default AddEditFaculty;
