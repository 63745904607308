import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Spin, Button, Col, Row, Table, Breadcrumb, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  ArrowLeftOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import Search from "antd/es/input/Search";
import MasterService from "../../../Services/MasterService";
import AddEditReminder from "./AddEditReminder";
import dayjs from "dayjs";
import "../.../../../../Custom.css";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { ReminderMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import { useNavigate } from "react-router";

interface IReminder {
  key: string;
  sNo: number;
  id: string;
  name: string;
}
const api = new ApiCalls(ReminderMaster.endPoints, ReminderMaster.prefix);

const Reminder = () => {
  const navigate = useNavigate();
  const columns: ColumnsType<IReminder> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "5%",
      render: (index: any) => <p>{index}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      showSorterTooltip: false,
      sorter: true,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setReminderId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),
    },
    // {
    //   title: "c",
    //   dataIndex: "c",
    //   showSorterTooltip: false,
    //   sorter: true,
    //   key: "c",
    //   render: (text: any) => <p>{dayjs(text).format("MMMM D, YYYY h:mm A")}</p>,

    //   // render:
    // },
    {
      title: " ",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          <FormOutlined
            className="editIconColour me-2"
            onClick={() => {
              setReminderId(record?.id);
              setOpenDrawer(true);
            }}
          />
        </Row>
      ),
    },
  ];
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [reminderId, setReminderId] = useState<string | undefined>();
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [allReminder, setAllReminder] = useState<IReminder[]>();
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "Name",
    sortDir: "ascend",
  });

  useEffect(() => {
    setPageLoader(true);
    get_reminder(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems]);

  const get_reminder = async (start: any, length: any) => {
    await api
      .GET(ReminderMaster.endPoints.getList + "?", {
        masterType: MasterTypes.Reminder,
        start: start,
        length: length,
        search: listParams.search,
        sortCol: listParams.sortCol,
        sortDir: listParams.sortDir,
      })
      .then((res: IApiResponse) => {
        if (/*res?.errors === null &&*/ res?.result) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          setAllReminder(
            res?.result?.items.map((r: any, i: any) => ({
              key: i,
              sno: r?.sno,
              id: r?.id,
              name: dayjs(r?.name).format('h:mm A	'),
              // c: r?.createdBy.date
            }))
          );
          setPageLoader(false);
        } else {
          message.error(res?.message);
          setPageLoader(false);
        }
      });
  };

  const setListParamsAndRefresh = (value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams({ ...listParams, search: value });
  };

  return (
    <>
      <AddEditReminder
        setTrigger={setTrigger}
        openDrawer={openDrawer}
        reminderId={reminderId}
        SetDrawerClose={setOpenDrawer}
      />
      <Row
        justify={"space-between"}
        align="middle"
        style={{ marginBottom: "6px" }}
      >
        <ArrowLeftOutlined
          style={{ color: "#1677ff" }}
          onClick={() => {
            navigate("/settings");
          }}
        />
        
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Reminders",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row justify="space-between">
            <Button
              type="primary"
              className="cit-add-btn mb-1"
              onClick={() => {
                setReminderId(undefined);
                setOpenDrawer(true);
              }}
            >
              <PlusOutlined />
              Reminder
            </Button>

            <Search
              size="middle"
              placeholder="Search..."
              allowClear
              className="att-search-input mb-1"
              onSearch={(val: string) => setListParamsAndRefresh(val)}
              onChange={(e: any) =>
                e.target.value === "" ? setListParamsAndRefresh("") : null
              }
              style={{ width: 170 }}
            />
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "12px" }}>
          <Table
            className="cit-table"
            loading={{
              spinning: pageLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            size="small"
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={allReminder}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: ["15", "25", "50", "100"],
            }}
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                setListParams({
                  ...listParams,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey,
                });
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Reminder;
