import { useEffect, useState } from "react";
import { Col, Modal, Pagination, Row, Table, Tag } from "antd";
import HTTPSCalls from "../../Services/HTTPCalls";
import { ColumnsType } from "antd/es/table/interface";
import { pageSizeOption } from "../LMS/SubjectAlllocation/SubjectAllocation";
import {
  CenterVisited,
  ProcessStepsEnum,
  RecordStatusType,
} from "../../Constant/Enums";
import Search from "antd/es/input/Search";
// import { useSelector } from "react-redux";
// import { selectBatchState } from "../../Store/Slice/batchSlice";

const SourcesViewModal = (props: any) => {
  const { sourceIdName } = props;
  const [search, setSearch] = useState<any>();
  const [viewEnquiryData, setViewEnquiryData] = useState<any>([]);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [modelLoading, setModelLoading] = useState<boolean>(false);
  const [enquiryGroupBySource, setEnquiryGroupBySource] = useState<{
    rejected: number;
    enrolled: number;
    notInterested: number;
  }>({
    rejected: 0,
    enrolled: 0,
    notInterested: 0,
  });
  // useEffect(()=>{
  //   console.log(sourceIdName,"sdfsafasdfasdfs")
  // },[])
  // const [listParams, setListParams] = useState({
  //   streamSearch: "",
  //   batchSearch: "",
  //   start: 0,
  //   length: 15,
  //   search: "",
  //   sortCol: "Student.Name",
  //   sortDir: "ascend",
  //   feesType: "",
  // });
  // const batchState = useSelector(selectBatchState);
  const getFeesData = async () => {
    try {
      setModelLoading(true);
      let res = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.GET_ENQUIRY_BY_SOURCE + "?",
        {
          ...(search && { search: search }),
          source: sourceIdName.id,
          ...(props?.sourceDate && {
            From: props?.sourceDate[0],
            To: props?.sourceDate[1],
          }),
          ...(props?.sourceDate && { sourceFilter: true }),
          // streamSearch: listParams?.streamSearch,
          // // batchSearch: listParams?.batchSearch,
          // batchSearch: batchState,
          start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
          length: pagingItems?.pageSize,
          // sortCol: listParams.sortCol,
          // sortDir: listParams?.sortDir,
          // search: listParams?.search,
          // type: sendCandidateReqType,
          // ...(sendCandidateReqType===CandidateRequestType.Student&&{exists:1})
        }
      );
      if (res?.result?.data?.items?.length >= 0) {
        const result = res?.result?.data?.items;
        setViewEnquiryData(() =>
          result?.map((itm: any, index: number) => {
            return {
              ...itm,
              sno: itm?.sno,
              key: index,
              // student: itm?.student,
              id: itm?.id,
              fullName: itm?.fullName || itm?.name,
              currentClass: itm?.currentClass?.name,
              centerVisited: CenterVisited?.[itm?.centerVisited] || "Unknown",
              batch: itm?.batch?.name,
              contactDetail: itm?.contactDetail?.mobileNumber,
              sources: itm?.sources?.name,
              processStep: itm?.processStep?.processStep,
            };
          })
        );
        setPagingItems((p) => {
          p.totalRecords = res?.result?.data?.totalRecords;
          return p;
        });
        setEnquiryGroupBySource(res?.result?.enquiryGroupBySource);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setModelLoading(false);
    }
  };
  useEffect(() => {
    getFeesData();
  }, [pagingItems, props?.openSourcesModal, search]);

  const columns: ColumnsType<any> = [
    {
      title: "S No",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "fullName",
      ellipsis: true,
      key: "name",
      width: "25%",
      render: (fullName: any) => (
        <span style={{ cursor: "pointer" }}>{fullName}</span>
      ),
    },
    {
      title: "Current Class",
      dataIndex: "currentClass",
      key: "currentClass",
      render: (currentClass: any) => <span>{currentClass}</span>,
    },
    {
      title: "Center Visited",
      dataIndex: "centerVisited",
      render: (centerVisited: any) => <span>{centerVisited}</span>,
    },
    {
      title: "Contact No.",
      dataIndex: "contactDetail",
      render: (contactDetail: any) => <span>{contactDetail}</span>,
    },
    // {
    //     title:"Batch",
    //     dataIndex:"batch",
    //     key:"batch",
    //     render:(batch:any)=><span>{batch}</span>
    // },
    // {
    //     title:"Sources",
    //     dataIndex:"sources",
    //     key:"sources",
    //     render:(sources:any)=><span>{sources}</span>
    // },
    {
      title: "Status",
      dataIndex: "processStep",
      key: "processStep",
      width: "12%",
      align: "center",
      render: (text: any, record: any, index: any) => {
        var status = "";
        var color = "";

        if (record?.reject) {
          status = "Rejected";
          color = "red";
        } else if (record?.recordStatus === RecordStatusType.Deleted) {
          status = "Not interested";
          color = "gold";
        } else if (record?.isEnrolled) {
          status = "Enrolled";
          color = "green";
        } else if (text === ProcessStepsEnum.New) {
          status = "Not interested";
          color = "gold";
        } else {
          status = "Interested";
          color = "blue";
          // status = ProcessStepsEnumName[text];
        }
        return (
          <Tag
            color={color}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ textAlign: "center" }}>
              {status}
              {/* {ProcessStepsEnumName[text]} */}
            </span>
          </Tag>
        );
      },
    },
  ];
  return (
    <>
      <Modal
        width={1100}
        footer={false}
        title={`Sources (${sourceIdName.name})`}
        open={props?.openSourcesModal}
        onCancel={() => props?.setOpenSourcesModal((p: any) => !p)}
      >
        <Row gutter={[16, 16]} align="middle" justify={"space-between"}>
          <Col span={12} style={{ display: "flex" }}>
          <Col>
            <Tag
              color="blue"
              style={{
                width: "120px", // Set a fixed width
                textAlign: "center", // Ensure text is centered
              }}
            >
              Interested:{" "}
              <b>
                {(pagingItems.totalRecords || 0) -
                  (enquiryGroupBySource.enrolled +
                    enquiryGroupBySource.notInterested +
                    enquiryGroupBySource.rejected)}
              </b>
            </Tag>
          </Col>
          <Col>
            <Tag
              color="gold"
              style={{
                width: "120px",
                textAlign: "center",
              }}
            >
              Not Interested: <b>{enquiryGroupBySource.notInterested}</b>
            </Tag>
          </Col>
          <Col>
            <Tag
              color="red"
              style={{
                width: "120px",
                textAlign: "center",
              }}
            >
              Rejected: <b>{enquiryGroupBySource.rejected}</b>
            </Tag>
          </Col>
          <Col>
            <Tag
              color="green"
              style={{
                width: "120px",
                textAlign: "center",
              }}
            >
              Enrolled: <b>{enquiryGroupBySource.enrolled}</b>
            </Tag>
          </Col>
          </Col>
              <Col span={4}>
              <Search
                  size="small"
                  placeholder="Search..."
                  allowClear
                  className="att-search-input"
                  onSearch={(val: string) => {
                    setSearch(val);
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
        </Row>

        <Row>
          <Col span={24} style={{ paddingTop: "10px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ overflowY: "auto", flex: "1" }}>
                <Table
                  size="small"
                  className="Tabel-style"
                  dataSource={viewEnquiryData}
                  loading={modelLoading}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: "calc(100vh - 400px)", x: 600 }}
                  // summary={() => {
                  //   return (
                  //     // <Table.Summary fixed >
                  //     //   <Table.Summary.Row >
                  //     //     <Table.Summary.Cell index={0}>
                  //     //       <b> Total</b>
                  //     //     </Table.Summary.Cell>
                  //     //     <Table.Summary.Cell index={1}> <Tag color="green">  Enrolled:<b> {enquiryGroupBySource.enrolled}</b></Tag></Table.Summary.Cell>
                  //     //     <Table.Summary.Cell index={1}> <Tag color="blue" >  Interested :<b> {(pagingItems.totalRecords ||0)- (enquiryGroupBySource.enrolled+enquiryGroupBySource.notInterested+enquiryGroupBySource.rejected)}</b></Tag></Table.Summary.Cell>
                  //     //     <Table.Summary.Cell index={1}> <Tag color="gold">  Not Interested:<b> {enquiryGroupBySource.notInterested}</b></Tag></Table.Summary.Cell>
                  //     //     <Table.Summary.Cell index={1}> <Tag color="red">  Rejected :<b> {enquiryGroupBySource.rejected}</b></Tag></Table.Summary.Cell>
                  //     //   </Table.Summary.Row>
                  //     // </Table.Summary>
                  //   );
                  // }}
                />
              </div>
              <Pagination
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                current={pagingItems.currentPage}
                pageSize={pagingItems.pageSize}
                showSizeChanger
                total={pagingItems.totalRecords}
                pageSizeOptions={pageSizeOption}
                onChange={(page, pageSize) => {
                  setPagingItems({
                    ...pagingItems,
                    currentPage: page,
                    pageSize,
                  });
                }}
                style={{ marginTop: "16px", textAlign: "right" }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SourcesViewModal;
