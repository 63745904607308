
import { Avatar, Modal, Space, Table } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import NoImg from "../../images/NoImage.png"
const HolidaysTable = (props: any) => {
  // Ensure only top 7 holidays are shown
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const topHolidays = props?.data?.slice(0, 7);
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      text: "center",
      ellipsis: true,
      key: "sNo",
      width: "9%",
      render: (_: any, record: any, index: number) => <p>{index + 1}</p>,
    },
    {
      title: "Holidays",
      dataIndex: "name",
      key: "name",
      width: "25%",
      render: (text: any, record: any) => {
        return (
          <Space style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              className="cursor"
              shape="square"
              style={{ border:"1px solid lightgray" }}
              onClick={() => {
                setPreviewVisible(record?.attachments?.[0]?.fileName?.path? true:false);
                setPreviewImage(
                  record?.attachments?.[0]?.fileName?.path ||
                    record?.attachments?.[0]?.base64 ||
                    ""
                );
                setImageTitle(text?.[0]?.fileName);
              }}
              size={20}
              src={record?.attachments?.[0]?.fileName?.path || NoImg}
            />
            <span>{text}</span>
          </Space>
        );
      },
    },
    {
      title: "From",
      dataIndex: "fromTo",
      key: "From",
      width: "25%",
      render: (text: any) => (
        <p>{dayjs.utc(text?.from).format("DD MMM YYYY")}</p>
      ),
    },
    {
      title: "To",
      dataIndex: "fromTo",
      key: "To",
      width: "25%",
      render: (text: any) => <p>{dayjs.utc(text?.to).format("DD MMM YYYY")}</p>,
    },
  ];
  const handleCancel = () => setPreviewVisible(false);
  return (
    <>
      <Table
        className="mt-2 dashBoardTable-Font masterList"
        rowKey={(record) => record?.id}
        style={{
          width: "100vw",
          borderBottom: "none",
          // maxHeight: 730,
          fontSize: "13px",
          overflow: "auto",
          minHeight: 240,
          height: "100%",
        }}
        size="small"
        scroll={{ y: 210 }}
        pagination={false}
        dataSource={topHolidays}
        columns={columns}
        // loading={pageLoader}
      />
      <Modal
        open={previewVisible}
        title={imageTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default HolidaysTable;
