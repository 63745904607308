import {
  BellOutlined,
  CloseOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Space,
  Upload,
} from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { MasterTypes } from "../../../Constant/Enums";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { NoticeMaster } from "../../../ApiUrls/URLS";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import { FilesViewer } from "../../../Common/FileViewer";
interface IDrawerPorps {
  open: boolean;
  setOpen: any;
  setTrigger: any;
  recordData: any;
  trigger: any;
}
const AddEditNotice = ({
  open,
  setOpen,
  setTrigger,
  trigger,
  recordData,
}: IDrawerPorps) => {
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewFile, setPreviewFile] = useState<string | null |any>(null);
  const onsubmit = async () => {
    form.validateFields().then(async (values: any) => {
      setLoading(true);
      const attachments = fileList.map((file) => {
        // console.log(file.Type,"contenttype====");
        // const isUploaded = !!file.url;
        return {
          Filename: {
            Id: file?.id || null,
            Name: file?.name,
            ContentType: file.type,
            Path:file?.path || file?.base64,
            Length: file?.size || file?.response?.size,
          },
        };
      });
      let noticeDetial = {
        masterType: MasterTypes.Notice,
        id: recordData?.id,
        name: values?.title,
        description: values?.description,
        attachments: attachments,
      };
      HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.POST_MASTER,
        {},
        { ...noticeDetial }
      ).then((res: IApiResponse) => {
        if (res?.status) {
          // console.log(res,"postres=")
          setOpen(false);
          form.resetFields();
          setOpen(false);
          message.success(
            recordData?.id
              ? "Notice updated successfully"
              : "Notice added successfully"
          );
          setTrigger((prev: boolean) => !prev);
        } else {
          message.warning(res?.message);
        }
        setLoading(false);
      });
    });
  };
  useEffect(() => {
    if (open) {
      console.log(recordData?.id,"recorddataid");
      
      if (recordData?.id) {
        form.setFieldsValue({
          title: recordData?.name,
          description: recordData?.description,
          attachments: recordData?.attachments?.map((file:any)=>file?.fileName)
        });
        setFileList(recordData?.attachments?.map((file:any)=>{
          return {
            id:file?.fileName?.id,
            name:file?.fileName?.name,
            type:file?.fileName?.contentType,
            size:file?.fileName?.length,
            path: file?.fileName?.path,
          }
        }));
      } else if (open) {
        form.resetFields();
      }
    }
  }, [open, recordData, form]);
  // const onChange = async ({ fileList: newFileList }: any) => {
  //   // debugger
  //   const updatedFileList = await Promise.all(
  //     newFileList.map(async (file: any) => {
  //       if (file.originFileObj && !file.url) {
  //         const base64 = await getBase64(file.originFileObj);
  //         return {
  //           ...file,
  //           base64,
  //         };
  //       }
  //       return file;
  //     })
  //   );
  //   setFileList(updatedFileList);
  // };

  const onChange = async ({ fileList: newFileList }: any) => {
    console.log(":newFileList",newFileList);
    
    const oldFileList = newFileList.filter((file:any)=>file.id);
    newFileList = newFileList.filter((file:any)=>!file?.id);
    const validFileList = newFileList.filter((file: any) => {
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf";
      if (!isValidFileType) {
        message.error("You can only upload JPG or PDF files!");
      }
      return isValidFileType;
    });

    const updatedFileList = await Promise.all(
      validFileList.map(async (file: any) => {
        if (file.originFileObj && !file.url) {
          const base64 = await getBase64(file.originFileObj);
          return {
            ...file,
            base64,
          };
        }
        return file;
      })
    );

    setFileList(updatedFileList.concat(oldFileList));
  };

  const getBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const onPreview = async (file: any) => {
    // console.log("fileName",file);
    const fileSrc = file?.url || file?.base64 || file?.path || "";
    setPreviewFile(fileSrc);
    setPreviewImage(file?.url || file?.base64 || file?.path|| "");
    setPreviewTitle(file?.name || "Preview");
    setPreviewVisible(true);
  };
  const onClosePreview = () => {
    setPreviewFile(null);
  };
  return (
    <>
      <Drawer
        open={open}
        onClose={() => {
          setOpen((prev: boolean) => !prev);
          form.resetFields();
          setFileList([]);
        }}
        title={
          <>
            {recordData?.id ? "Edit Notice" : "Add Notice"} <BellOutlined />{" "}
          </>
        }
        footer={
          <Row gutter={12} justify={"end"}>
            <Col>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => onsubmit()}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        }
      >
        <Form form={form} layout="vertical">
          <Row className="p-4">
            <Col span={24}>
              <Form.Item
                name={"title"}
                label="Title"
                rules={[{ required: true, message: "Please input title" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"description"}
                label="Description"
                rules={[
                  { required: true, message: "Please input description" },
                ]}
              >
                <TextArea rows={5} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name={"attachments"} label="Attachments">
                <Upload
                  className="cursor"
                  onPreview={onPreview}
                  onChange={onChange}
                  fileList={fileList}
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess?.("ok");
                    }, 1000);
                  }}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Row>
                  {/* <Col xs={10} sm={8}>
                    <Form.Item label=" " colon={false}></Form.Item>
                  </Col> */}
                  <Col xs={24} sm={24}>
                    <div
                      style={{ color: "rgba(0, 0, 0, 0.5)", fontSize: "11px" }}
                    >
                      Note: Documents should be only in the format of Image
                      & PDF.
                    </div>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            {previewFile && (
              <>
              <Drawer title={<Space size={680}><span>Preview</span><Button size="small" onClick={()=>setPreviewFile(null)}><CloseOutlined/></Button></Space>} width={800} open={previewFile}>
          
              <iframe
                   title={previewTitle}
                    src={previewFile}
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "1px solid #d9d9d9",
                      borderRadius: "4px",
                    }} />
              </Drawer>
              
              <Col span={24} style={{ marginTop: "16px" }}>
                <div>
                  <Button
                    type="link"
                    onClick={onClosePreview}
                    style={{ marginBottom: "8px" }}
                  >
                    Close Preview
                  </Button>
          
                </div>
              </Col>
              
              </>
            )}
          </Row>
        </Form>
      </Drawer>

      {/* <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <Image alt={previewTitle} style={{ width: "100%" }} src={previewImage} />
      </Modal> */}
    </>
  );
};

export default AddEditNotice;
