import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Table,
  TableProps,
} from "antd";
import React, { useEffect, useState } from "react";
import HTTPSCalls from "../../../Services/HTTPCalls";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  useGetResultByDesignCourse,
  // useSaveAllSubjectMarks,
} from "../../../Utilities/tanStackQueries";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { ApiUtility } from "../../../Utilities/ApiUtility.axios";

function SingleStudentResult() {
  const [loading, setLoading] = useState<boolean>(false);
  const { RangePicker } = DatePicker;
  const [selectedDate, setSelectedDate] = useState<any>([]);
  const [departMentData, setDepartMentData] = useState();
  const [courseData, setCourseData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [sectionsData, setSectionsData] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  const [categoryId, setCategoryId] = useState<string>("");
  // const [allStudentDetail, setAllStudentDetail] = useState<any>();
  const [studentId, setStudentId] = useState<any>("");
  const [trigger, setTrigger] = React.useState<number>(0);
  const [formVal, setFormval] = useState<any>();
  const [form] = Form.useForm();
  // const [inputForm] = Form.useForm();
  const navigate = useNavigate();
  // const saveAllSubjectMarksMutation = useSaveAllSubjectMarks();
  const [csvLoading, setCsvLoading] = useState(false);
  const getDesignCourseData = async (formValues: any) => {
    try {
      setLoading(true);
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.GET_SUBJECT_ALLOCATION_MASTER_DETAILS + "?",
        {},
        {
          departMentId: formValues?.department?.value || "",
          courseId: formValues?.course?.value || "",
          batchId: formValues?.batch?.value || "",
          semesterId: formValues?.semester?.value || "",
          sectionId: formValues?.section?.value || "",
        }
      );
      if (res?.result) {
        // console.log(res?.result, "resresult");
        let departmentData = res?.result?.departments?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setDepartMentData(departmentData);
        let courseData = res?.result?.courses?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setCourseData(courseData);

        let batchData = res?.result?.batches?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setBatchData(batchData);
        let semesterData = res?.result?.semesters?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSemesterData(semesterData);
        let sectionsData = res?.result?.sections?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSectionsData(sectionsData);
        let subjectsData = res?.result?.subjects?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSubjectsData(subjectsData);
      }
    } catch (error) {
      console.error("something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const formValues = form.getFieldsValue();
    getDesignCourseData(formValues);
    setFormval(formValues);
  }, [trigger]);

  const {
    data: studentsData,
    isLoading: studentLoading,
    refetch: studentRefetch,
  } = useGetResultByDesignCourse(form.getFieldsValue(), selectedDate);

  const columns: TableProps<any>["columns"] = [
    {
      title: "Assessment Name",
      dataIndex: "assessmentDetails",
      key: "assessmentDetails.",
      width: "14%",
      render: (text, record: any) => {
        return (
          <div>
            {/* {console.log(record, "record")} */}
            {record.assessmentDetails?.assessmentName}
          </div>
        );
      },
    },
    {
      title: "Category Name",
      dataIndex: "category",
      key: "category",
      render: (text, record: any) => record?.assessmentDetails?.category?.name,
    },

    {
      title: "Count in Overall",
      dataIndex: "isCountOverallScore",
      key: "isCountOverallScore",
      render: (text, record: any) =>
        record?.assessmentDetails?.isCountOverallScore ? "Yes" : "No",
    },

    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text, record: any) => record?.subject?.name,
    },
    {
      title: "Obatined Marks",
      dataIndex: "semester",
      key: "semester",
      render: (text, record: any) => {
        let studentMarks = record?.students?.find(
          (itm: any) => itm.id === studentId
        );
        return <div>{studentMarks?.obtainedMarks}</div>;
      },
    },
    {
      title: "Passing Marks",
      dataIndex: "passingMarks",
      key: "passingMarks",
      render: (text, record: any) => record?.passingMarks,
    },
    {
      title: "Max Marks",
      dataIndex: "maxMarks",
      key: "maxMarks",
      render: (text, record: any) => record?.maxMarks,
    },
  ];
  useEffect(() => {
    setSelectedDate([dayjs.utc().startOf("month"), dayjs.utc()]);
  }, []);

  const handleFieldChange = (field: string, value: any) => {
    switch (field) {
      case "department":
        form.resetFields([
          "course",
          "batch",
          "section",
          "semester",
          "student",
          "category",
        ]);
        break;
      case "course":
        form.resetFields([
          "batch",
          "section",
          "semester",
          "student",
          "category",
        ]);
        break;
      case "batch":
        form.resetFields(["section", "semester", "student", "category"]);
        break;
      case "semester":
        form.resetFields(["section", "student", "category"]);
        break;
      case "section":
        form.resetFields(["student", "category"]);
        break;
      default:
        break;
    }
    setStudentId("");
    setTrigger((prev) => prev + 1);
  };
  // const gap = 5;
  const handleDownloadCsv = async () => {
    try {
      setCsvLoading(true);
      // console.log(formVal, "formvalues=====");
      // console.log(selectedDate, "selectedData//////");

      await ApiUtility.downloadFile(
        HTTPSCalls.ENDPOINTS.GET_SINGLE_STUDENT_CSV + "?",
        {
          departMentId: formVal?.department?.value || "",
          courseId: formVal?.course?.value || "",
          batchId: formVal?.batch?.value || "",
          semesterId: formVal?.semester?.value || "",
          sectionId: formVal?.section?.value || "",
          studentId: studentId,
          categoryId: categoryId,
          date: {
            from: selectedDate[0]?.format("YYYY-MM-DD") || "",
            to: selectedDate[1]?.format("YYYY-MM-DD") || "",
          },
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setCsvLoading(false);
    }
  };
  return (
    <>
      <ArrowLeftOutlined onClick={() => navigate(-1)} />
      <Card className="roundedCornerSmall mt-2">
        <Form form={form}>
          {/* First Row */}
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"department"}
                label="Department"
                className="AlignStart"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={departMentData}
                  onChange={(value) => handleFieldChange("department", value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"course"}
                label="Course"
                className="AlignStart"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={courseData}
                  onChange={(value) => handleFieldChange("course", value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"batch"}
                label="Batch"
                className="AlignStart"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={batchData}
                  onChange={(value) => handleFieldChange("batch", value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"semester"}
                label="Semester"
                className="AlignStart"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={semesterData}
                  onChange={(value) => handleFieldChange("semester", value)}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Second Row */}
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"section"}
                label="Section"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={sectionsData}
                  onChange={(value) => handleFieldChange("section", value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"student"}
                label="Student"
                className="AlignStart"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={studentsData?.result?.students.map((itm: any) => ({
                    value: itm.id,
                    label: itm.name,
                  }))}
                  loading={studentLoading}
                  onSelect={(val: any) => {
                    setStudentId(val.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name={"category"}
                label="Category"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue={true}
                  options={studentsData?.result?.category.map((itm: any) => ({
                    value: itm.id,
                    label: itm.name,
                  }))}
                  loading={studentLoading}
                  onChange={(val: any) => {
                    setCategoryId(val?.value);
                    studentRefetch();
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Select Date"
                className="AlignStart"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <RangePicker
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  value={selectedDate}
                  onChange={(dates: any, dateStrings: [string, string]) => {
                    setSelectedDate([dayjs.utc(dates[0]), dayjs.utc(dates[1])]);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <Col span={24}>
              <Table
                footer={() => (
                  <Row justify={"end"}>
                    <Col>
                      <Button
                        loading={csvLoading}
                        icon={<DownloadOutlined />}
                        onClick={handleDownloadCsv}
                        disabled={
                          studentsData?.result?.assessmentSubjectDetails
                            .length > 0
                            ? false
                            : true
                        }
                      >
                        Export
                      </Button>
                    </Col>
                  </Row>
                )}
                size="small"
                columns={columns}
                dataSource={studentsData?.result?.assessmentSubjectDetails}
                style={{ marginTop: 14 }}
                loading={{
                  spinning: studentLoading,
                  indicator: <Spin indicator={<LoadingOutlined />} />,
                }}
                pagination={false}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default SingleStudentResult;
