import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  InputRef,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import * as React from "react";
import { useContext, useState, useEffect,useRef } from "react";
import { adminContext } from "../../Common/PageRoute";
import { MasterTypes } from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import { IEnquiryModelType } from "../../Enquiry/EnquiryLead";

interface IAddCredentials {
  open: boolean;
  id: string;
  onAdd: (rec: boolean) => void;
  onCancel: () => void;
  apiData:any
}
const AddInOption = ({ funcType, setRefresh }: any) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const { openNotification } = React.useContext(adminContext);
  const onNameChange = (e: any) => {
    setName(e?.target?.value);
  };
  const addOption = async (e: any) => {
    e?.stopPropagation();
    if (!name || name?.trim() === "") {
      openNotification("error", "Option cant'be empty.");
    } else {
      if (funcType === "credential") {
        setLoading(true);
        let val = {
          masterType: MasterTypes.Credential,
          name: name,
        };
        await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.POST_MASTER, {}, val).then(
          (res: any) => {
            if (res?.status) {
              setRefresh((x: boolean) => !x);
              openNotification("success", "Credential saved successfully");
              setName("");
              setLoading(false);
            } else {
              openNotification("error", res?.message);
              setLoading(false);
            }
          }
        );
      }
    }
  };
  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Row
        gutter={[0, 16]}
        justify={"space-around"}
        style={{ padding: "8px 8px 8px 5px" }}
      >
        <Col sm={19}>
          <Input
            placeholder="Enter Option"
            ref={inputRef}
            value={name}
            onChange={onNameChange}
            onPressEnter={addOption}
            size={
              funcType === "class" ||
              funcType === "document" ||
              funcType === "status" ||
              funcType === "credential"
                ? "small"
                : "middle"
            }
          />
        </Col>
        <Col sm={3}>
          <Button
            type="primary"
            ghost
            icon={<PlusOutlined />}
            onClick={addOption}
            loading={loading}
            size={
              funcType === "class" ||
              funcType === "document" ||
              funcType === "status" ||
              funcType === "credential"
                ? "small"
                : "middle"
            }
          />
        </Col>
      </Row>
    </>
  );
};

const AddCredentialsModel = ({
  id,
  onAdd,
  onCancel,
  open,
  apiData
}: IAddCredentials) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [modelLoading, setModelLoading] = React.useState<boolean>(false);
  const [masterLoading, setMasterLoading] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [masterData, setMasterData] = React.useState<{
    credentials: any;
  }>({
    credentials: [],
  });
  const getMaster = async () => {
    setMasterLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });
      setMasterLoading(false);
      let obj: {
        document: any;
        credentials: any;
      } = {
        document: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Documents
        )?.[0]?.items,
        credentials: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Credential
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
    } else {
      message.error(res?.message);
    }
  };
  React.useEffect(() => {
    getMaster();
  }, [refresh]);

  useEffect(()=>{
    if(apiData)
      {
        form.setFieldsValue({credentials:apiData?.credentials?.map(
          (arrayItem: any, index: any) => ({
            ...arrayItem,
            key: index,
            credentialName: {
              value: arrayItem?.credentialName?.id,
              label: arrayItem?.credentialName?.name,
            },
          })
        )})
      }
  },[])

  const onSubmit = () => {
    form.validateFields().then(async (val: any) => {
      let formVal = val?.credentials?.map((arrayItem: any) => {
        return {
          credentialName: {
            id: arrayItem?.credentialName?.value,
            name: arrayItem?.credentialName?.label,
          },
          email: arrayItem?.email,
          password: arrayItem?.password,
          number: arrayItem?.number,
        };
      })
      setLoading(true);
      setModelLoading(true);
      let response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.ENQUIRY_ADD_CREDENTIALS+"?",
        {id:id},
        formVal
      );
      setLoading(false);
      if (response?.status) {
        message.success("Credential Submitted");
        onAdd(true);
      } else {
        message.error(response?.message);
      }
    });
  };

  return (
    <>
      <Modal
        title={"LSBU - Add Credentials"}
        open={open}
        maskClosable={false}
        onCancel={onCancel}
        width={850}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onSubmit} type="primary" loading={loading}>
              Save
            </Button>
          </Space>
        }
      >
        <Divider className="marginDivider" />

        <Spin
          spinning={modelLoading || masterLoading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <div style={{ maxHeight: "68vh", overflowY: "auto", padding: "7px" }}>
            <Form form={form} requiredMark={false}>
              <Form.List name="credentials">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <>
                        <Row gutter={[10, 16]}>
                          <Col md={5} xl={5} lg={5}>
                            <Form.Item
                              name={[name, "credentialName"]}
                              {...restField}
                              rules={[
                                {
                                  required: true,
                                  message: "field is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{
                                  width: "100%",
                                }}
                                placeholder="Select Credentials"
                                optionFilterProp="children"
                                labelInValue
                                allowClear
                                popupMatchSelectWidth={true}
                                filterOption={(input, option) =>
                                  (option?.label?.toString() ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <AddInOption
                                      funcType={"credential"}
                                      setRefresh={setRefresh}
                                    />
                                  </>
                                )}
                                options={masterData?.credentials}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={6} xl={6} lg={6}>
                            <Form.Item
                              name={[name, "email"]}
                              rules={[
                                {
                                  type: "email",
                                  message: "Please input valid email!",
                                },
                                {
                                  required: true,
                                  message: "this field is required",
                                },
                              ]}
                              {...restField}
                            >
                              <Input placeholder="Enter Email" />
                            </Form.Item>
                          </Col>
                          <Col md={6} xl={6} lg={6}>
                            <Form.Item
                              name={[name, "password"]}
                              {...restField}
                              rules={[
                                {
                                  required: true,
                                  message: "field is required",
                                },
                              ]}
                            >
                              <Input placeholder="Enter Password" />
                            </Form.Item>
                          </Col>
                          <Col md={5} xl={5} lg={5}>
                            <Form.Item
                              name={[name, "number"]}
                              {...restField}
                              rules={[
                                {
                                  required: true,
                                  message: "field is required",
                                },
                              ]}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                placeholder="Enter Number"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={2} xl={2} lg={2}>
                            <Button
                              style={{
                                marginBottom: "24px",
                              }}
                              onClick={() => remove(index)}
                              icon={<DeleteOutlined className="cursor deleteIconColour"/>}
                            ></Button>
                          </Col>
                        </Row>
                      </>
                    ))}

                    <Form.Item className="inputBoxMb">
                      <Row>
                        <Col>
                          <Button
                            type="primary"
                            ghost
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            disabled={fields[4]?.name === 4 ? true : false}
                          >
                            Credentials
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default AddCredentialsModel;
