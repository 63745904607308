import { useState } from "react";
import StudentAttendance from "./StudentAttendance";
import { Col, Row, Tabs, TabsProps } from "antd";
import EnquiryDashboard from "./EnquiryDashboard";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState<string>("1");
  const changeTab = (activeKey:string) => {
    setActiveTab(activeKey);
  };
  const TabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Dashboard",
      children: (
        <StudentAttendance/>
      ),
    },
    {
      key: "2",
      label: "Enquiries",
      children: (
        <EnquiryDashboard/>
      ),
    },
  ];
  
  return (
    <div>
      <Row>
          <Col lg={24}>
            <Tabs
            className="dahsboard-tabs"
              activeKey={activeTab}
              onChange={changeTab}
              defaultActiveKey="1"
              items={TabItems}
              style={{
                height: "821px"
            }}
            />
          </Col>
        </Row>
    </div>
  );
};

export default Dashboard;
