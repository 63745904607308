import * as React from "react";
import { useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  Checkbox,
  DatePicker,
  message,
  Upload,
  Modal,
} from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import dayjs from "dayjs";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { HolidaysMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import ImgCrop from "antd-img-crop";

interface AddEditHolidayIn {
  setTrigger: any;
  openDrawer: any;
  holidayId: string | undefined;
  SetDrawerClose: any;
}
const { RangePicker } = DatePicker;
const api = new ApiCalls(HolidaysMaster.endPoints, HolidaysMaster.prefix);

const AddEditHoliday = (props: AddEditHolidayIn) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const saveData = () => {
    console.log("fileList", fileList);
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);

      const attachments = fileList.map((file) => {
        // console.log(file.Type,"contenttype====");
        // const isUploaded= !!file.url;
        return {
          Filename: {
            Id: file?.id || null,
            Name: file?.name,
            ContentType: file.type,
            Path: file?.base64 || file?.url,
            Length: file?.size || file?.response?.size,
          },
        };
      });
      let val = {
        ...values,
        id: props?.holidayId,
        masterType: MasterTypes.Holidays,
        name: values?.name,
        fromTo:
          values?.fromTo === undefined
            ? null
            : {
                from: dayjs(values?.fromTo[0]).format("MM-DD-YYYY"),
                to: dayjs(values?.fromTo[1]).format("MM-DD-YYYY"),
              },
        isActive: values?.isActive,
        attachments,
      };
      // console.log("val = ", val)
      await api
        .POST(HolidaysMaster.endPoints.save, null, val)
        .then((res: any) => {
          if (res.status) {
            onClose();
            openNotification(
              "success",
              props?.holidayId
                ? "Holiday updated successfully"
                : "Holiday saved successfully"
            );
            props?.setTrigger((x: boolean) => !x);
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
    });
  };

  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.holidayId) {
        setLoading(true);
        getHolidayById(props?.holidayId);
      }
    }
  }, [props?.openDrawer]);
  const onChange = async ({ fileList: newFileList }: any) => {
    // debugger
    const updatedFileList = await Promise.all(
      newFileList.map(async (file: any) => {
        if (file.originFileObj && !file.url) {
          const base64 = await getBase64(file.originFileObj);
          return {
            ...file,
            base64,
          };
        }
        return file;
      })
    );
    setFileList(updatedFileList);
  };
  const getBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const getHolidayById = async (id: any) => {
    setLoading(true);
    await api
      .GET(HolidaysMaster.endPoints.getById + "/" + id + "?", {
        masterType: MasterTypes.Holidays,
      })
      .then((data: IApiResponse) => {
        if (data?.result) {
          let res = data?.result;

          form.setFieldsValue({
            name: res?.name,
            isActive: res?.isActive,
            fromTo: [dayjs.utc(res?.fromTo?.from), dayjs.utc(res?.fromTo?.to)],
          });
          if (res?.attachments && res.attachments.length > 0) {
            const uploadedFiles = res.attachments.map(
              (file: any, index: number) => ({
                uid: `-1-${index}`,
                name: file?.fileName?.name,
                id: file?.fileName?.id,
                url: file?.fileName?.path,
                size: file?.fileName?.length,
                type: file?.fileName?.contentType,
              })
            );
            setFileList(uploadedFiles);
          }

          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };

  // const getHolidayById = async (id: any) => {
  //   setLoading(true);
  //   await api
  //     .GET(HolidaysMaster.endPoints.getById + "/" + id + "?", {
  //       masterType: MasterTypes.Holidays,
  //     })
  //     .then((data: IApiResponse) => {
  //       if (data?.result) {
  //         let res = data?.result;
  //         form.setFieldsValue({
  //           name: res?.name,
  //           isActive: res?.isActive,
  //           fromTo: [dayjs.utc(res?.fromTo?.from), dayjs.utc(res?.fromTo?.to)],
  //         });
  //         setLoading(false);
  //       } else {
  //         message.error(data?.message);
  //         setLoading(false);
  //       }
  //     });
  // };
  // const onPreview = async (file: any) => {
  //   let src = file.url;
  //   const image = new Image();
  //   image.src = src;
  //   const w = window.open(src);
  //   w?.document.write(image.outerHTML);
  // };
  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
    setFileList([]);
  };
  const onPreview = async (file: any) => {
    setPreviewImage(file.url || file.base64 || "");
    setPreviewTitle(file.name || "Preview");
    setPreviewVisible(true);
  };
  const handleCancel = () => setPreviewVisible(false);
  return (
    <>
      <Drawer
        title={props?.holidayId ? "Edit Holiday" : "Add Holiday"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.holidayId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="holiday-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            size="small"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please enter holiday name!" },
              ]}
            >
              <Input
                autoFocus
                placeholder="Enter Holiday Name"
                size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Holidays"
              name="fromTo"
              rules={[
                {
                  required: true,
                  message: "Select Holiday Date",
                },
              ]}
            >
              <RangePicker
                size="middle"
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
              />
            </Form.Item>
            <Form.Item label=" " name="isActive" valuePropName="checked">
              <Checkbox>Active</Checkbox>
            </Form.Item>
            <Form.Item name="attachments">
              <ImgCrop rotationSlider>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  customRequest={({ file, onSuccess }) => {
                    // Simulate an upload success after some delay
                    setTimeout(() => {
                      onSuccess?.("ok");
                    }, 1000);
                  }}
                >
                  {fileList.length < 1 && "+ Upload"}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default AddEditHoliday;
