import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CardModel from "../../pages/Students/CardModel";
import HTTPSCalls from "../../Services/HTTPCalls";
import { adminContext } from "../../Common/PageRoute";
import ConfirmationModel from "../../Common/ConfirmationModel";

interface CourseDataInterface {
  apiData: any;
}

const { Text } = Typography;

const EnquirySettings: React.FC<CourseDataInterface> = ({ apiData }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [key, setKey] = useState<string | null>(null);
  const [cardData, setCardData] = useState<any>(null);
  const [fetchCard, setFetchCard] = useState<number>(0);
  const [ecard, setEcard] = useState<any>(null);

  const { openNotification } = React.useContext(adminContext);

  const onMenuitemClick = ({ key }: any) => {
    if (key === "edit") {
      setModalOpen(true);
      setCardData(ecard);
    } else if (key === "resync") {
      setKey("resync");
      setConfirmation(true);
    } else if (key === "delete") {
      setKey("delete");
      setConfirmation(true);
    }
  };

  const resyncStudent = async () => {
    try {
      setLoading(true);
      if (apiData?.id) {
        const endpoint =
          key === "delete"
            ? HTTPSCalls.ENDPOINTS.ENQUIRY_DELETE_STUDENT_CARD
            : HTTPSCalls.ENDPOINTS.ENQUIRY_RESYNC_STUDENT;
        await HTTPSCalls.POST(
          endpoint + "?",
          { studentId: apiData?.id },
          {}
        ).then((res) => {
          if (res.status) {
            openNotification(
              "success",
              key === "delete"
                ? "Card deleted SuccessFully"
                : "Card Assign SuccessFully"
            );
          } else {
            openNotification("error", res?.message);
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const dropdownMenu = {
    items: [
      { key: "edit", label: "Edit" },
      { key: "resync", label: "Resync" },
      { key: "delete", label: "Delete" },
    ],
    onClick: onMenuitemClick,
  };

  const getStudentCard = async () => {
    try {
      setLoading(true);

      await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.ENQUIRY_GET_STUDENT_CARD + "?",
        { studentId: apiData?.id },
        {}
      ).then((res) => {
        if (res.status) {
          setEcard(res?.result);
        } else {
          openNotification("error", res?.message);
        }
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (apiData?.id) {
      getStudentCard();
    }
  }, [fetchCard]);

  return (
    <>
      <Row gutter={[16, 16]} justify="start">
        {/* Conditional Column */}
        <Col
          xs={24}
          xl={6}
          style={{ paddingLeft: 8, paddingRight: 8, minHeight: "200px" }}
        >
          <Col
            span={24}
            className="roundedCornerMedium shadowLight h100"
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              border: "1px solid rgb(238, 238, 238)",
            }}
          >
            {/* Header */}
            <Row
              className="ant-row-space-between ant-row-middle px-3 py-2 heading-box"
              style={{ borderBottom: "1px solid rgb(238, 238, 238)" }}
            >
              <Text strong>Card</Text>
              {ecard && (
                <Dropdown menu={dropdownMenu}>
                  <Button
                    icon={<MoreOutlined />}
                    type="text"
                    // style={{ position: "absolute", top: 8, right: 8 }}
                  />
                </Dropdown>
              )}
            </Row>

            {/* Content */}
            <Row
              className="px-3 py-2"
              style={{
                ...(ecard ? {} : { justifyContent: "center" }),
                alignItems: "center",
                minHeight: 100,
              }}
            >
              {ecard ? (
                <>
                  <Col span={24} md={24} lg={24}>
                    <Row gutter={[0, 8]} justify="start">
                      <Col span={10}>
                        <Text strong>Card No.</Text>
                      </Col>
                      <Col span={14}>
                        <Text>{ecard.cardNo || "N/A"}</Text>
                      </Col>
                      <Col span={10}>
                        <Text strong>Biometric Machines</Text>
                      </Col>
                      <Col span={14}>
                        <Text>
                          {ecard?.bioMachines
                            ?.map((machine: any) => machine.machineTitle)
                            .join(", ") || "N/A"}
                        </Text>
                      </Col>
                      <Col span={10}>
                        <Text strong>Status</Text>
                      </Col>
                      <Col span={14}>
                        <Text>
                          {ecard?.isCardDeActive ? "Deactive" : "Active"}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </>
              ) : (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  size="small"
                  onClick={() => setModalOpen(true)}
                >
                  Add Card
                </Button>
              )}
            </Row>
          </Col>
        </Col>
      </Row>
      {modalOpen && (
        <CardModel
          student={{ id: apiData?.id, name: apiData?.name }}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          cardData={cardData}
          fetchStudent={() => setFetchCard((prev) => prev + 1)}
        />
      )}

      {confirmation && (
        <ConfirmationModel
          open={confirmation}
          text={`Are you sure you want to ${key} this card?`}
          onOk={async () => {
            await resyncStudent();
            setFetchCard((prev) => prev + 1);
            setConfirmation(false);
            setCardData(null);
            setKey(null);
          }}
          onCancel={() => {
            setConfirmation(false);
            setCardData(null);
            setKey(null);
          }}
          parentLoading={loading}
        />
      )}
    </>
  );
};

export default EnquirySettings;
